import { api } from "roboflex-thalamus-request-handler";

const consultarProjetos = {
  async buscarProjetos(projetosOuPlanoAcao) {
    try {
      const response = await api.post(`${projetosOuPlanoAcao}/tarefas/status`);
      return response.data;
    } catch (error) {
      console.error("Erro ao buscar projetos:", error);
      throw error;
    }
  },

  baixarXML(projetosOuPlanoAcao) {
    api.post(`${projetosOuPlanoAcao}/tarefas/status`, {
      gerar: "xlsx"
    }, {
      responseType: 'arraybuffer',
    })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `Relatório_${projetosOuPlanoAcao}.xlsx`; 0,

          document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error(error);
      });
  }
}

export default consultarProjetos;
