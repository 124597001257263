import { createRouter, createWebHistory } from 'vue-router'
import { sso } from "roboflex-thalamus-sso-lib";

//Projetos
import ControleDeProjetos from '../views/Projetos/ControleDeProjetos.vue'
import PainelKanbanView from '../views/Projetos/PainelKanban'
import SprintsView from '../views/Projetos/SprintsView'

//PCM
import VisualizaçãoPCM from '@/views/PCM/VisualizaçãoPCM.vue'
import ControlePCM from '@/views/PCM/ControlePCM'
import ControlePcmDemandados from '@/views/PCM/ControlePcmDemandados.vue'
import CriarPCM from '@/views/PCM/CriarPCM.vue'

//Plano de Ação
import ControledePlanodeAção from '@/views/PA/ControleDePlanodeAção.vue'
import VisualizaçãoPlanodeAção from '@/views/PA/VisualizaçãoPlanodeAção.vue'

//PAP
import ControledePAP from '@/views/PAP/ControleDePAP.vue'
import VisualizaçãoPAP from '@/views/PAP/VisualizaçãoPAP.vue'

//Programas
import controleProgramas from '@/views/Programas/ControleProgramas'

//Gestão
import GestaoDeTarefas from '@/views/Gestão/gestaoDeTarefas.vue'
import GestaoDeProjetos from '@/views/Gestão/gestaoDeProjetos.vue';

//Protocolos
import CriarProtocolo from '@/views/Protocolo/criarProtocolo.vue';
import visualizaçãoProtocolo from '@/views/Protocolo/visualizaçãoProtocolo.vue'
import ControleDeTNC from '@/views/Protocolo/controleDeTNC.vue';
import ControleDeRAP from '@/views/Protocolo/controleDeRAP.vue';
import ControleDeOs from '@/views/Protocolo/controleDeOs.vue';
import controleDePLA from '@/views/Protocolo/controleDePLA.vue';
import SprintsView_backupComPermissão from '@/views/Projetos/SprintsView_backupComPermissão.vue';


function guardMyroute(to, from, next) {
  if (sso.validarSessao()) {
    next();
  } else {
    document.location = process.env.VUE_APP_ROOT_SSO_LOGIN;
  }
}

const routes = [

  {
    path: "/login",
    redirect: "http://portal.thalamus.com.br/#/login",
  },

  //Protocolos
  {
    path: '/PLA',
    name: 'controleDePLA',
    component: controleDePLA,
    beforeEnter: guardMyroute,
  },
  {
    path: '/RAP',
    name: 'controleDeRAP',
    component: ControleDeRAP,
    beforeEnter: guardMyroute,
  },
  {
    path: '/TNC',
    name: 'controleDeTNC',
    component: ControleDeTNC,
    beforeEnter: guardMyroute,
  },
  {
    path: '/OS',
    name: 'controleDeOs',
    component: ControleDeOs,
    beforeEnter: guardMyroute,
  },
  {
    path: '/protocolo',
    name: 'visualizaçãoProtocolo',
    component: visualizaçãoProtocolo,
    beforeEnter: guardMyroute,
  },
  {
    path: '/protocolo/criar',
    name: 'criaçãoProtocolo',
    component: CriarProtocolo,
    beforeEnter: guardMyroute,
  },

  // Gestão
  {
    path: '/gestao',
    name: 'gestaoProjetos',
    component: GestaoDeProjetos,
    beforeEnter: guardMyroute,
  },
  {
    path: '/',
    name: 'gestaoTarefas',
    component: GestaoDeTarefas,
    beforeEnter: guardMyroute,
  },
  {
    path: '/PCM/:idPCM',
    name: 'PCM',
    component: VisualizaçãoPCM,
    props: route => ({
      idPCM: route.params.idPCM
    }),
    beforeEnter: guardMyroute
  },
  {
    path: '/PCM/Controle',
    name: 'ControlePCM',
    component: ControlePCM,
    beforeEnter: guardMyroute,
  },
  {
    path: '/PCM/Controle/Demandados',
    name: 'ControlePCMDemandados',
    component: ControlePcmDemandados,
    beforeEnter: guardMyroute,
  },
  {
    path: '/PCM/Criar',
    name: 'CriarPCM',
    component: CriarPCM,
    beforeEnter: guardMyroute,
  },

  //PAP
  {
    path: '/PAP',
    name: 'ControlePAP',
    component: ControledePAP,
    beforeEnter: guardMyroute,
  },
  {
    path: '/PAP',
    name: 'PAP',
    component: VisualizaçãoPAP,
    beforeEnter: guardMyroute
  },

  //Plano de Ação
  {
    path: '/PA/Controle',
    name: 'ControlePA',
    component: ControledePlanodeAção,
    beforeEnter: guardMyroute,
  },
  {
    path: '/PA',
    name: 'PA',
    component: VisualizaçãoPlanodeAção,
    beforeEnter: guardMyroute
  },

  //programas
  {
    path: '/Programas/controle',
    name: 'controleProgramas',
    component: controleProgramas,
    beforeEnter: guardMyroute,
  },

  //Projetos
  {
    path: '/projeto',
    name: 'sprints',
    component: SprintsView,
    beforeEnter: guardMyroute,
  },
  {
    path: '/projeto/backup',
    name: 'sprintsBackup',
    component: SprintsView_backupComPermissão,
    beforeEnter: guardMyroute,
  },
  {
    path: '/projetos',
    name: 'ControleDeProjetos',
    component: ControleDeProjetos,
    beforeEnter: guardMyroute,
  },
  {
    path: '/painel',
    name: 'painel',
    component: PainelKanbanView,
    beforeEnter: guardMyroute,
  },

  {
    path: '/:pathMatch(.*)*', redirect: '/'
  }

]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router