<template>
    <HeaderComponent :moduleName="$moduleName" :buildMode="envMode" />
    <main id="main">
        <NovoMenu></NovoMenu>
        <section>
            <router-view />
        </section>
    </main>
    <br>
</template>

<script>
import { HeaderComponent } from "roboflex-thalamus-componentes-vue3-lib";
import NovoMenu from "./components/menuLateral/NovoMenu.vue";

export default {
    components: {
        HeaderComponent,
        NovoMenu
    },
    data() {
        return {
            envMode: process.env.NODE_ENV
        };
    }
}
</script>


<style>
select {
    cursor: pointer;
}

option {
    background: var(--cor-bg) !important;
}

.HP {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent !important;
}

a:hover {
    cursor: pointer;
}

.anexo {
    border: 1px solid var(--cor-separador);
    border-radius: 10px;
    padding: 5px;
    display: flex;
    justify-content: space-between;
}

div {
    transition: background-color 0.25s, border 0.25s;
}

*::-webkit-scrollbar {
    width: 10px;
}

*::-webkit-scrollbar-thumb {
    background-color: var(--cor-separador);
    border-radius: 20px;
}

th {
    text-align: center !important;
}

.hPoints {
    display: flex;
    border-radius: 50%;
    margin-right: 5px;
    width: 2rem;
    height: 2rem;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    text-align: center;
}

.botaoAdicionarSprint:hover {
    transition: 50ms linear;
    transform: scale(1.1);
    cursor: pointer;
}

.shake {
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
}

@keyframes shake {

    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}

.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-container {
    background-color: var(--cor-bg);
    border-radius: 8px;
    padding: 100px;
    width: 90%;
    max-width: 1500px;
    max-height: 80%;
    overflow-y: auto;
    position: relative;
}
</style>
