<template>
  <main id="main">
    <section>
      <div class="titulo">
        <div class="margem container">
          <div class="m-icone direita">
            <div class="pesquisa">
              <input type="text" placeholder="Pesquisar PCM" v-model="PCMSelecionado" @input="filtrarPCMs()" />
              <a class="icone-pesquisa" title="Pesquise"></a>
            </div>
          </div>
          <h2>Propostas de Criação ou Mudança Demandadas</h2>
        </div>
      </div>
      <div class="margem container">
        <div class="bloco margem">
          <table class="tabela">
            <tbody>
              <tr>
                <th style="widows: 1%"></th>
                <th scope="col" @click="ordernarColuna('tipo')">
                  Tipo <span v-if="activeFilter == 'tipo'"><i :class="isAscending ? 'bi bi-caret-down-fill' : 'bi bi-caret-up-fill'"></i></span>
                </th>
                <th scope="col" @click="ordernarColuna('codigo')" class="header">
                  Código <span v-if="activeFilter == 'codigo'"><i :class="isAscending ? 'bi bi-caret-down-fill' : 'bi bi-caret-up-fill'"></i></span>
                </th>
                <th scope="col" @click="ordernarColuna('nome')" class="header">
                  Nome <span v-if="activeFilter == 'nome'"><i :class="isAscending ? 'bi bi-caret-down-fill' : 'bi bi-caret-up-fill'"></i></span>
                </th>
                <th scope="col" @click="ordernarColuna('setorDemanda_nome')" class="header">
                  Setor demandante <span v-if="activeFilter == 'tipo'"><i :class="isAscending ? 'bi bi-caret-down-fill' : 'bi bi-caret-up-fill'"></i></span>
                </th>
                <th scope="col" @click="ordernarColuna('setorAprovacao_nome')" class="header">
                  Setor aprovador <span v-if="activeFilter == 'setorAprovacao_nome'"><i :class="isAscending ? 'bi bi-caret-down-fill' : 'bi bi-caret-up-fill'"></i></span>
                </th>
                <th scope="col" @click="ordernarColuna('status')" class="header" style="text-align: center">
                  Status <span v-if="activeFilter == 'status'"><i :class="isAscending ? 'bi bi-caret-down-fill' : 'bi bi-caret-up-fill'"></i></span>
                </th>
                <th scope="col" @click="ordernarColuna('dtInicio')" class="header">
                  Data Início <span v-if="activeFilter == 'dtInicio'"><i :class="isAscending ? 'bi bi-caret-down-fill' : 'bi bi-caret-up-fill'"></i></span>
                </th>
                <th scope="col" style="text-align: right" v-if="dadosUsuario && dadosUsuario.funcionalidade_id.includes(56)">
                  <div class="form-check" style="display: flex; justify-content: right" v-if="dadosUsuario && dadosUsuario.grupo.includes('Diretoria')">
                    <div class="tags">
                      <a @click="(mostrarOperacionais = !mostrarOperacionais), this.getPCMs()" :class="mostrarOperacionais == true ? 'ativo' : ''" style="cursor: pointer">Mostrar Operacionais</a>
                    </div>
                  </div>
                </th>
              </tr>
              <tr v-for="item in listaPCMsFiltrada" @click="verPCM(item.id, item.status, item.solicitante_id)" :key="item.id">
                <td style="vertical-align: middle; text-align: center; width: 1%">
                  <i v-if="item.nivel == 'Estratégico'" style="font-size: 19px; color: var(--cor-fonte)" class="fa-solid fa-chess-rook"></i>
                  <i v-else style="font-size: 19px; color: var(--cor-fonte)" class="fa-solid fa-screwdriver-wrench"></i>
                  <!-- {{ item.solicitante_id }} -->
                </td>

                <td style="text-align: center; vertical-align: middle; width: 5% !important; white-space: nowrap">
                  {{ item.tipo }}
                </td>

                <td style="text-align: center; vertical-align: middle; width: 14% !important">
                  {{ item.codigo }}
                </td>

                <td style="text-align: center; vertical-align: middle; width: 30% !important">
                  <span :title="item.nome" style="width: 100% !important">
                    {{ item.nome }}
                  </span>
                </td>

                <td style="text-align: center; vertical-align: middle; width: 14%">
                  {{ item.setorDemanda_nome }}
                </td>

                <td style="text-align: center; vertical-align: middle; width: 14%">
                  {{ item.setorAprovacao_nome }}
                </td>

                <td
                  style="text-align: center; vertical-align: middle; width: 11%"
                  :style="{
                    color:
                      item.status == 'Aguardando Aprovação' || item.status == 'Aprovação Estratégica' || item.status == 'Aprovação Técnica'
                        ? 'rgb(255, 145, 0)'
                        : item.status == 'Reprovado'
                        ? '#e02130'
                        : item.status == 'Aprovado'
                        ? 'rgb(0, 192, 0)'
                        : 'red',
                  }"
                >
                  {{ item.status }}
                </td>

                <td style="text-align: center; vertical-align: middle; width: 10%">
                  {{ item.dtInicio ? `${formatarDataHora(item.dtInicio).split("-")[2]}/${formatarDataHora(item.dtInicio).split("-")[1]}/${formatarDataHora(item.dtInicio).split("-")[0]}` : "" }}
                </td>

                <td style="text-align: center; vertical-align: middle; width: 14%"></td>
              </tr>
            </tbody>
          </table>
          <h3 v-if="PCMs && PCMs.length == 0" style="color: red; text-align: center">Lista vazia!</h3>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import { api } from "roboflex-thalamus-request-handler";
import { sso } from "roboflex-thalamus-sso-lib";
import { getDados } from "@/services/serviceFuncionalidades";
import { consultarSetores } from "@/services/usuario-setor";
import serviceFunções from "@/services/serviceFunções";
import Cookies from "js-cookie";

export default {
  name: "ControlePCM",

  setup() {
    const formatarDataHora = (valor) => {
      return serviceFunções.formatarDataHora(valor);
    };
    return {
      formatarDataHora,
    };
  },

  data() {
    return {
      PCMSelecionado: null,
      listaPCMsFiltrada: null,
      pcmReprovado: null,
      modalConfirmacao: false,
      quantidadeTarefas: null,
      tipo: null,

      objetoReferenciaFiltro: "status",
      valorReferencia: null,

      mostrarOperacionais: false,

      PCMs: null,
      idUsuario: null,

      dadosUsuario: null,

      teste: "teste",
      usuarios: null,
      setores: null,

      isAscending: true,
      activeFilter: "none",
    };
  },

  created() {
    const usuarioLogado = sso.getUsuarioLogado();
    // this.userName = usuarioLogado.nome // nome
    this.idUsuario = usuarioLogado.id; // id
    this.carregarPermissoes();
    this.getSetores();
  },

  methods: {
    apagarPCM(item) {
      api
        .put(`pcm/excluir/${item.id}`, {
          usuario_id: this.idUsuario,
        })
        .then(() => {
          this.getPCMs();
        });
    },

    fecharModalFora(event) {
      if (event.target.classList.contains("modal-mask")) {
        this.modalConfirmacao = false;
      }
    },

    avisoExclusao(item) {
      if (item.tipo == "Projeto") {
        api
          .get("projeto/listar")
          .then((response) => {
            var projetoEncontrado = response.data.find((projeto) => projeto.id === item.associadao_id);
            if (projetoEncontrado) {
              var quantidadeTarefas = projetoEncontrado.quantidade_tarefas;
              if (quantidadeTarefas !== 0) {
                this.modalConfirmacao = true;
                this.quantidadeTarefas = quantidadeTarefas;
                this.tipo = item.tipo;
                this.pcmReprovado = item;
              } else {
                this.excluirElemento(item);
                this.atualizarPCM("status", "Reprovado", item.id);
              }
            } else {
              this.excluirElemento(item);
              this.atualizarPCM("status", "Reprovado", item.id);
            }
          })
          .catch((error) => {
            // Tratar erros de requisição
            console.error("Erro ao buscar projetos:", error);
          });
      }
      if (item.tipo == "Plano de Ação") {
        api
          .get("planoAcao/listar")
          .then((response) => {
            var planoEncontrado = response.data.find((plano) => plano.id === item.associadao_id);
            if (planoEncontrado) {
              var quantidadeTarefas = planoEncontrado.quantidade_tarefas;
              if (quantidadeTarefas !== 0) {
                this.modalConfirmacao = true;
                this.quantidadeTarefas = quantidadeTarefas;
                this.tipo = item.tipo;
                this.pcmReprovado = item;
              } else {
                this.excluirElemento(item);
                this.atualizarPCM("status", "Reprovado", item.id);
              }
            } else {
              this.excluirElemento(item);
              this.atualizarPCM("status", "Reprovado", item.id);
            }
          })
          .catch((error) => {
            // Tratar erros de requisição
            console.error("Erro ao buscar planos de ação:", error);
          });
      }
      if (item.tipo == "Programa") {
        this.excluirElemento(item);
        this.atualizarPCM("status", "Reprovado", item.id);
      }
    },

    excluirElemento(item) {
      if (item.tipo == "Projeto") {
        api
          .put(`projeto/excluir/${item.associadao_id}`, {
            usuario_id: this.idUsuario,
          })
          .then((response) => {
            console.log(response.data);
          })
          .catch((error) => {
            console.error(error);
          });
      }
      if (item.tipo == "Plano de Ação") {
        api
          .put(`planoAcao/excluir/${item.associadao_id}`, {
            usuario_id: this.idUsuario,
          })
          .then((response) => {
            console.log(response.data);
          })
          .catch((error) => {
            console.error(error);
          });
      }
      if (item.tipo == "Programa") {
        api
          .put(`programa/excluir/${item.associadao_id}`, {
            usuario_id: this.idUsuario,
          })
          .then((response) => {
            console.log(response.data);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },

    criarElemento(item) {
      var responsavelSetor = this.setores.find((setor) => setor.id == item.setor_id).responsavel_id;
      var responsavelSetorDemandante = this.setores.find((setor) => setor.id == item.setorDemanda_id).responsavel_id;
      if (!responsavelSetor || !responsavelSetorDemandante) {
        console.error("Responsavel pelo setor não encontrado");
        return;
      }

      if (item.associadao_id == null) {
        if (item.tipo == "Projeto") {
          api
            .post(`projeto/cadastrar`, {
              nome: item.nome,
              dtInicio: item.dtInicio.split(" ")[0],
              gerente_id: responsavelSetor,
              demandante_id: responsavelSetorDemandante,
              setor_id: item.setor_id,
              usuario_id: this.idUsuario,
              solicitante_id: item.solicitante_id,
              status: "Aprovado",
            })
            .then((response) => {
              api
                .put(`pcm/atualizar/${item.id}`, {
                  // projeto: 1,
                  projeto_id: response.data.id,
                })
                .catch((error) => {
                  console.error(error);
                });
              console.log(response.data);
            })
            .catch((error) => {
              console.error(error);
            });
        }
        if (item.tipo == "Plano de Ação") {
          api
            .post(`planoAcao/cadastrar`, {
              nome: item.nome,
              dtInicio: item.dtInicio.split(" ")[0],
              gerente_id: responsavelSetor,
              demandante_id: responsavelSetorDemandante,
              setor_id: item.setor_id,
              usuario_id: this.idUsuario,
              solicitante_id: item.solicitante_id,
              status: "Aprovado",
            })
            .then((response) => {
              api
                .put(`pcm/atualizar/${item.id}`, {
                  // projeto: 0,
                  planoAcao_id: response.data.id,
                })
                .catch((error) => {
                  console.error(error);
                });
              console.log(response.data);
            })
            .catch((error) => {
              console.error(error);
            });
        }
        if (item.tipo == "Programa") {
          api
            .post(`programa/cadastrar`, {
              nome: item.nome,
              dtInicio: item.dtInicio.split(" ")[0],
              gerente_id: responsavelSetor,
              status: "Aprovado",
            })
            .then((response) => {
              api
                .put(`pcm/atualizar/${item.id}`, {
                  // projeto: 0,
                  programa_id: response.data.id,
                })
                .catch((error) => {
                  console.error(error);
                });
              console.log(response.data);
            })
            .catch((error) => {
              console.error(error);
            });
        }
      }
    },

    carregarPermissoes() {
      var id = this.idUsuario;
      getDados(id)
        .then((dadosUsuario) => {
          this.dadosUsuario = dadosUsuario;
          this.getPCMs();
        })
        .catch((error) => {
          console.error(error);
          console.log("Falha ao colher dados do usuario");
        });
    },

    atualizarPCM(itemEditado, valor, id) {
      if (valor == "Aprovado") {
        api
          .put(`pcm/atualizar/${id}`, {
            [itemEditado]: valor,
            aprovada: 1,
          })
          .then(() => {
            this.getPCMs();
          })
          .catch((error) => {
            console.error(error);
          });
      }
      if (valor == "Reprovado") {
        api
          .put(`pcm/atualizar/${id}`, {
            [itemEditado]: valor,
            aprovada: 0,
          })
          .then(() => {
            this.getPCMs();
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },

    filtrarPCMs() {
      if (!this.PCMSelecionado) {
        this.listaPCMsFiltrada = this.PCMs;
      } else {
        const textoLowerCase = this.PCMSelecionado.toLowerCase();
        this.listaPCMsFiltrada = this.PCMs.filter((pcm) => {
          // Verifica se alguma chave do objeto contém o texto
          return Object.values(pcm).some((value) => {
            if (typeof value === "string") {
              return value.toLowerCase().includes(textoLowerCase);
            }
            return false;
          });
        });
      }
    },

    getPCMs() {
      api
        .get(`pcm/listar`)
        .then((response) => {
          this.PCMs = response.data;

          if (!this.dadosUsuario.grupo.includes("Diretoria")) {
            this.PCMs = this.PCMs.filter(
              (item) => item.solicitante_id == this.idUsuario || (this.dadosUsuario.setor_responsavel.includes(item.setorDemanda_id) && !this.dadosUsuario.setor_responsavel.includes(item.setor_id))
            );
          }

          this.PCMs = this.PCMs.sort((a, b) => {
            const statusComparison = a.status.localeCompare(b.status);
            if (statusComparison !== 0) {
              return statusComparison;
            } else {
              // Se os status forem iguais, ordena por data mais recente
              const dateA = new Date(a.dtInicio).getTime();
              const dateB = new Date(b.dtInicio).getTime();
              return dateB - dateA; // Ordena por data mais recente primeiro
            }
          });
          this.filtrarPCMs();
        })
        .catch((error) => {
          console.error(error);
        });
    },

    verPCM(id, status, solicitante_id) {
      Cookies.set(
        "pcmDetails",
        JSON.stringify({
          perfil: status == "Aprovado" || status == "Reprovado" || solicitante_id !== this.idUsuario ? 0 : 2,
        }),
        { expires: 1 }
      );
      this.$router.push({ name: "PCM", params: { idPCM: id } });
    },

    async getSetores() {
      try {
        const { usuarios, setores } = await consultarSetores();
        this.usuarios = usuarios;
        this.setores = setores;
      } catch (error) {
        console.error(error);
      }
    },

    ordernarColuna(coluna) {
      this.activeFilter = coluna;
      switch (coluna) {
        case "codigo" || "setorDemanda_nome" || "setorAprovacao_nome":
          this.ordernarString(coluna);
          break;
        case "status":
          this.porStatus();
          break;
        case "dtInicio":
          this.porData();
          break;
        default:
          break;
      }
      this.isAscending = !this.isAscending;
    },

    ordernarString(coluna) {
      this.listaPCMsFiltrada.sort((a, b) => {
        const valorA = a[coluna] ?? "z";
        const valorB = b[coluna] ?? "z";

        if (this.isAscending) return valorA.localeCompare(valorB);
        else return valorB.localeCompare(valorA);
      });
    },

    porData() {
      this.listaPCMsFiltrada.sort((a, b) => {
        const dataInicioA = new Date(a["dtInicio"]);
        const dataInicioB = new Date(b["dtInicio"]);
        if (this.isAscending) return dataInicioA - dataInicioB;
        else return dataInicioB - dataInicioA;
      });
    },

    porStatus() {
      const order = {
        "Aguardando Aprovação": 1,
        "Aprovação Estratégica": 2,
        "Aprovação Técnica": 3,
        "Em andamento": 4,
        Aprovado: 5,
        Reprovado: 6,
      };

      this.listaPCMsFiltrada.sort((a, b) => {
        const orderA = order[a.status] || 0;
        const orderB = order[b.status] || 0;
        if (this.isAscending) {
          if (orderA < orderB) {
            return -1;
          }
          if (orderA > orderB) {
            return 1;
          }
          return 0;
        } else {
          if (orderA > orderB) {
            return -1;
          }
          if (orderA < orderB) {
            return 1;
          }
          return 0;
        }
      });
    },
  },
};
</script>

<style scoped>
.header {
  cursor: pointer;
}
</style>
