<template>
    <main id="main">
        <section>
            <div class="titulo">
                <div class="margem container">
                    <div class="m-icone direita"><button @click="verPCMCriada('Programa')" class="acao-secundaria">Novo
                            Programa</button></div>
                    <div class="m-icone direita">
                        <div class="pesquisa">
                            <input type="text" placeholder="Pesquisar Programa" v-model="programaSelecionado"
                                @input="filtrarProgramas()" />
                            <a disabled class="icone-pesquisa"></a>
                        </div>
                    </div>
                    <h2>Programas</h2>
                </div>
            </div>
            <div class="margem container">
                <div class="bloco margem">
                    <table class="tabela">
                        <tbody>
                            <tr>
                                <th scope="col">Nome do Programa</th>
                                <th scope="col">Status</th>
                                <th scope="col">Data de Inicio</th>
                                <th scope="col">Data de Término</th>
                                <th scope="col">Gerente Responsável</th>
                                <th scope="col"></th>
                            </tr>
                            <tr v-for="item in listaProgramasFiltrada" style="background-color: transparent;text-align: center;" :key="item.id"
                                @mouseover="mostrarBotao(item.id, true)" @click="programasAssociados(item.id)"
                                @mouseleave="mostrarBotao(item.id, false)">
                                <td style="vertical-align: middle;">{{ item.nome }}</td>
                                <td class="clicavel"
                                    style="vertical-align: middle; display: flex; align-items: center; justify-content: center; border: none">
                                    <select v-model="item.status" class="form-select"
                                        :style="{ 'color': (item.status == 'Aprovado') ? '#00B6B3' : (item.status == 'Em andamento') ? 'var(--cor-ok)' : (item.status == 'Concluído') ? ' var(--cor-sucesso)' : '#FFA500', }"
                                        style="width: 10rem; outline: none; text-align: center; border: none; "
                                        @click.stop @change="editarProgramaInline(item.id, 'status', item.status)">
                                        <option style="color: #00B6B3;">Aprovado</option>
                                        <option style="color: var(--cor-ok);">Em andamento</option>
                                        <option style="color: var(--cor-sucesso);">Concluído</option>
                                        <option style="color: #FFA500;">Suspenso</option>
                                    </select>
                                </td>
                                <td style="vertical-align: middle;">

                                    {{ item.dtInicio ?
                                        `${formatarDataHora(item.dtInicio).split('-')[2]}/${formatarDataHora(item.dtInicio).split('-')[1]}/${formatarDataHora(item.dtInicio).split('-')[0]}`
                                        : '' }}

                                </td>
                                <td style="vertical-align: middle;">

                                    {{ item.dtTermino ?
                                        `${formatarDataHora(item.dtTermino).split('-')[2]}/${formatarDataHora(item.dtTermino).split('-')[1]}/${formatarDataHora(item.dtTermino).split('-')[0]}`
                                        : '-' }}

                                </td>
                                <td style="vertical-align: middle;">{{ item.gerente_nome }}</td>
                                <td style="vertical-align: middle;">
                                    <div style="width: max-content; visibility: hidden;" :id="'botaoEdicao' + item.id">
                                        <v-menu v-if="Array.isArray(programas) && programas.length > 0">
                                            <template v-slot:activator="{ props }">
                                                <v-btn
                                                    style="width: 2rem; height: 2rem; border: 1px solid var(--cor-separador); color: var(--cor-fonte);"
                                                    class="botaoAdicionarSprint acao-secundaria"
                                                    icon="mdi-dots-horizontal" v-bind="props"></v-btn>
                                            </template>

                                            <v-list style="background-color: var(--cor-bg); color: var(--cor-fonte);">
                                                <v-list-item
                                                    @click="modalEditarPrograma = true, this.programaEditado = item, this.programaEditado.dtFim !== null ? this.programaEditado.dtFim = this.programaEditado.dtFim.slice(0, 10) : '', this.programaEditado.dtInicio !== null ? this.programaEditado.dtInicio = this.programaEditado.dtInicio.slice(0, 10) : ''">
                                                    Editar
                                                </v-list-item>
                                                <v-list-item
                                                    @click="modalFinalizarPrograma = true, this.programaEditado = item">
                                                    Finalizar
                                                </v-list-item>
                                                <v-list-item style="color: var(--cor-erro);"
                                                    @click="modalExcluirPrograma = true, this.programaEditado = item">
                                                    Excluir
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="loading" v-if="!programas">
                        <div></div>
                    </div>
                </div>
            </div>

            <!-- MODAL FINALIZAR PROGRAMAS-->

            <div class="modal-mask" v-if="modalFinalizarPrograma" @click="fecharModalFora">
                <div class="jm" style="height: min-content; width: 50rem; padding: 2rem;">
                    <div style="display: flex; justify-content: center">
                        <h2>{{ programaEditado.nome }}</h2>
                    </div>
                    <br>

                    <div style="width: 100%; display: flex; justify-content: center;">
                        <div style="width: 50%;">
                            <label>Data de término do programa:</label>
                            <input :class="{ shake: disabled }" v-model="dataTerminoPrograma" id="dataTermino"
                                class="form-control" type="date">
                        </div>
                        <div style="align-content: end; margin-left: 1rem;">
                            <button class="button" @click="finalizarPrograma()">
                                Finalizar Programa</button>
                        </div>
                    </div>

                </div>
            </div>

            <!-- END MODAL FINALIZAR PROGRAMAS-->

            <!-- MODAL EXCLUIR PROGRAMAS -->

            <div style="overflow: auto" class="modal-mask" v-if="modalExcluirPrograma" @click="fecharModalFora">
                <div class="jm" style="height: min-content; width: 50rem; padding: 2rem;">
                    <div style="text-align: center; width: 100%;">
                        <div>
                            <h2>Deseja excluir o programa {{ programaEditado.nome }}?</h2>
                        </div>
                        <br>
                        <div class="modal-footer">
                            <button type="button" class="button" @click="excluirPrograma">Confirmar</button>
                            <button type="button" class="button acao-secundaria" @click="fecharModalExcluirPrograma"
                                style="margin-left: 0.5rem;">Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- MODAL EXCLUIR PROGRAMAS -->


            <!-- MODAL EDITAR PROGRAMAS -->

            <div style="overflow: auto" class="modal-mask" v-if="modalEditarPrograma" @click="fecharModalFora">
                <div style="height: 50%; width: 50rem; padding: 2rem; margin-bottom: 3rem;" class="jm">
                    <div>
                        <div style="display: flex; justify-content: space-between">
                            <h3>Editar: {{ programaEditado.nome }} </h3>
                        </div>
                        <hr>
                        <br>
                        <div class="grid-2">
                            <div>
                                <label>Nome do Projeto</label>
                                <input type="text" v-model="programaEditado.nome"
                                    @focusout="editarPrograma('nome', programaEditado.nome)">
                            </div>

                            <div>
                                <label>Gerente Responsável</label>
                                <select @focusout="editarPrograma('gerente_id', programaEditado.gerente_id)"
                                    v-model="programaEditado.gerente_id">
                                    <option v-for="item in gerente" :key="item.id" :value="item.id">
                                        {{ item.nomeCompleto }}
                                    </option>
                                </select>
                            </div>

                            <div>
                                <label>Data de Início</label>
                                <input type="date" ref="dtInicio" v-model="programaEditado.dtInicio"
                                    @change="editarPrograma('dtInicio', $event.target.value)">
                            </div>
                            <div>
                                <label>Data de Término</label>
                                <input type="date" ref="dtFim" v-model="programaEditado.dtFim"
                                    @change="editarPrograma('dtFim', $event.target.value)">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- END EDITAR PROGRAMAS MODAL  -->

            <!-- MODAL ASSOCIAÇÃO -->

            <div class="modal-mask" v-if="modalProjetosAssociados" @click="fecharModalFora">
                <div style="height: fit-content;width: 70rem; padding: 3rem; margin-bottom: 2rem; max-height: 80vh; overflow: auto;"
                    class="jm">
                    <div style="display: flex; justify-content: space-between; max-height: 100% !important;">
                        <!-- Coluna de Projetos associados -->
                        <div style="width: 48%;">
                            <h3>
                                Projetos disponíveis:</h3>
                            <select id="projetos" class="form-select" @change="associarProjeto($event)">
                                <!-- v-if="permissoes ? permissoes.find(pessoa => pessoa.usuario_id == idUsuario).nivel !== 1 : false" -->
                                <option hidden selected>Selecione um projeto</option>
                                <option v-for="item in projetos" :key="item.id" :value="item.id">{{ item.nome }}
                                </option>
                            </select>
                            <div v-if="programaEditado.projeto.length > 0">
                                <br>
                                <h3>Projetos associados:</h3>
                                <ul style="overflow: auto;">
                                    <!-- <li    style="display: flex; border: 1px solid black; align-items: center; justify-content: space-between; padding: 5px; border-radius: 10px; width: 90%;"  -->
                                    <li v-for="(projeto, index) in programaEditado.projeto" :key="index"> {{
                                        projeto.projeto_nome }}
                                        <!-- v-if="permissoes ? permissoes.find(pessoa => pessoa.usuario_id == idUsuario).nivel !== 1 : false" -->
                                        <span @click="desassociarPlano('projeto', projeto.associacao_id)"
                                            style="cursor: pointer; color: red; text-align: right;"
                                            class="bi bi-dash-circle"></span>
                                    </li>
                                </ul>
                                <br><br>
                            </div>
                            <div v-else>
                                <br>
                                <h4>Nenhum projeto associado.</h4>
                            </div>

                        </div>

                        <!-- Coluna de Planos de Ação associados -->

                        <div style="width: 48%;">
                            <!-- v-if="permissoes ? permissoes.find(pessoa => pessoa.usuario_id == idUsuario).nivel !== 1 : false" -->
                            <h3>
                                Planos de ação disponíveis:</h3>
                            <!-- v-if="permissoes ? permissoes.find(pessoa => pessoa.usuario_id == idUsuario).nivel !== 1 : false" -->
                            <select id="planosAcao" class="form-select" @change="associarPlanoAcao($event)">
                                <option hidden selected>Selecione um plano de ação</option>
                                <option v-for="item in planosAcao" :key="item.id" :value="item.id">{{ item.nome }}
                                </option>
                            </select>


                            <div
                                v-if="programaEditado.planoAcao.length > 0 && programaEditado.planoAcao[0].associacao_id !== null">

                                <br>
                                <h3>Planos de Ação associados:</h3>
                                <ul style="overflow: auto;">
                                    <li v-for="(plano, index) in programaEditado.planoAcao" :key="index">
                                        <!-- v-if="permissoes ? permissoes.find(pessoa => pessoa.usuario_id == idUsuario).nivel !== 1 : false" -->
                                        {{ plano.planoAcao_nome }} <span
                                            @click="desassociarPlano('plano', plano.associacao_id)"
                                            class="bi bi-dash-circle"
                                            style="cursor: pointer; color: red; text-align: right;"></span>
                                    </li>
                                </ul>
                            </div>
                            <div v-else>
                                <br>
                                <h4>Nenhum plano de ação associado.</h4>
                            </div>

                        </div>
                    </div>
                    <br><br>
                </div>
            </div>

            <!-- END MODAL ASSOCIAÇÃO -->



            <!-- MODAL NOVO PROGRAMA -->

            <div style="overflow: auto" class="modal-mask" v-if="modalNovoPrograma" @click="fecharModalFora">
                <div style="max-height: 80%; max-width: 900px ;width: 70%; padding: 3rem;" class="modal-container">
                    <div>
                        <div style="display: flex; justify-content: space-between">
                            <h3 class="titulo">Cadastrar Programa </h3>
                            <button type="button" class="btn-close" aria-label="Close"
                                @click="this.modalNovoPrograma = false"></button>
                        </div>
                        <hr>
                        <br>
                        <div style="display: flex;">
                            <div class="form-group" style="width: 30rem;">
                                <label for="nome">Nome do Programa</label>
                                <input id="nome" v-model="novoPrograma.nome" type="text" class="form-control">
                            </div>

                            <div class="form-group" style="width: 20rem; margin-left: 2rem;">
                                <label for="data">Data de Início</label>
                                <input id="data" v-model="novoPrograma.dtInicio" type="date" ref="dtInicio"
                                    class="form-control">
                            </div>
                        </div>
                        <div style="display: flex;">
                            <div class="form-group" style="width: 30rem;">
                                <label for="gerente">Gerente Responsável</label>
                                <select id="gerente" v-model="novoPrograma.gerente_id" class="form-select">
                                    <option v-for="item in gerente" :key="item.id" :value="item.id">
                                        {{ item.nomeCompleto }}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group" style="width: 20rem; margin-left: 2rem;">
                                <label for="data">Data de Término</label>
                                <input id="data" v-model="novoPrograma.dtFim" type="date" ref="dtFim"
                                    class="form-control">
                            </div>
                        </div>
                        <div style="display: flex; justify-content: right;">
                            <button @click="adicionarPrograma"
                                style="height: 2.5rem; background-color: #F89E31; border: none;"
                                class="btn btn-primary float-right mr-2">Salvar</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- END MODAL NOVO PROGRAMA -->
        </section>
    </main>
</template>


<script>
// import {  permissoes } from '../../services/api'
import { api } from "roboflex-thalamus-request-handler";
import { getDados } from '@/services/serviceFuncionalidades';
import { consultarSetores } from '@/services/usuario-setor';
import { sso } from "roboflex-thalamus-sso-lib";
import serviceFunções from "@/services/serviceFunções";

export default {
    name: "ControleProgramas",

    setup() {
        const formatarDataHora = (valor) => {
            return serviceFunções.formatarDataHora(valor);
        };
        return {
            formatarDataHora
        };
    },
    
    data() {
        return {
            dadosUsuario: this.carregarPermissoes(),
            idUsuario: null,

            programaSelecionado: null,
            listaProgramasFiltrada: null,

            modalProjetosAssociados: false,
            modalNovoPrograma: false,
            modalExcluirPrograma: false,
            programas: null,
            novoPrograma: {
                "nome": '',
                "status": '',
                "dtInicio": new Date().getFullYear() + '-' + '0' + (new Date().getMonth() + 1) + '-' + new Date().getDate(),
                "gerente_nome": '',
                "dtFim": ''
            },
            gerente: [],
            modalEditarPrograma: false,
            programaEditado: null,
            projetos: [],
            planosAcao: [],
            dataTerminoPrograma: null,
            modalFinalizarPrograma: false

        }
    },

    mounted() {
        this.getProgramas()
        this.getGerente()
        this.getProjetosPlanoAcao()
    },

    created() {
        const usuarioLogado = sso.getUsuarioLogado()
        // this.userName = usuarioLogado.nome // nome
        this.idUsuario = usuarioLogado.id // id
    },

    methods: {
        verPCMCriada(tipo, nivel) {
            localStorage.setItem('Tipo', tipo);
            localStorage.setItem('Nivel', nivel)
            if (this.$route.path === '/PCM/Criar') {
                window.location.reload();
            } else {
                this.$router.push({ name: "CriarPCM" });
            }
        },

        carregarPermissoes() {
            var id = this.idUsuario
            id = 15
            getDados(id)
                .then(dadosUsuario => {
                    this.dadosUsuario = dadosUsuario;
                    // console.log(permissoes);
                })
                .catch(error => {
                    console.error(error);
                });
        },

        finalizarPrograma() {
            if (this.dataTerminoPrograma == null) {
                document.getElementById('dataTermino').style.border = '1px solid red';
                this.disabled = true
                setTimeout(() => {
                    this.disabled = false
                }, 1500)
                return
            }
            api.put(`programa/atualizar/${this.programaEditado.id}`, {
                dtFim: this.dataTerminoPrograma,
                status: "Concluído"
            })
            this.modalFinalizarPrograma = false;
            setTimeout(() => {
                this.getProgramas();
            }, 500)

        },

        filtrarProgramas() {
            if (!this.programaSelecionado) {
                this.listaProgramasFiltrada = this.programas;
            } else {
                const textoLowerCase = this.programaSelecionado.toLowerCase();
                this.listaProgramasFiltrada = this.programas.filter(programa => {
                    return Object.values(programa).some(value => {
                        if (typeof value === 'string') {
                            return value.toLowerCase().includes(textoLowerCase);
                        }
                        return false;
                    });
                });
            }
        },

        fecharModalExcluirPrograma() {
            this.modalExcluirPrograma = false;
        },

        excluirPrograma() {
            api.put(`programa/excluir/${this.programaEditado.id}`, {
                usuario_id: this.idUsuario
            })
                .then(() => {
                    this.getProgramas();
                    this.modalExcluirPrograma = false;
                })
                .catch((error) => {
                    console.error('Erro ao excluir programa:', error);
                });
        },

        associarProjeto(event) {
            const projetoId = event.target.value;
            api.post(`programa/associar/${this.programaEditado.id}`, {
                id: projetoId,
                projeto: 1
            })
                .then((response) => {
                    this.programaEditado.projeto.push({
                        associacao_id: response.data.associacao_id,
                        projeto_nome: event.target.options[event.target.selectedIndex].text

                    }

                    ); this.getProjetosPlanoAcao()

                })
                .catch((error) => {
                    console.error('Erro ao associar projeto:', error);
                });
        },

        associarPlanoAcao(event) {
            const planoAcaoId = event.target.value;
            api.post(`programa/associar/${this.programaEditado.id}`, {
                id: planoAcaoId,
                projeto: 0
            })
                .then((response) => {
                    this.programaEditado.planoAcao.push({
                        associacao_id: response.data.associacao_id,
                        planoAcao_nome: event.target.options[event.target.selectedIndex].text
                    });
                    this.getProjetosPlanoAcao()



                })
                .catch((error) => {
                    console.error('Erro ao associar plano de ação:', error);
                });
        },

        desassociarPlano(tipo, id) {
            api.post(`programa/associar/excluir/${this.programaEditado.id}`, {
                id: id,
                projeto: tipo === 'projeto' ? 1 : 0
            })
                .then(() => {
                    if (tipo === 'projeto') {
                        this.programaEditado.projeto = this.programaEditado.projeto.filter(plano => plano.associacao_id !== id);
                        this.getProjetosPlanoAcao()

                    } else {
                        this.programaEditado.planoAcao = this.programaEditado.planoAcao.filter(plano => plano.associacao_id !== id);
                        this.getProjetosPlanoAcao()

                    }
                })
                .catch((error) => {
                    console.error(`Erro ao desassociar ${tipo === 'projeto' ? 'projeto' : 'plano de ação'}:`, error);
                });

            this.getProjetosPlanoAcao()
        },

        getProjetosPlanoAcao() {
            api.get(`planoacao-projeto/listar/sem-programa`)
                .then((response) => {
                    const data = response.data;
                    this.projetos = data.projeto;
                    this.planosAcao = data.planoAcao;
                })
                .catch((error) => {
                    console.error('Erro', error);
                });
        },

        programasAssociados(id) {
            api.get(`programa/buscar/${id}`)
                .then(response => {
                    this.programaEditado = response.data;
                    this.modalProjetosAssociados = true;

                })
                .catch(error => {
                    console.error('Erro ao obter detalhes do programa:', error);
                });
        },

        mostrarBotao(id, mostrar) {
            if (mostrar == true) {
                document.getElementById('botaoEdicao' + id).style.visibility = ''
            }
            if (mostrar == false) {
                document.getElementById('botaoEdicao' + id).style.visibility = 'hidden'
            }
        },

        editarPrograma(itemAlterado, novoValor) {
            api.put(`programa/atualizar/${this.programaEditado.id}`, {
                [itemAlterado]: novoValor,
            })
        },

        editarProgramaInline(idProjeto, itemAlterado, novoValor) {

            if (novoValor !== "Concluído") {
                api.put(`programa/atualizar/${idProjeto}`, {
                    [itemAlterado]: novoValor,
                    dtTermino: null
                })
                    .then(() => {
                        this.getProgramas()
                    })
            }
            if (novoValor == "Concluído") {
                var dataAtual = new Date().toISOString().split('T')[0];
                api.put(`programa/atualizar/${idProjeto}`, {

                    [itemAlterado]: novoValor,
                    dtFim: dataAtual
                })
                    .then(() => {
                        this.getProgramas()
                    })
            }
        },

        adicionarPrograma() {
            api.post(`programa/cadastrar`, {
                nome: this.novoPrograma.nome,
                dtInicio: this.novoPrograma.dtInicio,
                dtFim: this.novoPrograma.dtFim,
                gerente_id: this.novoPrograma.gerente_id,
                setor_id: this.novoPrograma.setor_id,
                status: "Proposto"
            })

                .then((response) => {
                    this.getProgramas();
                    this.modalNovoPrograma = false;
                    this.novoPrograma = {
                        "nome": "",
                        "dtInicio": new Date().getFullYear() + '-' + '0' + (new Date().getMonth() + 1) + '-' + new Date().getDate(),
                        "gerente_id": "",
                        "setor_id": "",
                        "dtFim": ""
                    };
                    console.log(response.data);
                })
                .catch((error) => {
                    console.error(error);
                });

        },

        getProgramas() {
            api.get(`programa/listar`, {})
                .then((response) => {
                    this.programas = response.data;
                    this.filtrarProgramas()
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async getGerente() {
            try {
                const { usuarios } = await consultarSetores();
                this.gerente = usuarios;
            } catch (error) {
                console.error(error);
            }
        },

        fecharModalFora(event) {
            if (event.target.classList.contains('modal-mask')) {
                this.modalProjetosAssociados = false;
                this.modalNovoPrograma = false
                this.modalEditarPrograma = false
                this.modalExcluirPrograma = false
                this.modalFinalizarPrograma = false
            }
        },

        verPCM() {
            this.$router.push({ name: "PCM" })
        },

    }

}
</script>

<style scoped></style>