import { api } from "roboflex-thalamus-request-handler"; 

export async function getDados(id) {
    try {
        const dadosUsuario = await api.get(`usuario/${id}/funcionalidades`);

        return dadosUsuario.data;
    } catch (error) {
        console.error(error);
        throw error; 
    }
}