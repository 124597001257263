<template>
    <main id="main">
        <section>
            <div class="titulo">
                <div class="margem container">
                    <div class="m-icone direita"><button @click="criarProtocolo(5)" class="acao-secundaria">Adicionar
                            PLA</button></div>
                    <div class="m-icone direita">
                        <div class="pesquisa">
                            <input type="text" placeholder="Pesquisar Plano de Ação" v-model="protocoloSelecionado"
                                @input="filtrarProtocolos()" />
                            <a disabled class="icone-pesquisa"></a>
                        </div>
                    </div>
                    <h2>Plano de Ação</h2>
                </div>
            </div>
            <div class="margem container">
                <div class="bloco margem">
                    <table class="tabela">
                        <tbody>
                            <tr>
                                <th scope="col">Código</th>
                                <th scope="col">Nome</th>
                                <th scope="col">Status</th>
                                <th scope="col">Data de Registro</th>
                                <th scope="col">Responsável</th>
                                <th scope="col">Setor Envolvido</th>
                            </tr>
                            <tr style="text-align: center;" v-for="item in listaprotocoloFiltrada" :key="item.id"
                                @click="verProtocolo(item.id)">
                                <td style="vertical-align: middle;">
                                    {{ item.codigo }}
                                </td>
                                <td style="vertical-align: middle;">
                                    {{ item.nome }}
                                </td>
                                <td style="vertical-align: middle;">
                                    <select v-model="item.status"
                                        :style="{ 'color': (item.status == 'Aprovado' || item.status == 'Pendente') ? '#00B6B3' : (item.status == 'Em andamento') ? 'var(--cor-ok)' : (item.status == 'Concluído') ? 'var(--cor-sucesso)' : '#FFA500' }"
                                        style="opacity: 1;background-color: transparent;width: fit-content; outline: none; text-align: center; border: none;"
                                        @click.stop @change="editarProtocolo(item.id, 'status', item.status)">

                                        <option style="color: #00b6b3" hidden value="null"><strong>-</strong></option>
                                        <option style="color: #00B6B3;">Pendente</option>
                                        <option style="color: var(--cor-ok);">Em andamento</option>
                                        <option style="color: var(--cor-sucesso);">Concluído</option>
                                        <option style="color: #FFA500;">Suspenso</option>
                                    </select>
                                </td>
                                <td style="vertical-align: middle;">
                                    {{ item.dtInicio ?
                                        `${formatarDataHora(item.dtInicio).split('-')[2]}/${formatarDataHora(item.dtInicio).split('-')[1]}/${formatarDataHora(item.dtInicio).split('-')[0]}`
                                        : '-' }}
                                </td>
                                <td style="vertical-align: middle;">
                                    {{ item.responsavel_nome }}
                                </td>
                                <td style="vertical-align: middle;">
                                    {{ item.setor_nome ? item.setor_nome : '-' }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div v-if="!protocolos" class="loading" id="loading">
                        <div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>


<script>
import { api } from "roboflex-thalamus-request-handler";
import serviceFunções from "@/services/serviceFunções";

export default {
    name: "ControleProtocolo",

    setup() {
        const formatarDataHora = (valor) => {
            return serviceFunções.formatarDataHora(valor);
        };
        return {
            formatarDataHora
        };
    },

    data() {
        return {
            listaprotocoloFiltrada: null,
            protocoloSelecionado: null,
            protocolos: null,
            tipoProtocolo: sessionStorage.getItem('tipoProtocolo')
        }
    },

    mounted() {
        this.getProtocolos()
    },

    methods: {
        editarProtocolo(idProtocolo, itemAlterado, novoValor) {
            api.post(`protocolo/atualizar/${idProtocolo}`, {
                [itemAlterado]: novoValor,
                dtTermino: null
            })
                .then(() => {
                    this.getProtocolos()
                })
        },

        getProtocolos() {
            api.get('protocolo/listar')
                .then((response) => {
                    this.protocolos = response.data
                    this.protocolos = this.protocolos.filter((item) => item.tipo_id == 5)

                    this.protocolos = this.protocolos.sort((a, b) => {
                        if (a.codigo < b.codigo) return 1;
                        if (a.codigo > b.codigo) return -1;
                        return 0;
                    });

                    this.filtrarProtocolos()
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        filtrarProtocolos() {
            if (!this.protocoloSelecionado) {
                this.listaprotocoloFiltrada = this.protocolos;
            } else {
                const textoLowerCase = this.protocoloSelecionado.toLowerCase();
                this.listaprotocoloFiltrada = this.protocolos.filter(projeto => {
                    return Object.values(projeto).some(value => {
                        if (typeof value === 'string') {
                            return value.toLowerCase().includes(textoLowerCase);
                        }
                        return false;
                    });
                });
            }
        },

        criarProtocolo(tipo) {
            if (this.$route.path === '/protocolo/criar') {
                sessionStorage.setItem('tipoProtocolo', tipo)
                window.location.reload();
            } else {
                sessionStorage.setItem('tipoProtocolo', tipo)
                this.$router.push({ name: 'criaçãoProtocolo' })
            }
        },

        verProtocolo(id) {
            this.$router.push({ name: "visualizaçãoProtocolo" })
            sessionStorage.setItem('idProjeto', id)
        }
    }

}
</script>

<style scoped>
.header {
    cursor: pointer;
}
</style>