<template>
    <main id="main">
        <section>
            <div class="titulo">
                <div class="margem container">
                    <div class="m-icone direita">
                        <div class="tags m-b">
                            <a @click="this.projetosOuPlanoAcao = 'projeto', this.carregarProjetos()"
                                :class="projetosOuPlanoAcao == 'projeto' ? 'ativo' : ''">Projetos</a>
                            <a @click="this.projetosOuPlanoAcao = 'planoAcao', this.carregarProjetos()"
                                :class="projetosOuPlanoAcao == 'planoAcao' ? 'ativo' : ''">Planos de Ação</a>
                        </div>
                    </div>
                    <div class="m-icone direita">
                        <div class="pesquisa">
                            <input type="text" placeholder="Pesquisar Projeto" v-model="projetoSelecionado"
                                @input="filtrarProjetos()" />
                            <a disabled class="icone-pesquisa"></a>
                        </div>
                    </div>
                    <h2>Gestão de Projetos</h2>
                </div>
            </div>
            <div class="margem container">
                <div class="alinha-direita" style="margin-bottom: 5px;">
                    <button class="acao-secundaria" @click="baixarXML(projetosOuPlanoAcao)">Baixar relatório</button>
                </div>
                <div class="bloco">
                    <table class="tabela">
                        <tbody>
                            <tr>
                                <th scope="col">
                                    Código
                                </th>
                                <th scope="col">
                                    <span @click="ordenarLista('nome')" style="cursor: pointer;">
                                        Nome
                                    </span>
                                    <i class="bi bi-caret-down-fill" style="display: none;" id="setaBaixonome"></i>
                                    <i class="bi bi-caret-up-fill" style="display: none;" id="setaCimanome"></i>
                                </th>
                                <th scope="col">
                                    <span @click="ordenarLista('status')" style="cursor: pointer;">
                                        Status
                                    </span>
                                    <i class="bi bi-caret-down-fill" style="display: none;" id="setaBaixostatus"></i>
                                    <i class="bi bi-caret-up-fill" style="display: none;" id="setaCimastatus"></i>
                                </th>
                                <th scope="col">
                                    <span @click="ordenarLista('dtInicio')" style="cursor: pointer;">
                                        Data de Início
                                    </span>
                                    <i class="bi bi-caret-down-fill" style="display: none;" id="setaBaixodtInicio"></i>
                                    <i class="bi bi-caret-up-fill" style="display: none;" id="setaCimadtInicio"></i>
                                </th>
                                <th scope="col">
                                    <span @click="ordenarLista('dtTermino')" style="cursor: pointer;">
                                        Data de Termino
                                    </span>
                                    <i class="bi bi-caret-down-fill" style="display: none;" id="setaBaixodtTermino"></i>
                                    <i class="bi bi-caret-up-fill" style="display: none;" id="setaCimadtTermino"></i>
                                </th>
                                <th scope="col">
                                    <span @click="ordenarLista('tarefasTotal')" style="cursor: pointer;">
                                        Qtd. Tarefas
                                    </span>
                                    <i class="bi bi-caret-down-fill" style="display: none;"
                                        id="setaBaixotarefasTotal"></i>
                                    <i class="bi bi-caret-up-fill" style="display: none;" id="setaCimatarefasTotal"></i>
                                </th>
                                <th scope="col">
                                    <span @click="ordenarLista('tarefasPendentes')" style="cursor: pointer;">
                                        Pendentes
                                    </span>
                                    <i class="bi bi-caret-down-fill" style="display: none;"
                                        id="setaBaixotarefasPendentes"></i>
                                    <i class="bi bi-caret-up-fill" style="display: none;"
                                        id="setaCimatarefasPendentes"></i>
                                </th>
                                <th scope="col">
                                    <span @click="ordenarLista('tarefasAndamento')" style="cursor: pointer;">
                                        Andamento
                                    </span>
                                    <i class="bi bi-caret-down-fill" style="display: none;"
                                        id="setaBaixotarefasAndamento"></i>
                                    <i class="bi bi-caret-up-fill" style="display: none;"
                                        id="setaCimatarefasAndamento"></i>
                                </th>
                                <th scope="col">
                                    <span @click="ordenarLista('tarefasConcluidas')" style="cursor: pointer;">
                                        Concluídas
                                    </span>
                                    <i class="bi bi-caret-down-fill" style="display: none;"
                                        id="setaBaixotarefasConcluidas"></i>
                                    <i class="bi bi-caret-up-fill" style="display: none;"
                                        id="setaCimatarefasConcluidas"></i>
                                </th>
                                <th scope="col">
                                    <span @click="ordenarLista('tarefasAtrasadas')" style="cursor: pointer;">
                                        Atrasadas
                                    </span>
                                    <i class="bi bi-caret-down-fill" style="display: none;"
                                        id="setaBaixotarefasAtrasadas"></i>
                                    <i class="bi bi-caret-up-fill" style="display: none;"
                                        id="setaCimatarefasAtrasadas"></i>
                                </th>
                                <th scope="col">
                                    <span @click="ordenarLista('Diasemexecução')" style="cursor: pointer;">
                                        Dias em execução
                                    </span>
                                    <i class="bi bi-caret-down-fill" style="display: none;"
                                        id="setaBaixoDiasemexecução"></i>
                                    <i class="bi bi-caret-up-fill" style="display: none;"
                                        id="setaCimaDiasemexecução"></i>
                                </th>
                                <th scope="col">
                                    <span @click="ordenarLista('tarefasPorcentagemConcluidas')"
                                        style="cursor: pointer;">
                                        Conclusão
                                    </span>
                                    <i class="bi bi-caret-down-fill" style="display: none;"
                                        id="setaBaixotarefasPorcentagemConcluidas"></i>
                                    <i class="bi bi-caret-up-fill" style="display: none;"
                                        id="setaCimatarefasPorcentagemConcluidas"></i>
                                </th>
                            </tr>
                            <tr v-for="projeto in listaProjetosFiltrada" :key="projeto">
                                <td>
                                    {{ projeto.pcm_codigo ? projeto.pcm_codigo : '-' }}
                                </td>
                                <td>
                                    {{ projeto.nome }}
                                </td>
                                <td>
                                    {{ projeto.status }}
                                </td>
                                <td>
                                    {{ projeto.dtInicio ?
                                        `${projeto.dtInicio.split('-')[2]}/${projeto.dtInicio.split('-')[1]}/${projeto.dtInicio.split('-')[0]}`
                                        : '-' }}
                                </td>
                                <td>
                                    {{ projeto.dtTermino ?
                                        `${projeto.dtTermino.split('-')[2]}/${projeto.dtTermino.split('-')[1]}/${projeto.dtTermino.split('-')[0]}`
                                        : '-' }}
                                </td>
                                <td>
                                    {{ projeto.tarefasTotal }}
                                </td>
                                <td>
                                    {{ projeto.tarefasPendentes }}
                                </td>
                                <td>
                                    {{ projeto.tarefasAndamento }}
                                </td>
                                <td>
                                    {{ projeto.tarefasConcluidas }}
                                </td>
                                <td>
                                    {{ projeto.tarefasAtrasadas }}
                                </td>
                                <td>
                                    {{ projeto.Diasemexecução }} Dias
                                </td>
                                <td>
                                    {{ projeto.tarefasPorcentagemConcluidas }} %
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
import serviceGestaoPRJ from '@/services/serviceGestaoProjetos';

export default {
    name: "gestaoProjetos",

    data() {
        return {
            projetoSelecionado: null,
            listaProjetosFiltrada: [],
            projetos: [],
            projetosOuPlanoAcao: 'projeto'
        }
    },
    mounted() {
        this.carregarProjetos()
    },
    methods: {
        baixarXML(projetosOuPlanoAcao) {
            serviceGestaoPRJ.baixarXML(projetosOuPlanoAcao);
        },

        ordenarLista(itemReferencia) {
            for (var item of ['nome', 'status', 'dtInicio', 'dtTermino', 'tarefasTotal', 'tarefasPendentes', 'tarefasAndamento', 'tarefasConcluidas','tarefasAtrasadas', 'Diasemexecução', 'tarefasPorcentagemConcluidas']) {
                if (item !== itemReferencia) {
                    document.getElementById(`setaBaixo${item}`).style.display = 'none'
                    document.getElementById(`setaCima${item}`).style.display = 'none'
                }
            }

            if (itemReferencia == 'tarefasTotal' || itemReferencia == 'tarefasPendentes' || itemReferencia == 'tarefasAndamento' || itemReferencia == 'tarefasConcluidas' || itemReferencia == 'tarefasAtrasadas' || itemReferencia == 'tarefasPorcentagemConcluidas' || itemReferencia == 'Diasemexecução') {
                if (document.getElementById(`setaBaixo${itemReferencia}`).style.display === 'none') {
                    this.projetos = this.projetos.sort((a, b) => {
                        const dataInicioA = a[itemReferencia];
                        const dataInicioB = b[itemReferencia];
                        return dataInicioB - dataInicioA;
                    });
                    document.getElementById(`setaBaixo${itemReferencia}`).style.display = 'inline'
                    document.getElementById(`setaCima${itemReferencia}`).style.display = 'none'
                    return
                } else if (document.getElementById(`setaBaixo${itemReferencia}`).style.display === 'inline') {
                    this.projetos = this.projetos.sort((a, b) => {
                        const dataInicioA = a[itemReferencia];
                        const dataInicioB = b[itemReferencia];
                        return dataInicioA - dataInicioB;
                    });
                    document.getElementById(`setaBaixo${itemReferencia}`).style.display = 'none'
                    document.getElementById(`setaCima${itemReferencia}`).style.display = 'inline'
                    return
                }
                return
            }

            if (itemReferencia == 'dtInicio' || itemReferencia == 'dtTermino') {
                if (document.getElementById(`setaBaixo${itemReferencia}`).style.display === 'none') {
                    this.projetos = this.projetos.sort((a, b) => {
                        const dataInicioA = new Date(a[itemReferencia]);
                        const dataInicioB = new Date(b[itemReferencia]);
                        return dataInicioB - dataInicioA;
                    });
                    document.getElementById(`setaBaixo${itemReferencia}`).style.display = 'inline'
                    document.getElementById(`setaCima${itemReferencia}`).style.display = 'none'
                    return
                } else if (document.getElementById(`setaBaixo${itemReferencia}`).style.display === 'inline') {
                    this.projetos = this.projetos.sort((a, b) => {
                        const dataInicioA = new Date(a[itemReferencia]);
                        const dataInicioB = new Date(b[itemReferencia]);
                        return dataInicioA - dataInicioB;
                    });
                    document.getElementById(`setaBaixo${itemReferencia}`).style.display = 'none'
                    document.getElementById(`setaCima${itemReferencia}`).style.display = 'inline'
                    return
                }
                // --------------------------------------------------------------------------------------------------------------------------
            } else if (itemReferencia == 'status') {
                if (document.getElementById(`setaBaixo${itemReferencia}`).style.display === 'none') {

                    this.projetos = this.projetos.sort((a, b) => {
                        const order = {
                            'Em andamento': 1,
                            'Aprovado': 2,
                            'Proposto': 3,
                            'Concluído': 4,
                            'Suspenso': 5
                        };
                        const orderA = order[a.status] || 0;
                        const orderB = order[b.status] || 0;
                        if (orderA < orderB) {
                            return -1;
                        }
                        if (orderA > orderB) {
                            return 1;
                        }
                        return 0;
                    });


                    document.getElementById(`setaBaixo${itemReferencia}`).style.display = 'inline'
                    document.getElementById(`setaCima${itemReferencia}`).style.display = 'none'
                    return
                } else if (document.getElementById(`setaBaixo${itemReferencia}`).style.display === 'inline') {


                    this.projetos = this.projetos.sort((a, b) => {
                        const order = {
                            'Em andamento': 1,
                            'Aprovado': 2,
                            'Proposto': 3,
                            'Concluído': 4
                        };
                        const orderA = order[a.status] || 0;
                        const orderB = order[b.status] || 0;
                        if (orderA < orderB) {
                            return -1;
                        }
                        if (orderA > orderB) {
                            return 1;
                        }
                        return 0;
                    });


                    document.getElementById(`setaBaixo${itemReferencia}`).style.display = 'none'
                    document.getElementById(`setaCima${itemReferencia}`).style.display = 'inline'
                    return
                }
            }
            // --------------------------------------------------------------------------------------------------------------------------
            else {
                if (document.getElementById(`setaBaixo${itemReferencia}`).style.display === 'none') {
                    this.projetos = this.projetos.sort((a, b) => {
                        const statusA = a[itemReferencia] ? a[itemReferencia].toLowerCase() : '';
                        const statusB = b[itemReferencia] ? b[itemReferencia].toLowerCase() : '';

                        if (statusA < statusB) {
                            return -1;
                        }
                        if (statusA > statusB) {
                            return 1;
                        }
                        return 0;
                    });
                    document.getElementById(`setaBaixo${itemReferencia}`).style.display = 'inline'
                    document.getElementById(`setaCima${itemReferencia}`).style.display = 'none'
                    return
                } else if (document.getElementById(`setaBaixo${itemReferencia}`).style.display === 'inline') {
                    this.projetos = this.projetos.sort((a, b) => {
                        const statusA = a[itemReferencia] ? a[itemReferencia].toLowerCase() : '';
                        const statusB = b[itemReferencia] ? b[itemReferencia].toLowerCase() : '';

                        if (statusA > statusB) {
                            return -1;
                        }
                        if (statusA < statusB) {
                            return 1;
                        }
                        return 0;
                    });
                    document.getElementById(`setaBaixo${itemReferencia}`).style.display = 'none'
                    document.getElementById(`setaCima${itemReferencia}`).style.display = 'inline'
                    return
                }
            }
        },

        filtrarProjetos() {
            if (!this.projetoSelecionado) {
                this.listaProjetosFiltrada = this.projetos;
            } else {
                const textoLowerCase = this.projetoSelecionado.toLowerCase();
                this.listaProjetosFiltrada = this.projetos.filter(projeto => {
                    return Object.values(projeto).some(value => {
                        if (typeof value === 'string') {
                            return value.toLowerCase().includes(textoLowerCase);
                        } else if (typeof value === 'number') {
                            return value.toString().toLowerCase().includes(textoLowerCase);
                        }
                        return false;
                    });
                });
            }
        },

        async carregarProjetos() {
            document.getElementById(`setaBaixostatus`).style.display = 'none'
            document.getElementById(`setaBaixostatus`).style.display = 'none'
            try {
                this.projetos = await serviceGestaoPRJ.buscarProjetos(this.projetosOuPlanoAcao);
                this.projetos = this.projetos.map(projeto => {
                    return {
                        ...projeto,
                        Diasemexecução: this.calculateDaysDifference(projeto.dtInicio)
                    };
                });
                this.filtrarProjetos();
                this.ordenarLista('status')
            } catch (error) {
                console.error("Erro ao carregar projetos:", error);
            }
        },

        calculateDaysDifference(data) {
            const differenceInTime = new Date() - new Date(data);
            const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
            return differenceInDays
        }
    }
}

</script>
<style>
td {
    text-align: center;
}

/* th {
    position: sticky !important;
    top: 0 !important;
    z-index: 999 !important;
    background-color: var(--cor-bg);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    text-align: center !important;
} */
</style>
