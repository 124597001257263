<template>
    <main id="main">
        <section>
            <div class="titulo">
                <div class="margem container">
                    <div class="m-icone direita">
                        <div class="pesquisa">
                            <input type="text" placeholder="Pesquisar PCM" v-model="PCMSelecionado"
                                @input="filtrarPCMs()" />
                            <a class="icone-pesquisa" title="Pesquise"></a>
                        </div>
                    </div>
                    <h2>Propostas de Criação ou Mudança</h2>
                </div>
            </div>
            <div class="margem container">
                <div class="bloco margem">
                    <div class="tags">
                        <a v-if="dadosUsuario && dadosUsuario.grupo.includes('Diretoria')"
                            @click="mostrarOperacionais = !mostrarOperacionais, this.getPCMs()"
                            :class="mostrarOperacionais == true ? 'ativo' : ''" style="cursor: pointer;">Mostrar
                            operacionais</a>

                        <a @click="mostrarAprovados = !mostrarAprovados, this.getPCMs()"
                            :class="mostrarAprovados == true ? 'ativo' : ''" style="cursor: pointer;">Mostrar aprovador
                            e reprovados</a>
                    </div>
                    <table class="tabela">
                        <tbody>
                            <tr>
                                <th>
                                </th>
                                <th scope="col">
                                    Tipo
                                </th>
                                <th scope="col">
                                    Código
                                </th>
                                <th scope="col">
                                    Nome
                                </th>
                                <th scope="col">
                                    Setor aprovador
                                </th>
                                <th scope="col">
                                    Data Início
                                </th>
                                <th scope="col">
                                    Status
                                </th>
                            </tr>
                            <tr @click="item.tipo == 'Encerramento' ? abrirModal(item) : verPCM(item.id)"
                                v-for="item in listaPCMsFiltrada" :key="item.id">
                                <td>
                                    <i v-if="item.nivel == 'Estratégico'"
                                        style="font-size: 19px; color: var(--cor-fonte)"
                                        class="fa-solid fa-chess-rook"></i>
                                    <i v-if="item.nivel == 'Operacional'"
                                        style="font-size: 19px; color: var(--cor-fonte)"
                                        class="fa-solid fa-screwdriver-wrench"></i>
                                    <i v-if="item.tipo == 'Encerramento'"
                                        style="font-size: 20px; color: var(--cor-fonte)" class="bi bi-flag-fill"></i>
                                </td>

                                <td style="white-space: nowrap;">
                                    {{ item.tipo }}
                                </td>

                                <td>
                                    {{ item.codigo ?? '-' }}
                                </td>

                                <td>
                                    <span :title="item.nome" style="width: 100% !important;">
                                        {{ item.nome ?? item.projeto_nome }}
                                    </span>
                                </td>

                                <td>
                                    {{ item.setorAprovacao_nome ?? '-' }}
                                </td>

                                <td>
                                    {{ item.dtInicio ?
                                        `${formatarDataHora(item.dtInicio).split('-')[2]}/${formatarDataHora(item.dtInicio).split('-')[1]}/${formatarDataHora(item.dtInicio).split('-')[0]}`
                                        : '-' }}
                                </td>

                                <td
                                    :style="{ 'color': (item.status == 'Aguardando Aprovação' || item.status == 'Aprovação Estratégica' || item.status == 'Aprovação Técnica') ? 'rgb(255, 145, 0)' : (item.status == 'Reprovado') ? '#e02130' : (item.status == 'Aprovado') ? 'rgb(0, 192, 0)' : 'red', }">
                                    {{ item.status ?? 'Aguardando Aprovação' }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
        <!-- MODAL ENCERRAR PROJETO -->
        <div style="overflow: auto" class="modal-mask" v-if="modalEncerrarProjeto"
            @click="modalEncerrarProjeto = false">
            <div class="jm" style="height: min-content; width: 50rem; padding: 2rem;" @click.stop>
                <div style="display: flex; justify-content: center">
                    <h2>Encerrar: {{ propostaEncerramento.projeto_nome }}</h2>
                </div>
                <br>
                <fieldset class="margem">
                    <div class="grid-2">
                        <div>
                            <label>Data de termino do projeto</label>
                            <input v-model="propostaEncerramento.dtConclusao" type="date">
                        </div>
                        <div class="grid-2">
                            <div>
                                <label>Custo previsto</label>
                                <money3 v-bind="config" v-model="propostaEncerramento.custoEstimado"
                                    style="width: 8rem; border: none; outline: none; font-size: 20px; background-color: transparent;">
                                </money3>
                            </div>
                            <div>
                                <label>Custo Real</label>
                                <money3 v-bind="config" v-model="propostaEncerramento.custoReal"
                                    style="width: 8rem; border: none; outline: none; font-size: 20px; background-color: transparent;">
                                </money3>
                            </div>
                        </div>
                        <div>
                            <label>
                                Evidencias de alcance
                            </label>
                            <textarea v-model="propostaEncerramento.evidenciaAlcance"></textarea>
                        </div>
                        <div>
                            <label>Escopo alcançado</label>
                            <textarea v-model="propostaEncerramento.escopoAlcancado"></textarea>
                        </div>
                        <div>
                            <label>resultado Obtido</label>
                            <textarea v-model="propostaEncerramento.resultadoObtido"></textarea>
                        </div>
                        <div>
                            <label>Conclusão</label>
                            <textarea v-model="propostaEncerramento.conclusao"></textarea>
                        </div>
                    </div>
                </fieldset>
                <div style="align-content: end; margin-left: 1rem;">
                    <button :disabled="propostaEncerramento.status !== 'Aguardando aprovação'"
                        @click="aprovarEncerramento(propostaEncerramento.projeto_id)"
                        class="aprovar acao-secundaria bg-sucesso">Aprovar</button>
                    <button :disabled="propostaEncerramento.status !== 'Aguardando aprovação'"
                        @click="reprovarEncerramento(propostaEncerramento.projeto_id)" style="margin-left: 5px;"
                        class="reprovar acao-secundaria bg-erro">Reprovar</button>
                </div>
            </div>
        </div>
        <!-- END MODAL -->
    </main>
</template>
<script>

import { api } from "roboflex-thalamus-request-handler";
import { sso } from "roboflex-thalamus-sso-lib";
import { getDados } from '@/services/serviceFuncionalidades';
import { consultarSetores } from '@/services/usuario-setor';
import { Money3Component } from "v-money3";
import serviceFunções from "@/services/serviceFunções";
import Cookies from 'js-cookie'

export default {
    name: "ControlePCM",

    components: { money3: Money3Component },

    setup() {
        const formatarDataHora = (valor) => {
            return serviceFunções.formatarDataHora(valor);
        };
        return {
            formatarDataHora
        };
    },

    data() {
        return {
            modalEncerrarProjeto: false,
            propostaEncerramento: null,
            SolicitaçãoEncerramento: [],
            config: {
                masked: false,
                prefix: "R$ ",
                thousands: ".",
                decimal: ",",
                precision: 2,
            },

            PCMSelecionado: null,
            listaPCMsFiltrada: null,
            tipo: null,

            objetoReferenciaFiltro: 'status',
            valorReferencia: null,

            mostrarOperacionais: false,
            mostrarAprovados: false,

            PCMs: null,
            idUsuario: null,

            dadosUsuario: null,

            teste: 'teste',
            usuarios: null,
            setores: null
        }
    },

    created() {

        const usuarioLogado = sso.getUsuarioLogado()
        // this.userName = usuarioLogado.nome // nome
        this.idUsuario = usuarioLogado.id // id

        this.carregarPermissoes();
        this.getSetores();
    },

    methods: {
        abrirModal(item) {
            this.propostaEncerramento = item
            this.modalEncerrarProjeto = true
        },

        aprovarEncerramento(id) {
            api.put(`projeto/encerrar/${id}`, {
                status: 'Aprovado'
            })
            this.getPCMs()
        },

        reprovarEncerramento(id) {
            api.put(`projeto/encerrar/${id}`, {
                status: 'Reprovado'
            })
            this.getPCMs()
        },

        apagarPCM(item) {
            api.put(`pcm/excluir/${item.id}`, {
                usuario_id: this.idUsuario
            })
                .then(() => {
                    this.getPCMs()
                })
        },

        carregarPermissoes() {
            var id = this.idUsuario
            getDados(id)
                .then(dadosUsuario => {
                    this.dadosUsuario = dadosUsuario;
                    this.getPCMs()
                })
                .catch(error => {
                    console.error(error);
                    console.log('Falha ao colher dados do usuario')
                });
        },

        atualizarPCM(itemEditado, valor, id) {
            if (valor == 'Aprovação Estratégica') {
                api.put(`pcm/atualizar/${id}`, {
                    [itemEditado]: valor,
                    aprovada: 0
                })
                    .then(() => {
                        this.getPCMs()
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
            if (valor == 'Aprovado') {
                api.put(`pcm/atualizar/${id}`, {
                    [itemEditado]: valor,
                    aprovada: 1
                })
                    .then(() => {
                        this.getPCMs()
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
            if (valor == 'Reprovado') {
                api.put(`pcm/atualizar/${id}`, {
                    [itemEditado]: valor,
                    aprovada: 0
                })
                    .then(() => {
                        this.getPCMs()
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        },

        filtrarPCMs() {
            if (!this.PCMSelecionado) {
                this.listaPCMsFiltrada = this.PCMs;
            } else {
                const textoLowerCase = this.PCMSelecionado.toLowerCase();
                this.listaPCMsFiltrada = this.PCMs.filter(pcm => {
                    // Verifica se alguma chave do objeto contém o texto
                    return Object.values(pcm).some(value => {
                        if (typeof value === 'string') {
                            return value.toLowerCase().includes(textoLowerCase);
                        }
                        return false;
                    });
                });
            }
            this.listaPCMsFiltrada = this.listaPCMsFiltrada.concat(this.SolicitaçãoEncerramento)
        },

        getPCMs() {
            api.get(`pcm/listar`)
                .then((response) => {
                    this.PCMs = response.data;

                    if (!this.dadosUsuario.grupo.includes('Diretoria')) {
                        this.PCMs = this.PCMs.filter(item => this.dadosUsuario.setor_responsavel.includes(item.setor_id) || this.dadosUsuario.setor_responsavel.includes(item.setorDemanda_id) && item.status == 'Aprovação Técnica')
                        this.mostrarOperacionais = true
                    }
                    else if (this.dadosUsuario.grupo.includes('Diretoria')) {
                        // this.PCMs = this.PCMs.filter(item => item.status !== 'Aprovação Técnica')
                    }

                    if (this.mostrarOperacionais == false) {
                        this.PCMs = this.PCMs.filter(item => item.nivel !== 'Operacional');
                        this.PCMs = this.PCMs.filter(item => item.status !== 'Aprovação Técnica')
                    }

                    if (this.mostrarAprovados == false) {
                        this.PCMs = this.PCMs.filter(item => item.status !== 'Aprovado' && item.status !== 'Reprovado')
                    }

                    this.PCMs = this.PCMs.sort((a, b) => {
                        const statusComparison = a.status.localeCompare(b.status);
                        if (statusComparison !== 0) {
                            return statusComparison;
                        } else {
                            const dateA = new Date(a.dtInicio).getTime();
                            const dateB = new Date(b.dtInicio).getTime();
                            return dateB - dateA;
                        }
                    });

                    this.modalEncerrarProjeto = false
                    api.get('projeto/encerrados/listar')
                        .then((response) => {
                            this.SolicitaçãoEncerramento = response.data.data
                            this.SolicitaçãoEncerramento = this.SolicitaçãoEncerramento.map(item => ({
                                ...item,
                                tipo: 'Encerramento',
                                dtConclusao: item.dtConclusao.slice(0, 10)
                            }));
                            if (this.mostrarAprovados == false) {
                                this.SolicitaçãoEncerramento = this.SolicitaçãoEncerramento.filter(item => item.status !== 'Aprovado' && item.status !== 'Reprovado')
                            }
                            if (!this.dadosUsuario.grupo.includes('Diretoria')) {
                                this.SolicitaçãoEncerramento = this.SolicitaçãoEncerramento.filter(item => item.nivel !== 'Estratégico')
                            }
                            this.filtrarPCMs()
                        })
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        verPCM(id) {
            if (this.dadosUsuario.funcionalidade_id.includes(56)) {
                Cookies.set('pcmDetails', JSON.stringify({
                    perfil: 1
                }), { expires: 1 })
                this.$router.push({ name: "PCM", params: { idPCM: id } })
            } else {
                return
            }
        },

        async getSetores() {
            try {
                const { usuarios, setores } = await consultarSetores();
                this.usuarios = usuarios;
                this.setores = setores;
            } catch (error) {
                console.error(error);
            }
        },
    }
}
</script>

<style scoped>
td,
th,
tr {
    text-align: center;
}

.button {
    width: 7rem;
    padding: 5px !important;
    border: none !important;
    border-radius: 6px !important;
    cursor: pointer;
    color: var(--cor-fonte);
}

.reprovar {
    background-color: #e02130;
}

.aprovar {
    background-color: #429867;
}

.reprovar:disabled {
    background-color: #e0213171 !important;
    cursor: not-allowed !important;
}


.aprovar:disabled {
    background-color: #4298677e !important;
    cursor: not-allowed !important;
}

.aprovar:hover,
.reprovar:hover {
    transition: 50ms linear;
    transform: scale(1.1);
}
</style>