<template>
    <main id="main">
        <section>
            <div class="titulo">
                <div class="margem container">
                    <!-- <div class="m-icone direita"><button @click="verPCMCriada('PAP', 'Operacional')"
                            class="acao-secundaria">Novo PAP</button></div> -->
                    <div class="m-icone direita">
                        <div class="pesquisa">
                            <input type="text" placeholder="Pesquisar PAP" v-model="planoSelecionado"
                                @input="filtrarPlanosdeAção()" />
                            <a class="icone-pesquisa"></a>
                        </div>
                    </div>
                    <h2>Planos de Ação (descontinuado)</h2>
                </div>
            </div>
            <div class="margem container">
                <div>
                    <div class="container">
                        <div class="bloco margem">
                            <table class="tabela">
                                <tbody>
                                    <tr>
                                        <th scope="col" style="text-align: center;">
                                            Código
                                        </th>
                                        <th scope="col" style="text-align: center; white-space: nowrap;">
                                            <span @click="ordenarLista('nome')">
                                                Assunto
                                            </span>
                                            <i class="bi bi-caret-down-fill" style="display: none;"
                                                id="setaBaixonome"></i>
                                            <i class="bi bi-caret-up-fill" style="display: none;" id="setaCimanome"></i>
                                        </th>
                                        <th scope="col">
                                            <span @click="ordenarLista('status')" style="cursor: pointer;">
                                                Status
                                            </span>
                                            <i class="bi bi-caret-down-fill" style="display: none;"
                                                id="setaBaixostatus"></i>
                                            <i class="bi bi-caret-up-fill" style="display: none;"
                                                id="setaCimastatus"></i>
                                        </th>
                                        <th scope="col" style="width: 10rem;">
                                            <span @click="ordenarLista('dtInicio')" style="cursor: pointer;">
                                                Data de Início
                                            </span>
                                            <i class="bi bi-caret-down-fill" style="display: none;"
                                                id="setaBaixodtInicio"></i>
                                            <i class="bi bi-caret-up-fill" style="display: none;"
                                                id="setaCimadtInicio"></i>
                                        </th>
                                        <th scope="col" style="width: 10rem;">
                                            <div style="display: flex; align-items: center;">
                                                <span @click="ordenarLista('dtTermino')" style="cursor: pointer;">
                                                    Data de Termino
                                                </span>
                                                <i class="bi bi-caret-down-fill" style="display: none;"
                                                    id="setaBaixodtTermino"></i>
                                                <i class="bi bi-caret-up-fill" style="display: none;"
                                                    id="setaCimadtTermino"></i>
                                            </div>
                                        </th>
                                        <th scope="col">
                                            <div style="display: flex; align-items: center;">
                                                <span @click="ordenarLista('gerente_name')" style="cursor: pointer;">
                                                    Gerente Responsável
                                                </span>
                                                <i class="bi bi-caret-down-fill" style="display: none;"
                                                    id="setaBaixogerente_name"></i>
                                                <i class="bi bi-caret-up-fill" style="display: none;"
                                                    id="setaCimagerente_name"></i>
                                            </div>
                                        </th>
                                        <th scope="col">
                                            <div style="display: flex; align-items: center;">
                                                <span @click="ordenarLista('setor_nome')" style="cursor: pointer;">
                                                    Setor Envolvido
                                                </span>
                                                <i class="bi bi-caret-down-fill" style="display: none;"
                                                    id="setaBaixosetor_nome"></i>
                                                <i class="bi bi-caret-up-fill" style="display: none;"
                                                    id="setaCimasetor_nome"></i>
                                            </div>
                                        </th>
                                        <th scope="col"></th>
                                    </tr>
                                    <tr v-for="item in listaPlanosFiltrada" :key="item.id" style="text-align: center;"
                                        @mouseover="mostrarBotao(item.id, true)" @click="verBacklogs(item)"
                                        @mouseleave="mostrarBotao(item.id, false)">
                                        <td style="vertical-align: middle; text-align: center; cursor: pointer;">{{
                                            item.pcm_codigo ?
                                                item.pcm_codigo : '-' }}</td>
                                        <td style="vertical-align: middle; text-align: center; cursor: pointer">{{
                                            item.nome }}</td>
                                        <td style="vertical-align: middle;" class="clicavel">
                                            <select v-model="item.status"
                                                :disabled="dadosUser.includes('Diretoria') || dadosUser.includes('Auditor Projetos') ? false : (item.permissao).find(pessoa => pessoa.usuario_id == this.idUsuario).nivel == 1"
                                                :style="{ 'color': (item.status == 'Aprovado') ? '#00B6B3' : (item.status == 'Em andamento') ? 'var(--cor-ok)' : (item.status == 'Concluído') ? 'var(--cor-sucesso)' : '#FFA500', 'cursor': dadosUser.includes('Diretoria') || dadosUser.includes('Auditor Projetos') ? false : (item.permissao).find(pessoa => pessoa.usuario_id == this.idUsuario).nivel == 1 ? 'not-allowed' : '' }"
                                                style="opacity: 1;background-color: transparent;width: fit-content; outline: none; text-align: center; border: none;"
                                                @click.stop @change="editarPlanoInline(item.id, 'status', item.status)">

                                                <option style="color: #00B6B3;">Aprovado</option>
                                                <option style="color: var(--cor-ok);">Em andamento</option>
                                                <option style="color: var(--cor-sucesso);">Concluído</option>
                                                <option style="color: #FFA500;">Suspenso</option>
                                            </select>
                                        </td>
                                        <td style="vertical-align: middle;">

                                            {{ item.dtInicio ?
                                                `${formatarDataHora(item.dtInicio).split('-')[2]}/${formatarDataHora(item.dtInicio).split('-')[1]}/${formatarDataHora(item.dtInicio).split('-')[0]}`
                                                : '' }}

                                        </td>
                                        <td style="vertical-align: middle;">

                                            {{ item.dtTermino ?
                                                `${formatarDataHora(item.dtTermino).split('-')[2]}/${formatarDataHora(item.dtTermino).split('-')[1]}/${formatarDataHora(item.dtTermino).split('-')[0]}`
                                                : '-' }}

                                        </td>
                                        <td style="vertical-align: middle;" class="text-nowrap">{{ item.gerente_name
                                            ?
                                            nomeEsobrenome(item.gerente_name) : '-' }}</td>
                                        <td style="vertical-align: middle;">{{ item.setor_nome }}</td>
                                        <td style="vertical-align: middle;">
                                            <div style="width: max-content; visibility: hidden;"
                                                :id="'botaoEdicao' + item.id">
                                                <v-menu v-if="Array.isArray(planosAcao) && planosAcao.length > 0">
                                                    <template v-slot:activator="{ props }">
                                                        <v-btn
                                                            style="width: 2rem; height: 2rem; border: 1px solid var(--cor-separador); color: var(--cor-fonte);"
                                                            class="botaoAdicionarSprint acao-secundaria"
                                                            icon="mdi-dots-horizontal" v-bind="props"></v-btn>
                                                    </template>

                                                    <v-list
                                                        style="background-color: var(--cor-bg); color: var(--cor-fonte);">
                                                        <v-list-item
                                                            :disabled="dadosUser.includes('Diretoria') || dadosUser.includes('Auditor Projetos') ? false : (item.permissao).find(pessoa => pessoa.usuario_id == this.idUsuario).nivel == 1"
                                                            @click="modalCompartilharPlano = true, this.planoEditado = item, this.planoEditado.permissao = this.planoEditado.permissao.filter((pessoa) => pessoa.automatico == 0)">
                                                            Compartilhar
                                                            <br />
                                                        </v-list-item>
                                                        <v-list-item
                                                            :disabled="dadosUser.includes('Diretoria') || dadosUser.includes('Auditor Projetos') ? false : (item.permissao).find(pessoa => pessoa.usuario_id == this.idUsuario).nivel == 1"
                                                            @click="modalEditarPlano = true, this.planoEditado = item, this.planoEditado.dtTermino !== null ? this.planoEditado.dtTermino = this.planoEditado.dtTermino.slice(0, 10) : '', this.planoEditado.dtInicio !== null ? this.planoEditado.dtInicio = this.planoEditado.dtInicio.slice(0, 10) : ''">Editar
                                                        </v-list-item>
                                                        <v-list-item
                                                            :disabled="dadosUser.includes('Diretoria') || dadosUser.includes('Auditor Projetos') ? false : (item.permissao).find(pessoa => pessoa.usuario_id == this.idUsuario).nivel == 1"
                                                            @click="modalFinalizarPlano = true, this.planoEditado = item">
                                                            Finalizar
                                                        </v-list-item>
                                                        <v-list-item
                                                            :disabled="dadosUser.includes('Diretoria') || dadosUser.includes('Auditor Projetos') ? false : (item.permissao).find(pessoa => pessoa.usuario_id == this.idUsuario).nivel == 1"
                                                            @click="modalExcluirPlano = true, this.planoEditado = item"
                                                            style="color: var(--cor-erro);">
                                                            Excluir
                                                        </v-list-item>
                                                    </v-list>
                                                </v-menu>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div v-if="!planosAcao" class="loading">
                                <div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- MODAL compartilhar Plano-->
                <div class="modal-mask" v-if="modalCompartilharPlano" @click="fecharModalFora">
                    <div style="height: 85%; width: 50rem; padding: 1rem 2rem; overflow: auto;" class="jm">

                        <div>
                            <h2>Compartilhar: {{ planoEditado.nome }} </h2>
                            <hr>
                            <br>
                        </div>

                        <div style="width: 100%; display: flex; justify-content: center;">
                            <div style="display: flex; flex-flow: column; width: 100%; height: 10rem;">
                                <input type="text" v-model="pessoaSelecionada"
                                    @focusin="this.procurar($event.target.value)"
                                    @input="this.procurar($event.target.value)" @focusout="fecharLista()"
                                    placeholder="Adicionar Participante">

                                <div style="height: fit-content; max-height: 15rem ; overflow: auto; background-color: var(--cor-bg); border: 2px solid var(--cor-separador)  ;border-bottom-left-radius: 4px; border-bottom-right-radius: 4px; position: absolute; margin-top: 3.5rem; width: 30rem;"
                                    v-if="listaPessoasFiltrada">
                                    <ul style="list-style: none;">
                                        <li @click="atualizarPermissão(item, 'adicionar')"
                                            v-for="item in listaPessoasFiltrada" :key="item.id">
                                            <div style="display: flex; ; align-items: center; padding: 5px; border-radius: 10px; margin-right: 3rem;"
                                                :style="{ 'color': (this.planoEditado.permissao.map((item) => item.usuario_id)).includes(item.id) ? 'grey' : 'black', 'cursor': (this.planoEditado.permissao.map((item) => item.usuario_id)).includes(item.id) ? 'not-allowed' : '' }">
                                                {{ item.nomeCompleto }} {{ (this.planoEditado.permissao.map((item) =>
                                                    item.usuario_id)).includes(item.id) ? '(Já adicionado)' : '' }}
                                            </div>
                                        </li>
                                    </ul>
                                </div>


                                <br>
                                <h3>Pessoas com acesso:</h3>
                                <ul style="list-style: none;">
                                    <li v-for="item in reordenarArray(planoEditado.permissao) " :key="item.usuario_id"
                                        style="display: flex; align-items: center;">
                                        <div
                                            style="display: flex; border: 1px solid var(--cor-separador);  height: 2.5rem ;align-items: center; justify-content: space-between; padding: 5px; border-radius: 10px; width: 90%; margin-top: 0.3rem;">
                                            {{ item.nome }} {{ item.usuario_id == planoEditado.gerente_id ? '(Gerente)'
                                                :
                                                item.usuario_id == this.idUsuario ? '(Você)' : '' }}
                                            <select
                                                style="width: 6rem; text-align: center; height: 2rem; padding: 0; padding-right: 1rem"
                                                v-model="item.nivel" @change="atualizarPermissão(item, 'atualizar')"
                                                :disabled="item.usuario_id == planoEditado.gerente_id || item.usuario_id == this.idUsuario">
                                                <option :value="1">Leitor</option>
                                                <option :value="2">Editor</option>
                                            </select>
                                        </div>
                                        <i v-if="parseInt(item.usuario_id) !== parseInt(this.idUsuario) && parseInt(item.usuario_id) !== parseInt(this.planoEditado.gerente_id)"
                                            @click=" atualizarPermissão(item, 'remover')"
                                            style="margin-left: 1rem; font-size: 20px; color: red;"
                                            :id="'botaoEdicao' + item.id"
                                            class="bi bi-dash-circle botaoAdicionarSprint"></i>
                                    </li>
                                    <br><br><br>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <!--END MODAL-->

                <!-- MODAL FINALIZAR PLANO-->
                <div class="modal-mask" v-if="modalFinalizarPlano" @click="fecharModalFora">
                    <div class="jm" style="height: min-content; width: 45rem; padding: 2rem;">
                        <div style="display: flex; justify-content: center">
                            <h2>{{ planoEditado.nome }}</h2>
                        </div>
                        <br>

                        <div style="width: 100%; display: flex; justify-content: center;">
                            <div style="width: 50%;">
                                <label>Data de termino</label>
                                <input :class="{ shake: disabled }" v-model="dataTerminoPlano" id="dataTermino"
                                    type="date">
                            </div>
                            <div style="align-content: end; margin-left: 1rem;">
                                <button @click="finalizarPlano()">
                                    Finalizar PAP</button>
                            </div>
                        </div>
                    </div>
                </div>
                <!--END MODAL-->

                <!-- MODAL EXCLUIR PLANO DE AÇÃO -->
                <div style="overflow: auto" class="modal-mask" v-if="modalExcluirPlano" @click="fecharModalFora">
                    <div class="jm" style="height: min-content; width: 50rem; padding: 2rem;">
                        <div style="text-align: center; width: 100%;">
                            <div>
                                <h2>Deseja excluir {{ planoEditado.nome }}?</h2>
                            </div>
                            <br>
                            <div class="modal-footer">
                                <button type="button" class="button" @click="excluirPlano">Confirmar</button>
                                <button type="button" class="button acao-secundaria" @click="fecharModalExcluirPlano"
                                    style="margin-left: 0.5rem;">Cancelar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- END MODAL -->

                <!-- MODAL EDITAR PLANO DE AÇÃO -->
                <div style="overflow: auto" class="modal-mask" v-if="modalEditarPlano" @click="fecharModalFora">
                    <div style="height: 70%; width: 50rem; padding: 2rem; margin-bottom: 3rem;" class="jm">
                        <div>
                            <div style="display: flex; justify-content: space-between">
                                <h3>Editar: {{ planoEditado.nome }} </h3>
                            </div>
                            <hr>
                            <br>
                            <div class="grid-2">

                                <div>
                                    <label>Assunto</label>
                                    <input type="text" v-model="planoEditado.nome"
                                        @focusout="editarPlano('nome', planoEditado.nome)">
                                </div>

                                <div>
                                    <label>Gerente Responsável</label>
                                    <select v-model="planoEditado.gerente_id"
                                        @change="editarPlano('gerente_id', $event.target.value)">
                                        <option v-for="item in gerente" :key="item.nome" :value="item.id">
                                            {{ item.nomeCompleto }}
                                        </option>
                                    </select>
                                </div>


                                <div>
                                    <label>Data de Início</label>
                                    <input type="date" v-model="planoEditado.dtInicio"
                                        @change="editarPlano('dtInicio', $event.target.value)">
                                </div>

                                <div>
                                    <label>Data de Término</label>
                                    <input type="date" v-model="planoEditado.dtTermino"
                                        @change="editarPlano('dtTermino', $event.target.value)">
                                </div>

                                <div>
                                    <label>Setor Beneficiado</label>
                                    <select v-model="planoEditado.setor_id"
                                        @change="editarPlano('setor_id', $event.target.value)">
                                        <option v-for="item in setores" :key="item.id" :value="item.id"
                                            :style="{ 'font-weight': (item.nivel_hierarquico <= 2) ? 'bold' : '0' }">
                                            <span v-if="item.nivel_hierarquico == 3" style="color: red;">
                                                &nbsp;&nbsp;
                                            </span>
                                            {{ item.nivel_hierarquico == 3 ? "-" : '' }}
                                            {{ item.nome }}
                                        </option>
                                    </select>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <!-- END MODAL -->

            </div>
        </section>
    </main>
</template>

<script>
import { sso } from "roboflex-thalamus-sso-lib";
import { api } from "roboflex-thalamus-request-handler";
import { consultarSetores } from '@/services/usuario-setor';
import serviceFunções from "@/services/serviceFunções";
import Cookies from "js-cookie"

export default {
    name: "ControlePCM",

    setup() {
        const formatarDataHora = (valor) => {
            return serviceFunções.formatarDataHora(valor);
        };
        const nomeEsobrenome = (nome) => {
            return serviceFunções.nomeEsobrenome(nome);
        };
        return {
            formatarDataHora, nomeEsobrenome
        };
    },

    data() {
        return {
            idUsuario: null,
            modalCompartilharPlano: false,

            planoSelecionado: null,
            listaPlanosFiltrada: null,

            listaPessoasFiltrada: null,
            pessoaSelecionada: null,

            modalNovoPA: false,
            planosAcao: null,
            novoPlanoAcao: {
                "nome": '',
                "dtInicio": new Date().toISOString().split('T')[0],
                "gerente_id": '',
                "setor_id": '',
                "programa_id": ''
            },
            gerente: [],
            setores: [],
            modalEditarPlano: false,
            modalExcluirPlano: false,
            modalFinalizarPlano: false,
            planoEditado: null,
            programas: [],
            dataTerminoPlano: null,

            teste: 'teste',
            dadosUser: null,
        }
    },

    mounted() {
        this.getPlanoAcao()
        this.getGerenteseSetor()
    },


    created() {
        const usuarioLogado = sso.getUsuarioLogado()
        // this.userName = usuarioLogado.nome // nome
        this.idUsuario = usuarioLogado.id // id
    },

    methods: {
        verPCMCriada(tipo, nivel) {
            localStorage.setItem('Tipo', tipo);
            localStorage.setItem('Nivel', nivel)
            if (this.$route.path === '/PCM/Criar') {
                window.location.reload();
            } else {
                this.$router.push({ name: "CriarPCM" });
            }
        },

        ordenarLista(itemReferencia) {
            for (var item of ['nome', 'status', 'dtInicio', 'dtTermino', 'gerente_name', 'setor_nome']) {
                if (item !== itemReferencia) {
                    document.getElementById(`setaBaixo${item}`).style.display = 'none'
                    document.getElementById(`setaCima${item}`).style.display = 'none'
                }
            }

            if (itemReferencia == 'dtInicio' || itemReferencia == 'dtTermino') {
                if (document.getElementById(`setaBaixo${itemReferencia}`).style.display === 'none') {
                    this.planosAcao = this.planosAcao.sort((a, b) => {
                        const dataInicioA = new Date(a[itemReferencia]);
                        const dataInicioB = new Date(b[itemReferencia]);
                        return dataInicioB - dataInicioA;
                    });
                    document.getElementById(`setaBaixo${itemReferencia}`).style.display = 'inline'
                    document.getElementById(`setaCima${itemReferencia}`).style.display = 'none'
                    return
                } else if (document.getElementById(`setaBaixo${itemReferencia}`).style.display === 'inline') {
                    this.planosAcao = this.planosAcao.sort((a, b) => {
                        const dataInicioA = new Date(a[itemReferencia]);
                        const dataInicioB = new Date(b[itemReferencia]);
                        return dataInicioA - dataInicioB;
                    });
                    document.getElementById(`setaBaixo${itemReferencia}`).style.display = 'none'
                    document.getElementById(`setaCima${itemReferencia}`).style.display = 'inline'
                    return
                }

            } else if (itemReferencia == 'status') {
                if (document.getElementById(`setaBaixo${itemReferencia}`).style.display === 'none') {

                    this.planosAcao = this.planosAcao.sort((a, b) => {
                        const order = {
                            'Em andamento': 1,
                            'Aprovado': 2,
                            'Proposto': 3,
                            'Concluído': 4,
                            'Suspenso': 5
                        };
                        const orderA = order[a.status] || 0;
                        const orderB = order[b.status] || 0;
                        if (orderA < orderB) {
                            return -1;
                        }
                        if (orderA > orderB) {
                            return 1;
                        }
                        return 0;
                    });


                    document.getElementById(`setaBaixo${itemReferencia}`).style.display = 'inline'
                    document.getElementById(`setaCima${itemReferencia}`).style.display = 'none'
                    return
                } else if (document.getElementById(`setaBaixo${itemReferencia}`).style.display === 'inline') {


                    this.planosAcao = this.planosAcao.sort((a, b) => {
                        const order = {
                            'Em andamento': 1,
                            'Aprovado': 2,
                            'Proposto': 3,
                            'Concluído': 4
                        };
                        const orderA = order[a.status] || 0;
                        const orderB = order[b.status] || 0;
                        if (orderA < orderB) {
                            return -1;
                        }
                        if (orderA > orderB) {
                            return 1;
                        }
                        return 0;
                    });


                    document.getElementById(`setaBaixo${itemReferencia}`).style.display = 'none'
                    document.getElementById(`setaCima${itemReferencia}`).style.display = 'inline'
                    return
                }
            }
            else {
                if (document.getElementById(`setaBaixo${itemReferencia}`).style.display === 'none') {
                    this.planosAcao = this.planosAcao.sort((a, b) => {
                        const valueA = a[itemReferencia] ? a[itemReferencia].toLowerCase() : '';
                        const valueB = b[itemReferencia] ? b[itemReferencia].toLowerCase() : '';

                        if (valueA < valueB) {
                            return -1;
                        }
                        if (valueA > valueB) {
                            return 1;
                        }
                        return 0;
                    });
                    document.getElementById(`setaBaixo${itemReferencia}`).style.display = 'inline'
                    document.getElementById(`setaCima${itemReferencia}`).style.display = 'none'
                    return
                } else if (document.getElementById(`setaBaixo${itemReferencia}`).style.display === 'inline') {
                    this.planosAcao = this.planosAcao.sort((a, b) => {
                        const valueA = a[itemReferencia] ? a[itemReferencia].toLowerCase() : '';
                        const valueB = b[itemReferencia] ? b[itemReferencia].toLowerCase() : '';

                        if (valueA > valueB) {
                            return -1;
                        }
                        if (valueA < valueB) {
                            return 1;
                        }
                        return 0;
                    });
                    document.getElementById(`setaBaixo${itemReferencia}`).style.display = 'none'
                    document.getElementById(`setaCima${itemReferencia}`).style.display = 'inline'
                    return
                }
            }
        },

        atualizarPermissão(item, ação) {
            if ((this.planoEditado.permissao.map((item) => item.usuario_id)).includes(item.id)) {
                return
            } else {

                if (ação == 'adicionar') {

                    var novaPermissão = {
                        usuario_id: item.id,
                        nivel: 1,
                        nome: item.nomeCompleto
                    }
                    this.planoEditado.permissao.push(novaPermissão);

                    api.post(`permissao/planoAcao/${this.planoEditado.id}`, {
                        usuarios: this.planoEditado.permissao
                    })
                        .then(() => {
                            this.listaPessoasFiltrada = null;
                            this.pessoaSelecionada = null
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }

                if (ação == 'remover') {
                    this.planoEditado.permissao = this.planoEditado.permissao.filter(pessoa => pessoa.usuario_id !== parseInt(item.usuario_id));
                    api.post(`permissao/planoAcao/${this.planoEditado.id}`, {
                        usuarios: this.planoEditado.permissao
                    })
                        .then(() => {
                            this.listaPessoasFiltrada = null;
                            this.pessoaSelecionada = null
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }
                if (ação == 'atualizar') {

                    api.post(`permissao/planoAcao/${this.planoEditado.id}`, {
                        usuarios: this.planoEditado.permissao
                        // .filter(item => item.usuario_id !== parseInt(this.idUsuario))
                    })
                        .then(() => {
                            this.listaPessoasFiltrada = null;
                            this.pessoaSelecionada = null
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }
            }

        },

        reordenarArray(array) {
            var id = this.planoEditado.gerente_id
            const primeiroElemento = array.find(item => parseInt(item.usuario_id) === id);
            const arraySemPrimeiroElemento = [array.filter(item => item.usuario_id !== parseInt(id))];
            return [].concat(primeiroElemento, ...arraySemPrimeiroElemento)
        },

        procurar(texto) {
            if (!texto) {
                this.listaPessoasFiltrada = this.gerente
                // .filter(item => !(this.planoEditado.permissao.map((item) => item.usuario_id)).includes(item.id));
            } else {
                if (this.listaPessoasFiltrada !== null) {
                    // this.listaPessoasFiltrada = this.gerente.filter(item => !(this.planoEditado.permissao.map((item) => item.usuario_id)).includes(item.id));
                    this.listaPessoasFiltrada = this.gerente
                    this.listaPessoasFiltrada = this.listaPessoasFiltrada.filter(nome => nome.nomeCompleto.toLowerCase().includes(texto.toLowerCase()));
                }
            }
        },

        fecharLista() {
            setTimeout(() => {
                this.listaPessoasFiltrada = null;
                this.pessoaSelecionada = null;
            }, 200);
        },

        finalizarPlano() {
            if (this.dataTerminoPlano == null) {
                document.getElementById('dataTermino').style.border = '1px solid red';
                this.disabled = true
                setTimeout(() => {
                    this.disabled = false
                }, 1500)
                return
            }
            api.put(`planoAcao/atualizar/${this.planoEditado.id}`, {
                dtTermino: this.dataTerminoPlano,
                status: "Concluído"
            })
            this.modalFinalizarPlano = false;
            setTimeout(() => {
                this.getPlanoAcao();
            }, 500)

        },

        filtrarPlanosdeAção() {
            if (!this.planoSelecionado) {
                this.listaPlanosFiltrada = this.planosAcao;
            } else {
                const textoLowerCase = this.planoSelecionado.toLowerCase();
                this.listaPlanosFiltrada = this.planosAcao.filter(plano => {
                    return Object.values(plano).some(value => {
                        if (typeof value === 'string') {
                            return value.toLowerCase().includes(textoLowerCase);
                        }
                        return false;
                    });
                });
            }
        },

        excluirPlano() {
            const userId = this.idUsuario

            api.put(`planoAcao/excluir/${this.planoEditado.id}`, {
                usuario_id: userId
            })
                .then(() => {
                    this.getPlanoAcao();
                    this.modalExcluirPlano = false;
                })
                .catch((error) => {
                    console.error('Erro ao excluir plano:', error);
                });
        },

        fecharModalExcluirPlano() {
            this.modalExcluirPlano = false;
        },

        editarPlanoInline(idProjeto, itemAlterado, novoValor) {
            if (novoValor !== "Concluído") {


                api.put(`planoAcao/atualizar/${idProjeto}`, {
                    [itemAlterado]: novoValor,
                    dtTermino: null
                })
                    .then(() => {
                        this.getPlanoAcao()
                    })
            }
            if (novoValor == "Concluído") {
                var dataAtual = new Date().toISOString().split('T')[0];
                api.put(`planoAcao/atualizar/${idProjeto}`, {

                    [itemAlterado]: novoValor,
                    dtTermino: dataAtual
                })
                    .then(() => {
                        this.getPlanoAcao()
                    })
            }
        },

        editarPlano(itemAlterado, novoValor) {

            api.put(`planoAcao/atualizar/${this.planoEditado.id}`, {
                [itemAlterado]: novoValor,
            })
        },

        mostrarBotao(id, mostrar) {
            if (mostrar) {
                this.planoEditado = this.planosAcao.find(item => item.id === id);
                document.getElementById('botaoEdicao' + id).style.visibility = '';
            } else {
                document.getElementById('botaoEdicao' + id).style.visibility = 'hidden';
            }
        },

        adicionarPlanoAcao() {
            api.post(`planoAcao/cadastrar`, {

                nome: this.novoPlanoAcao.nome,
                dtInicio: this.novoPlanoAcao.dtInicio,
                gerente_id: this.novoPlanoAcao.gerente_id,
                setor_id: this.novoPlanoAcao.setor_id,
                programa_id: this.novoPlanoAcao.programa_id,
                usuario_id: this.idUsuario,
                status: "Proposto"
            })

                .then((response) => {
                    this.getPlanoAcao();
                    this.modalNovoPA = false;
                    this.novoPlanoAcao = {
                        "nome": "",
                        "dtInicio": new Date().getFullYear() + '-' + '0' + (new Date().getMonth() + 1) + '-' + new Date().getDate(),
                        "gerente_id": "",
                        "setor_id": "",
                        "programa_id": "",
                    };
                    console.log(response.data);
                })
                .catch((error) => {
                    console.error(error);
                });

        },

        async getGerenteseSetor() {
            try {
                const { usuarios, setores } = await consultarSetores();
                this.gerente = usuarios;
                this.setores = setores;
            } catch (error) {
                console.error(error);
            }
        },

        getPlanoAcao() {
            api.get(`usuario/${this.idUsuario}/funcionalidades`)
                .then((response) => {
                    var gruposUsuario = response
                    gruposUsuario = gruposUsuario.data.grupo
                    this.dadosUser = gruposUsuario

                    api.get(`planoAcao/listar`)
                        .then((response) => {
                            this.planosAcao = response.data;

                            if (!gruposUsuario.includes("Diretoria") && !gruposUsuario.includes('Auditor Projetos')) {
                                this.planosAcao = this.planosAcao.filter(item => item.permissao.some(permissao => permissao.usuario_id === parseInt(this.idUsuario)))
                            }

                            this.planosAcao = this.planosAcao.filter(item => {
                                // Mantém itens onde pcm_codigo é nulo, indefinido ou não começa com "PAP"
                                return !item.pcm_codigo || !item.pcm_codigo.startsWith('PAP');
                            });

                            this.filtrarPlanosdeAção()
                            document.getElementById(`setaBaixodtInicio`).style.display = 'none'
                            document.getElementById(`setaCimadtInicio`).style.display = 'none'
                            this.ordenarLista('status')
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                })
                .catch((error) => {
                    console.error('erro ao consultar funcionalidades' + error)
                })
        },

        fecharModalFora(event) {
            if (event.target.classList.contains('modal-mask')) {
                this.modalNovoPA = false;
                this.modalEditarPlano = false;
                this.modalExcluirPlano = false;
                this.modalFinalizarPlano = false;
                this.modalCompartilharPlano = false;
                return this.getPlanoAcao()
            }
        },

        verBacklogs(project) {

            if (this.dadosUser.includes('Auditor Projetos') || this.dadosUser.includes('Diretoria')) {
                Cookies.set('projectDetails', JSON.stringify({
                    idProjeto: project.id,
                    nome: project.nome,
                    pcm_codigo: project.pcm_codigo,
                    pcm_id: project.pcm_id,
                    perfil: 1
                }), { expires: 1 })
                this.$router.push({ name: 'PA' })
            } else {
                if ((this.planosAcao.find(projeto => projeto.id == project.id).permissao).find(pessoa => pessoa.usuario_id == this.idUsuario).nivel == 2) {
                    Cookies.set('projectDetails', JSON.stringify({
                        idProjeto: project.id,
                        nome: project.nome,
                        pcm_codigo: project.pcm_codigo,
                        pcm_id: project.pcm_id,
                        perfil: 1
                    }), { expires: 1 })
                    this.$router.push({ name: 'PA' })
                }
                if ((this.planosAcao.find(projeto => projeto.id == project.id).permissao).find(pessoa => pessoa.usuario_id == this.idUsuario).nivel == 1) {
                    Cookies.set('projectDetails', JSON.stringify({
                        idProjeto: project.id,
                        nome: project.nome,
                        pcm_codigo: project.pcm_codigo,
                        pcm_id: project.pcm_id,
                        perfil: 2
                    }), { expires: 1 })
                    this.$router.push({ name: 'PA' })
                }
            }

            // if (this.planosAcao.length > 0) {
            //     sessionStorage.setItem('pcm_codigo', pcm_codigo)
            //     sessionStorage.setItem('pcm_id', pcm_id)
            //     if (this.dadosUser.includes('Auditor Projetos') || this.dadosUser.includes('Diretoria')) {
            //         this.$router.push({ name: "PA" })
            //         sessionStorage.setItem('idProjeto', id)
            //         sessionStorage.setItem('nomeDoProjeto', nomeProjeto)
            //     } else {
            //         if ((this.planosAcao.find(projeto => projeto.id == id).permissao).find(pessoa => pessoa.usuario_id == this.idUsuario).nivel == 2) {
            //             this.$router.push({ name: "PA" })
            //             sessionStorage.setItem('idProjeto', id)
            //             sessionStorage.setItem('nomeDoProjeto', nomeProjeto)
            //         }
            //         if ((this.planosAcao.find(projeto => projeto.id == id).permissao).find(pessoa => pessoa.usuario_id == this.idUsuario).nivel == 1) {
            //             this.$router.push({ name: "PAVo" })
            //             sessionStorage.setItem('idProjeto', id)
            //             sessionStorage.setItem('nomeDoProjeto', nomeProjeto)
            //         }
            //     }

            // }

        },

        verPCM() {
            this.$router.push({ name: "PCM" })
        },
    }

}
</script>

<style scoped></style>