<template>
    <main id="main">
        <section v-if="PCM">
            <div class="titulo">
                <div class="margem container">
                    <div class="m-icone esquerda"><a @click="this.$router.back();" style="cursor: pointer;"
                            class="icone-voltar m-d"></a></div>
                    <h2>{{ PCM.codigo + ' - ' + PCM.nome }}</h2>
                </div>
            </div>
            <div class="margem container">
                <div>
                    <div style="text-align: center;">
                        <h3>Área do Solicitante</h3>
                    </div>
                    <fieldset class="bloco2 margem">
                        <div class="grid-4">
                            <div class="col-2">
                                <label>Nome do {{ PCM.tipo }}</label>
                                <input :disabled="false" @focusout="atualizarPCM('nome', PCM.nome)" :class="{ shake: disabled }"
                                    id="nome" v-model="PCM.nome" type="text" />
                            </div>
                            <div>
                                <label>Nível</label>
                                <select :disabled="false" v-model="PCM.nivel" @change="atualizarPCM('nivel', PCM.nivel)">
                                    <option :hidden="PCM.tipo == 'Plano de Ação'">Estratégico</option>
                                    <option>Operacional</option>
                                </select>
                            </div>
                            <div>
                                <label>Tipo</label>
                                <select :disabled="false" v-model="PCM.tipo" @change="atualizarPCM('tipo', PCM.tipo)">
                                    <option>Programa</option>
                                    <option>Projeto</option>
                                    <option :hidden="nivel == 'Estratégico'">PAP</option>
                                </select>
                            </div>
                            <div>
                                <label>Finalidade</label>
                                <select :disabled="false" v-model="PCM.finalidade"
                                    @change="atualizarPCM('finalidade', PCM.finalidade)">
                                    <option>Criação</option>
                                    <option>Mudança</option>
                                </select>
                            </div>
                            <div>
                                <label>Área</label>
                                <select :disabled="false" v-model="PCM.area" @change="atualizarPCM('area', PCM.area)">
                                    <option>Processo</option>
                                    <option>Produto</option>
                                </select>
                            </div>
                            <div>
                                <label>Setor aprovador</label>
                                <select :disabled="false" :class="{ shake: disabled }" id="setor_id" v-model="PCM.setor_id"
                                    @change="atualizarPCM('setor_id', PCM.setor_id)" style="text-align: left;">
                                    <template v-if="nivel !== 'Estratégico'">
                                        <option v-for="item in setores" :key="item.id" :value="item.id"
                                            :style="{ 'font-weight': (item.nivel_hierarquico <= 2) ? 'bold' : '0' }">
                                            <span v-if="item.nivel_hierarquico == 3" style="color: red;">
                                                &nbsp;&nbsp;
                                            </span>
                                            {{ item.nivel_hierarquico == 3 ? "-" : '' }}
                                            {{ item.nome }}
                                        </option>
                                    </template>
                                    <template v-else>
                                        <option :value="7">
                                            Diretoria
                                        </option>
                                    </template>
                                </select>
                            </div>
                            <div>
                                <label>Data de abertura</label>
                                <input :disabled="false" v-model="PCM.dtInicio" type="date"
                                    @focusout="atualizarPCM('dtInicio', PCM.dtInicio)" />
                            </div>
                        </div>
                        <br>
                        <div>
                            <label>Descrição do Problema</label>
                            <textarea :disabled="false" :title="PCM.descricao_problema" v-model="PCM.descricao_problema"
                                @focusout="atualizarPCM('descricao_problema', descricao_problema)"></textarea>
                        </div>
                        <br>
                        <div>
                            <label>Possível solução</label>
                            <textarea :disabled="false" :title="PCM.possivel_solucao" v-model="PCM.possivel_solucao"
                                @focusout="atualizarPCM('possivel_solucao', PCM.possivel_solucao)"></textarea>
                        </div>

                        <div class="input-group" style="margin-top: 1rem;">
                            <label>Propósito da mudança</label>
                            <textarea :disabled="false" :title="PCM.proposito_mudanca" v-model="PCM.proposito_mudanca"
                                @focusout="atualizarPCM('proposito_mudanca', PCM.proposito_mudanca)"></textarea>
                        </div>


                        <div class="grid-2 margem">
                            <div>
                                <label>Responsável pela solicitação</label>
                                <select disabled v-model="PCM.solicitante_id" :class="{ shake: disabled }"
                                    id="solicitante_id" @change="atualizarPCM('solicitante_id', PCM.solicitante_id)">
                                    <option v-for="item in usuarios" :key="item.id" :value="item.id">
                                        {{ (item.nomeCompleto) }}
                                    </option>
                                </select>
                            </div>
                            <div>
                                <label>
                                    Setor demandante
                                </label>
                                <select :disabled="false" style="text-align: left" v-model="PCM.setorDemanda_id"
                                    @change="atualizarPCM('setorDemanda_id', PCM.setorDemanda_id)">
                                    <option v-for="item in setores" :key="item.id" :value="item.id"
                                        :style="{ 'font-weight': (item.nivel_hierarquico <= 2) ? 'bold' : '0' }">
                                        <span v-if="item.nivel_hierarquico == 3" style="color: red;">
                                            &nbsp;&nbsp;
                                        </span>
                                        {{ item.nivel_hierarquico == 3 ? "-" : '' }}
                                        {{ item.nome }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <br>
                <!-- v-if="perfil == 1" -->
                <div>
                    <div style="text-align: center">
                        <h3>Área do Responsável</h3>
                    </div>
                    <fieldset class="bloco2 margem">
                        <table class="tabela" style="border-bottom: 1px solid var(--cor-separador);">
                            <tbody>
                                <tr>
                                    <th scope="col">Análise de impactos e viabilidade da proposta</th>
                                    <th scope="col">Concordo</th>
                                    <th scope="col">Justificativa</th>
                                </tr>
                                <tr v-for="(item, index) in PCM.impacto_viabilidade" :key="index">
                                    <td>
                                        <div>
                                            {{ item.descricao }}
                                        </div>
                                    </td>
                                    <td style="text-align: center; padding: 0;">
                                        <div class="tags" style="justify-content: center;">
                                            <a @click="(dadosUsuario && dadosUsuario.funcionalidade_id.includes(56) ? item.concordo = true : null), atualizarPCM('impacto_viabilidade', objetoSemIndiceDescricao(item))"
                                                :class="item.concordo == true ? 'ativo' : ''">Sim</a>
                                            <a @click="(dadosUsuario && dadosUsuario.funcionalidade_id.includes(56) ? item.concordo = false : null), atualizarPCM('impacto_viabilidade', objetoSemIndiceDescricao(item))"
                                                :class="item.concordo == false ? 'ativo' : ''">Não</a>
                                        </div>
                                    </td>
                                    <td style="padding: 1rem; width: 30%;">
                                        <textarea v-model="item.justificativa" rows="3" :class="{ shake: disabled }"
                                            :id="'justificativa' + index"
                                            :disabled="dadosUsuario && !dadosUsuario.funcionalidade_id.includes(56) || perfil !== 1"
                                            :title="item.justificativa"
                                            @change="atualizarPCM('impacto_viabilidade', objetoSemIndiceDescricao(item))"></textarea>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div>Estimativa de Custo da Mudança</div>
                                    </td>
                                    <td>
                                        <money3 :class="{ shake: disabled }" v-model="PCM.estimativa_custo"
                                            :disabled="dadosUsuario && !dadosUsuario.funcionalidade_id.includes(56) || perfil !== 1"
                                            @focusout="atualizarPCM('estimativa_custo', PCM.estimativa_custo)"
                                            v-bind="config"
                                            style="width: 8rem; border: none; outline: none; font-size: 20px; background-color: transparent;">
                                        </money3>
                                    </td>
                                    <td style="padding: 1rem">
                                        <textarea :class="{ shake: disabled }" v-model="PCM.custo_justificativa"
                                            rows="3" :title="PCM.custo_justificativa" id="custo_justificativa"
                                            :disabled="dadosUsuario && !dadosUsuario.funcionalidade_id.includes(56) || perfil !== 1"
                                            @change="atualizarPCM('custo_justificativa', PCM.custo_justificativa)"></textarea>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div
                            style="border: 1px solid var(--cor-separador); padding: 5px; width: 100%; border-radius: 10px; margin-top: 0.5rem;">
                            <span>
                                Confirmo que o cenário geral do mercado é viável à proposta, pois
                                apresenta
                                condições favoráveis que podem sustentar o sucesso da iniciativa.
                                Além
                                disso, a
                                empresa possui capacidade interna necessária para implementá-la,
                                contando com
                                recursos humanos qualificados e infraestrutura adequada. Com isso,
                                demonstra-se
                                que
                                a implementação da proposta será rentável à empresa, gerando
                                retornos
                                positivos.
                            </span>
                        </div>
                        <div class="grid-3 margem">
                            <div>
                                <label>Data prevista de implementação</label>
                                <input :class="{ shake: disabled }" v-model="PCM.dtEstimadaFinal" type="date"
                                    id="dtEstimadaFinal"
                                    :disabled="dadosUsuario && !dadosUsuario.funcionalidade_id.includes(56) || perfil !== 1"
                                    @change="atualizarPCM('dtEstimadaFinal', PCM.dtEstimadaFinal)" />
                            </div>
                            <div>
                                <label>Data limite de implementação</label>
                                <input :class="{ shake: disabled }" v-model="PCM.dtLimiteImplementacao" type="date"
                                    id="dtLimiteImplementacao"
                                    :disabled="dadosUsuario && !dadosUsuario.funcionalidade_id.includes(56)|| perfil !== 1"
                                    @change="atualizarPCM('dtLimiteImplementacao', PCM.dtLimiteImplementacao)" />
                            </div>
                            <div>
                                <label>Gerente responsável</label>
                                <select :class="{ shake: disabled }"
                                    :disabled="dadosUsuario && !dadosUsuario.funcionalidade_id.includes(56) || perfil !== 1"
                                    v-model="PCM.gerentePCM_id" id="gerentePCM_id"
                                    @change="atualizarPCM('gerentePCM_id', PCM.gerentePCM_id)">
                                    <option v-for="item in usuarios" :key="item.id" :value="item.id">
                                        {{ (item.nomeCompleto) }}
                                    </option>
                                </select>
                            </div>
                            <div>
                                <label>Parecer</label>
                                <select :class="{ shake: disabled }" v-model="PCM.parecer_responsavel"
                                    id="parecer_responsavel"
                                    :disabled="dadosUsuario && !dadosUsuario.funcionalidade_id.includes(56) || perfil !== 1"
                                    @change="atualizarPCM('parecer_responsavel', PCM.parecer_responsavel)"
                                    :style="{ color: PCM.parecer_responsavel == 'Proposta viável' ? 'var(--cor-sucesso)' : 'var(--cor-erro)' }">
                                    <option style="color: var(--cor-sucesso)">Proposta viável</option>
                                    <option style="color: var(--cor-erro)">Proposta inviável</option>
                                </select>
                            </div>
                            <div>
                                <label> Nome do Aprovador </label>
                                <select :class="{ shake: disabled }"
                                    :disabled="dadosUsuario && !dadosUsuario.funcionalidade_id.includes(56) || PCM.nivel == 'Estratégico' || perfil !== 1"
                                    v-model="PCM.responsavel_id" id="responsavel_id"
                                    @change="atualizarPCM('responsavel_id', PCM.responsavel_id)">
                                    <option v-for="item in usuarios" :key="item.id" :value="item.id">
                                        {{ (item.nomeCompleto) }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div>
                            <label>Justificativa / Observação</label>
                            <textarea :class="{ shake: disabled }" v-model="PCM.responsavel_justificativa"
                                id="responsavel_justificativa"
                                :disabled="dadosUsuario && !dadosUsuario.funcionalidade_id.includes(56) || perfil !== 1"
                                :title="PCM.responsavel_justificativa"
                                @focusout=" atualizarPCM('responsavel_justificativa', PCM.responsavel_justificativa)"></textarea>
                        </div>
                        <br>
                        <div>
                            <label>Resultado esperado</label>
                            <textarea :class="{ shake: disabled }" v-model="PCM.resultado_esperado"
                                id="resultado_esperado"
                                :disabled="dadosUsuario && !dadosUsuario.funcionalidade_id.includes(56) || perfil !== 1"
                                :title="PCM.resultado_esperado"
                                @focusout=" atualizarPCM('resultado_esperado', PCM.resultado_esperado)"></textarea>
                        </div>

                        <div class="grid-3 margem" style="padding-bottom: 0;" v-if="PCM.tipo !== 'Projeto'">
                            <div>
                                <label>Necessidade de Cadastro no Omie?</label>
                                <div class="tags m-b">
                                    <a @click="(dadosUsuario && dadosUsuario.funcionalidade_id.includes(56) ? PCM.cadastro_omie = 1 : null), atualizarPCM('cadastro_omie', 1)"
                                        :class="PCM.cadastro_omie == 1 ? 'ativo' : ''">Sim</a>
                                    <a @click="(dadosUsuario && dadosUsuario.funcionalidade_id.includes(56) ? PCM.cadastro_omie = 0 : null), atualizarPCM('cadastro_omie', 0)"
                                        :class="PCM.cadastro_omie == 0 ? 'ativo' : ''">Não</a>
                                </div>
                            </div>
                            <div>
                                <label>Responsável pelo cadastro no sistema</label>
                                <select :disabled="dadosUsuario && !dadosUsuario.funcionalidade_id.includes(56) || perfil !== 1"
                                    v-model="PCM.responsavelCadastro_id"
                                    @change="atualizarPCM('responsavelCadastro_id', PCM.responsavelCadastro_id)">
                                    <option v-for="item in usuarios" :key="item.id" :value="item.id">
                                        {{ (item.nomeCompleto) }}
                                    </option>
                                </select>
                            </div>
                            <div>
                                <label> Data </label>
                                <input type="date"
                                    :disabled="dadosUsuario && !dadosUsuario.funcionalidade_id.includes(56) || perfil !== 1"
                                    v-model="PCM.dtCadastro" @change="atualizarPCM('dtCadastro', PCM.dtCadastro)" />
                            </div>
                        </div>
                        <div class="alinha-v alinha-centro margem" v-if="perfil == 1">
                            <button class="button aprovar acao-secundaria bg-sucesso"
                                :disabled="PCM.status == 'Aprovado'"
                                @click="validarCampos() ? (PCM.status == 'Aprovação Técnica' ? null : criarElemento(), atualizarStatus(PCM.status == 'Aprovação Técnica' ? 'Aprovação Estratégica' : 'Aprovado')) : null">
                                {{ PCM.status == 'Aprovado' ? 'Aprovado' : PCM.status == 'Aprovação Técnica' ?
                                    'Aprovação Técnica' :
                                    'Aprovar' }}
                                <!-- <i class="fa-solid fa-thumbs-up"></i> -->
                            </button>
                            <button class="button reprovar acao-secundaria bg-erro" @click="avisoExclusao()"
                                :disabled="PCM.status == 'Reprovado'">
                                {{ PCM.status == 'Reprovado' ? 'Reprovado' : 'Reprovar' }}
                                <!-- <i class="fa-solid fa-thumbs-down"></i> -->
                            </button>
                        </div>
                        <div class="alinha-v alinha-centro">
                            <b :class="{ shake: disabled }"
                                style="margin-top: 0.5rem; margin-right: 1rem; display: none; color: var(--cor-erro);"
                                id="aviso">Preencha
                                os
                                campos destacados!</b>
                        </div>
                    </fieldset>
                </div>
            </div>

            <!-- modal -->
            <div style="overflow: auto" class="modal-mask" v-if="modalConfirmacao" @click="fecharModalFora">
                <div style="height: max-content; width: 50rem; padding: 1rem 2rem; border-radius: 5px;" class="jm">
                    <div style="color: var(--cor-fonte);">
                        <div style="display: flex; justify-content: space-between">
                            <h1>Deseja reprovar {{ this.PCM.codigo }}?</h1>
                        </div>
                        <h3>O {{ this.PCM.tipo }} associado ao PCM que você está
                            reprovando será excluido.<br>
                            Atualmente ele possui
                            <strong>
                                {{ quantidadeTarefas }}
                            </strong>
                            {{ quantidadeTarefas > 1 ? 'tarefas registradas.' : 'tarefa registrada.' }}
                        </h3>

                        <div class="modal-footer">
                            <button
                                @click="excluirElemento(), atualizarStatus('Reprovado'), modalConfirmacao = false">Confirmar</button>
                            <button class="acao-secundaria" @click="modalConfirmacao = false"
                                style="margin-left: 0.5rem;">Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end-modal -->

        </section>
    </main>
</template>

<script>
import { sso } from "roboflex-thalamus-sso-lib";
import { getDados } from '@/services/serviceFuncionalidades';
import { Money3Component } from "v-money3";
import { api } from "roboflex-thalamus-request-handler";
import { consultarSetores } from '@/services/usuario-setor';
import Cookies from 'js-cookie'

export default {
    name: "VisualizaçãoCriaçãoPCM",
    components: { money3: Money3Component },
    props: {
        idPCM: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            teste: 'teste',
            dadosUsuario: null,
            modalConfirmacao: false,
            idUsuario: null,
            quantidadeTarefas: null,
            disabled: false,
            perfil: null,

            setores: '',
            usuarios: null,

            PCM: null,

            config: {
                masked: false,
                prefix: "R$ ",
                thousands: ".",
                decimal: ",",
                precision: 2,
            },
        };
    },

    created() {
        const details = JSON.parse(Cookies.get('pcmDetails'));
        this.perfil = details.perfil

        const usuarioLogado = sso.getUsuarioLogado()
        this.idUsuario = usuarioLogado.id,

            this.getSetores(),
            this.carregarPermissoes(),
            this.getPCM()
    },

    methods: {
        validarCampos() {
            var camposVazios = []
            if (!this.PCM.custo_justificativa) camposVazios.push('custo_justificativa');
            if (!this.PCM.dtEstimadaFinal) camposVazios.push('dtEstimadaFinal');
            if (!this.PCM.dtLimiteImplementacao) camposVazios.push('dtLimiteImplementacao');
            if (!this.PCM.gerentePCM_id) camposVazios.push('gerentePCM_id');
            if (!this.PCM.parecer_responsavel) camposVazios.push('parecer_responsavel');
            if (!this.PCM.responsavel_id) camposVazios.push('responsavel_id');
            if (!this.PCM.responsavel_justificativa) camposVazios.push('responsavel_justificativa');
            if (!this.PCM.resultado_esperado) camposVazios.push('resultado_esperado');

            camposVazios.forEach(item => {
                document.getElementById(item).style.border = 'solid 1px var(--cor-erro)';
                document.getElementById('aviso').style.display = '';
                this.disabled = true
                setTimeout(() => {
                    this.disabled = false
                }, 1500)
                return
            });

            this.teste = camposVazios.length

            if (camposVazios.length > 0) {
                return false
            } else {
                var campos = ['justificativa0', 'justificativa1', 'custo_justificativa', 'dtEstimadaFinal', 'dtLimiteImplementacao', 'gerentePCM_id', 'parecer_responsavel', 'responsavel_id', 'responsavel_justificativa', 'resultado_esperado']
                campos.forEach(item => {
                    document.getElementById(item).style.border = 'solid 1px var(--cor-separador)';
                });
                document.getElementById('aviso').style.display = 'none';
                return true
            }

        },

        avisoExclusao() {
            if (this.PCM.tipo == 'Projeto') {
                api.get('projeto/listar').then((response) => {
                    var projetoEncontrado = response.data.find(projeto => projeto.id === this.PCM.id_associacao);
                    if (projetoEncontrado) {
                        var quantidadeTarefas = projetoEncontrado.quantidade_tarefas;
                        if (quantidadeTarefas !== 0) {
                            this.modalConfirmacao = true;
                            this.quantidadeTarefas = quantidadeTarefas;
                            this.tipo = this.PCM.tipo;
                        } else {
                            this.excluirElemento();
                            this.atualizarStatus('Reprovado');
                        }
                    } else {
                        this.excluirElemento();
                        this.atualizarStatus('Reprovado');
                    }
                }).catch(error => {
                    // Tratar erros de requisição
                    console.error('Erro ao buscar projetos:', error);
                });
            }
            if (this.PCM.tipo == 'Plano de Ação') {
                api.get('planoAcao/listar').then((response) => {
                    var planoEncontrado = response.data.find(plano => plano.id === this.PCM.id_associacao);
                    if (planoEncontrado) {
                        var quantidadeTarefas = planoEncontrado.quantidade_tarefas;
                        if (quantidadeTarefas !== 0) {
                            this.modalConfirmacao = true;
                            this.quantidadeTarefas = quantidadeTarefas;
                            this.tipo = this.PCM.tipo;
                        } else {
                            this.excluirElemento();
                            this.atualizarStatus('Reprovado');
                        }
                    } else {
                        // this.excluirElemento();
                        this.atualizarStatus('Reprovado');
                    }
                }).catch(error => {
                    // Tratar erros de requisição
                    console.error('Erro ao buscar planos de ação:', error);
                });
            }
            if (this.PCM.tipo == 'Programa') {
                this.excluirElemento()
                this.atualizarStatus('Reprovado')
            }
        },

        excluirElemento() {
            if (this.PCM.tipo == 'Projeto') {
                api.put(`projeto/excluir/${this.PCM.id_associacao}`, {
                    usuario_id: this.idUsuario
                })
                    .then((response) => {
                        console.log(response.data);
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
            if (this.PCM.tipo == 'Plano de Ação' || this.PCM.tipo == 'PAP') {
                api.put(`planoAcao/excluir/${this.PCM.id_associacao}`, {
                    usuario_id: this.idUsuario
                })
                    .then((response) => {
                        console.log(response.data);
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
            if (this.PCM.tipo == 'Programa') {
                api.put(`programa/excluir/${this.PCM.id_associacao}`, {
                    usuario_id: this.idUsuario
                })
                    .then((response) => {
                        console.log(response.data);
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        },

        criarElemento() {
            // var responsavelSetor = this.setores.find((setor) => setor.id == this.PCM.setor_id).responsavel_id
            var responsavelSetorDemandante = this.setores.find((setor) => setor.id == this.PCM.setorDemanda_id).responsavel_id

            if (!responsavelSetorDemandante) {
                console.error('Responsavel pelo setor demandante não encontrado')
                return
            }

            if (this.PCM.id_associacao == null) {
                if (this.PCM.tipo == 'Projeto') {
                    api.post(`projeto/cadastrar`, {
                        nome: this.PCM.nome,
                        dtInicio: this.PCM.dtInicio.split(' ')[0],
                        gerente_id: this.PCM.gerentePCM_id,
                        demandante_id: responsavelSetorDemandante,
                        setor_id: this.PCM.setor_id,
                        usuario_id: this.idUsuario,
                        solicitante_id: this.PCM.solicitante_id,
                        status: "Aprovado"
                    })
                        .then((response) => {
                            api.put(`pcm/atualizar/${this.idPCM}`, {
                                // projeto: 1,
                                projeto_id: response.data.id
                            })
                                .catch((error) => {
                                    console.error(error);
                                });
                            console.log(response.data);
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }
                if (this.PCM.tipo == 'Plano de Ação' || this.PCM.tipo == 'PAP') {
                    api.post(`planoAcao/cadastrar`, {
                        nome: this.PCM.nome,
                        dtInicio: this.PCM.dtInicio.split(' ')[0],
                        gerente_id: this.PCM.gerentePCM_id,
                        demandante_id: responsavelSetorDemandante,
                        setor_id: this.PCM.setor_id,
                        usuario_id: this.idUsuario,
                        solicitante_id: this.PCM.solicitante_id,
                        status: "Aprovado"
                    })
                        .then((response) => {
                            api.put(`pcm/atualizar/${this.idPCM}`, {
                                // projeto: 0,
                                planoAcao_id: response.data.id
                            })
                                .catch((error) => {
                                    console.error(error);
                                });
                            console.log(response.data);
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }
                if (this.PCM.tipo == 'Programa') {
                    api.post(`programa/cadastrar`, {
                        nome: this.PCM.nome,
                        dtInicio: this.PCM.dtInicio.split(' ')[0],
                        gerente_id: this.PCM.gerentePCM_id,
                        status: "Aprovado"
                    })
                        .then((response) => {
                            api.put(`pcm/atualizar/${this.idPCM}`, {
                                // projeto: 0,
                                programa_id: response.data.id
                            })
                                .catch((error) => {
                                    console.error(error);
                                });
                            console.log(response.data);
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }
            }
        },

        atualizarStatus(valor) {
            var id = this.idPCM
            if (valor == 'Aprovação Estratégica') {
                api.put(`pcm/atualizar/${id}`, {
                    status: valor,
                    aprovada: 0
                })
                    .then(() => {
                        this.getPCM()
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
            if (valor == 'Aprovado') {
                api.put(`pcm/atualizar/${id}`, {
                    status: valor,
                    aprovada: 1
                })
                    .then(() => {
                        this.getPCM()
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
            if (valor == 'Reprovado') {
                api.put(`pcm/atualizar/${id}`, {
                    status: valor,
                    aprovada: 0
                })
                    .then(() => {
                        this.getPCM()
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
            if (valor == 'Aprovação Estratégica') {
                this.$router.push('/PCM/Controle')
            }
        },

        carregarPermissoes() {
            var id = this.idUsuario
            getDados(id)
                .then(dadosUsuario => {
                    this.dadosUsuario = dadosUsuario;
                    // this.getPCMs()
                })
                .catch(error => {
                    console.error(error);
                    console.log('Falha ao colher dados do usuario')
                });
        },

        objetoSemIndiceDescricao(item) {
            if (item) {
                const { indice, descricao, ...resto } = item;
                indice
                descricao
                return [resto];
            }
        },

        atualizarPCM(itemEditado, valor) {
            if (this.dadosUsuario.funcionalidade_id.includes(56)){
                api.put(`pcm/atualizar/${this.idPCM}`, {
                    [itemEditado]: valor,
                })
                .then(() => { })
                .catch((error) => {
                    console.error(error);
                });
            }else {
                return
            }
        },

        async getSetores() {
            try {
                const { usuarios, setores } = await consultarSetores();
                this.usuarios = usuarios;
                this.setores = setores;
            } catch (error) {
                console.error(error);
            }
        },

        getPCM() {
            api.get(`pcm/buscar/${this.idPCM}`, {})
                .then(response => {
                    this.PCM = response.data
                    if (this.PCM.nivel == 'Estratégico') {
                        this.PCM.responsavel_id = 15
                    }
                })

                .catch((error) => {
                    console.error(error);
                });
        },

        fecharModalFora(event) {
            if (event.target.classList.contains('modal-mask')) {
                this.modalConfirmacao = false;
            }
        },
    },
};
</script>

<style scoped>
a {
    cursor: pointer;
}

input:disabled,
select:disabled,
textarea:disabled {
    opacity: 1;
    cursor: not-allowed;
}

.button {
    /* width: 7rem;
    padding: 5px !important;
    border: none !important;
    border-radius: 6px !important; */
    cursor: pointer;
    color: var(--cor-fonte);
    margin-inline: 0.5rem;
}

.reprovar {
    background-color: #e02130;
}

.aprovar {
    background-color: #429867;
}

.reprovar:disabled {
    background-color: #e0213171 !important;
    cursor: not-allowed !important;
}


.aprovar:disabled {
    background-color: #4298677e !important;
    cursor: not-allowed !important;
}

.aprovar:hover,
.reprovar:hover {
    transition: 50ms linear;
    transform: scale(1.1);
}
</style>
