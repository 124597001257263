import api from './api';

export async function consultarSetores() {
    try {
        const responseUsuarios = await api.get(`usuario`);
        const usuarios = responseUsuarios.data.map(item => ({
            id: item.id,
            nomeCompleto: item.name,
        }));

        const responseSetores = await api.get(`/setor/hierarquico`);

        var setores = responseSetores.data
        setores = flattenHierarchy(setores);

        return { usuarios, setores };
    } catch (error) {
        console.error(error);
        throw error;
    }
}

function flattenHierarchy(data) {
    var setores = [];

    data.forEach(item => {
        if (item.nivel_hierarquico <= 3) {
            setores.push({ id: item.id, nome: item.nome, nivel_hierarquico: item.nivel_hierarquico, responsavel_id: item.responsavel_id });
        }

        if (item.subsetor.length > 0) {
            setores = setores.concat(flattenHierarchy(item.subsetor));
        }
    });

    return setores;
}