import { api } from "roboflex-thalamus-request-handler";

const funções = {
    async getProjetos(tipo) {
        if (tipo == 'todos') {
            try {
                const response = await api.get(`projeto/listar`);
                return response.data;
            } catch (error) {
                console.error("Erro ao buscar projetos:", error);
                throw error;
            }
        }
    },

    formatarDataHora(valor) {
        if (valor) {
            if (valor) {
                return valor.slice(0, 10)
            } else {
                return ''
            }
        }
    },

    somarHP(dados) {
        let somaPendentes = 0;
        let somaEmAndamento = 0;
        let somaConcluidos = 0;

        if (dados.backlogs) {
            dados.backlogs.forEach(backlog => {
                if (backlog.status == 'Pendente') {
                    if (backlog.HP) {
                        somaPendentes += parseInt(backlog.HP);
                    }
                }
                if (backlog.status == 'Em andamento' || backlog.status == 'Em andamento 25%' || backlog.status == 'Em andamento 50%' || backlog.status == 'Em andamento 75%') {
                    if (backlog.HP) {
                        somaEmAndamento += parseInt(backlog.HP);
                    }
                }
                if (backlog.status == 'Concluído') {
                    if (backlog.HP) {
                        somaConcluidos += parseInt(backlog.HP);
                    }
                }
            });
        }
        let lista = [somaPendentes, somaEmAndamento, somaConcluidos]
        return lista;
    },

    somarHP2(dados) {
        let somaPendentes = 0;
        let somaEmAndamento = 0;
        let somaConcluidos = 0;

        if (dados.tarefas) {
            dados.tarefas.forEach(backlog => {
                if (backlog.status == 'Pendente') {
                    if (backlog.HP) {
                        somaPendentes += parseInt(backlog.HP);
                    }
                }
                if (backlog.status == 'Em andamento' || backlog.status == 'Em andamento 25%' || backlog.status == 'Em andamento 50%' || backlog.status == 'Em andamento 75%') {
                    if (backlog.HP) {
                        somaEmAndamento += parseInt(backlog.HP);
                    }
                }
                if (backlog.status == 'Concluído') {
                    if (backlog.HP) {
                        somaConcluidos += parseInt(backlog.HP);
                    }
                }
            });
        }
        let lista = [somaPendentes, somaEmAndamento, somaConcluidos]
        return lista;
    },

    nomeEsobrenome(nome) {
        const nomeESobrenome = nome.split(" ");
        if (nomeESobrenome.length >= 2) {
            const primeiroNome = nomeESobrenome[0];
            const segundoNome = nomeESobrenome[1];
            if (segundoNome.length <= 3 || segundoNome == 'Paula') {
                return `${primeiroNome} ${segundoNome} ${nomeESobrenome[2] || ''}`;
            } else {
                return `${primeiroNome} ${segundoNome}`;
            }
        } else {
            return nome;
        }
    },

    abreviarMes(dataString, mandarAno) {
        if (dataString == null) {
            return null
        }
        const partes = dataString.split('-');

        if (partes.length !== 3) {
            console.error('Formato de data inválido. Use o formato AAAA-MM-DD');
            return null;
        }

        const ano = partes[0].slice(2);
        const mesNumero = parseInt(partes[1], 10);
        const dia = partes[2];

        const mesesAbreviados = [
            'Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun',
            'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'
        ];

        if (mesNumero < 1 || mesNumero > 12) {
            return 'Número do mês inválido.'
        }

        const mesAbreviado = mesesAbreviados[mesNumero - 1];

        if (mandarAno) {
            return `${dia}/${mesAbreviado}./${ano}`;
        } else {
            return `${dia}/${mesAbreviado}.`;
        }
    },
}
export default funções;