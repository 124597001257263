<template>
  <main id="main">
    <section>
      <div class="titulo">
        <div class="margem container">
          <div class="m-icone direita"><button @click="criarProtocolo(4)" class="acao-secundaria">Adicionar OS</button>
          </div>
          <div class="m-icone direita">
            <div class="pesquisa">
              <input type="text" placeholder="Pesquisar Ordem de Serviço" v-model="protocoloSelecionado"
                @input="filtrarProtocolos()" />
              <a disabled class="icone-pesquisa"></a>
            </div>
          </div>
          <h2>Ordem de Serviço</h2>
        </div>
      </div>
      <div class="margem container">
        <div class="bloco margem">
          <table class="tabela">
            <tbody>
              <tr>
                <th scope="col" @click="ordernarColuna('codigo')">
                  Código <span v-if="activeFilter == 'codigo'"><i
                      :class="isAscending ? 'bi bi-caret-down-fill' : 'bi bi-caret-up-fill'"></i></span>
                </th>
                <th scope="col" @click="ordernarColuna('nome')">
                  Nome
                  <span v-if="activeFilter == 'nome'"><i
                      :class="isAscending ? 'bi bi-caret-down-fill' : 'bi bi-caret-up-fill'"></i></span>
                </th>
                <th scope="col" @click="ordernarColuna('status')">
                  Status <span v-if="activeFilter == 'status'"><i
                      :class="isAscending ? 'bi bi-caret-down-fill' : 'bi bi-caret-up-fill'"></i></span>
                </th>
                <th scope="col" @click="ordernarColuna('dtRegistro')">
                  Data de Registro <span v-if="activeFilter == 'dtRegistro'"><i
                      :class="isAscending ? 'bi bi-caret-down-fill' : 'bi bi-caret-up-fill'"></i></span>
                </th>
                <th scope="col" @click="ordernarColuna('responsavel')">
                  Responsável <span v-if="activeFilter == 'responsavel'"><i
                      :class="isAscending ? 'bi bi-caret-down-fill' : 'bi bi-caret-up-fill'"></i></span>
                </th>
                <th scope="col" @click="ordernarColuna('setor')">
                  Setor Envolvido <span v-if="activeFilter == 'setor'"><i
                      :class="isAscending ? 'bi bi-caret-down-fill' : 'bi bi-caret-up-fill'"></i></span>
                </th>
              </tr>
              <tr style="text-align: center" v-for="item in listaprotocoloFiltrada" :key="item.id"
                @click="verProtocolo(item.id)">
                <td style="vertical-align: middle">
                  {{ item.codigo }}
                </td>
                <td style="vertical-align: middle">
                  {{ item.nome }}
                </td>
                <td style="vertical-align: middle">
                  <select v-model="item.status" :style="{
                    color:
                      item.status == 'Aprovado' || item.status == 'Pendente'
                        ? '#00B6B3'
                        : item.status == 'Em andamento'
                          ? 'var(--cor-ok)'
                          : item.status == 'Concluído'
                            ? 'var(--cor-sucesso)'
                            : '#FFA500',
                  }"
                    style="opacity: 1; background-color: transparent; width: fit-content; outline: none; text-align: center; border: none"
                    @click.stop @change="editarProtocolo(item.id, 'status', item.status)">
                    <!-- <option style="color: #00B6B3;">Aprovado</option> -->
                    <option style="color: #00b6b3" hidden value="null"><strong>-</strong></option>
                    <option style="color: #00b6b3">Pendente</option>
                    <option style="color: var(--cor-ok)">Em andamento</option>
                    <option style="color: var(--cor-sucesso)">Concluído</option>
                    <option style="color: #ffa500">Suspenso</option>
                  </select>
                </td>
                <td style="vertical-align: middle">
                  {{ item.dtInicio ?
                    `${formatarDataHora(item.dtInicio).split("-")[2]}/${formatarDataHora(item.dtInicio).split("-")[1]}/${formatarDataHora(item.dtInicio).split("-")[0]}`
                  : "-" }}
                </td>
                <td style="vertical-align: middle">
                  {{ item.responsavel_nome }}
                </td>
                <td style="vertical-align: middle">
                  {{ item.setor_nome ? item.setor_nome : "-" }}
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="!protocolos" class="loading" id="loading">
            <div></div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { api } from "roboflex-thalamus-request-handler";
import serviceFunções from "@/services/serviceFunções";

export default {
  name: "ControleProtocolo",

  setup() {
    const formatarDataHora = (valor) => {
      return serviceFunções.formatarDataHora(valor);
    };
    return {
      formatarDataHora,
    };
  },

  data() {
    return {
      listaprotocoloFiltrada: null,
      protocoloSelecionado: null,
      protocolos: null,
      tipoProtocolo: sessionStorage.getItem("tipoProtocolo"),
      isAscending: true,
      activeFilter: "none",
    };
  },

  mounted() {
    this.getProtocolos();
  },

  methods: {
    editarProtocolo(idProtocolo, itemAlterado, novoValor) {
      api
        .post(`protocolo/atualizar/${idProtocolo}`, {
          [itemAlterado]: novoValor,
          dtTermino: null,
        })
        .then(() => {
          this.getProtocolos();
        });
    },

    getProtocolos() {
      api
        .get("protocolo/listar")
        .then((response) => {
          this.protocolos = response.data;
          this.protocolos = this.protocolos.filter((item) => item.tipo_id == 4);

          this.protocolos = this.protocolos.sort((a, b) => {
            if (a.codigo < b.codigo) return 1;
            if (a.codigo > b.codigo) return -1;
            return 0;
          });

          this.filtrarProtocolos();
          console.log(this.listaprotocoloFiltrada[0]);
          this.listaprotocoloFiltrada.push({ id: 15, nome: "aeste2", dtInicio: "2024-06-28", responsavel_id: 13, responsavel_nome: "Resp Teste", codigo: "OS240002" });
        })
        .catch((error) => {
          console.error(error);
        });
    },

    filtrarProtocolos() {
      if (!this.protocoloSelecionado) {
        this.listaprotocoloFiltrada = this.protocolos;
      } else {
        const textoLowerCase = this.protocoloSelecionado.toLowerCase();
        this.listaprotocoloFiltrada = this.protocolos.filter((projeto) => {
          return Object.values(projeto).some((value) => {
            if (typeof value === "string") {
              return value.toLowerCase().includes(textoLowerCase);
            }
            return false;
          });
        });
      }
    },

    criarProtocolo(tipo) {
      if (this.$route.path === "/protocolo/criar") {
        sessionStorage.setItem("tipoProtocolo", tipo);
        window.location.reload();
      } else {
        sessionStorage.setItem("tipoProtocolo", tipo);
        this.$router.push({ name: "criaçãoProtocolo" });
      }
    },

    verProtocolo(id) {
      this.$router.push({ name: "visualizaçãoProtocolo" });
      sessionStorage.setItem("idProjeto", id);
    },

    ordernarColuna(coluna) {
      this.activeFilter = coluna;
      switch (coluna) {
        case "codigo":
          this.ordernarString("codigo");
          break;
        case "nome":
          this.ordernarString("nome");
          break;
        case "status":
          this.porStatus();
          break;
        case "dtRegistro":
          this.porData();
          break;
        case "responsavel":
          this.ordernarString("responsavel_nome");
          break;
        case "setor":
          this.ordernarString("setor_nome");
          break;
        default:
          break;
      }
      this.isAscending = !this.isAscending;
    },

    ordernarString(coluna) {
      this.listaprotocoloFiltrada.sort((a, b) => {
        const valorA = a[coluna] ?? "z";
        const valorB = b[coluna] ?? "z";

        if (this.isAscending) return valorA.localeCompare(valorB);
        else return valorB.localeCompare(valorA);
      });
    },

    porData() {
      this.listaprotocoloFiltrada.sort((a, b) => {
        const dataInicioA = new Date(a["dtInicio"]);
        const dataInicioB = new Date(b["dtInicio"]);
        if (this.isAscending) return dataInicioA - dataInicioB;
        else return dataInicioB - dataInicioA;
      });
    },

    porStatus() {
      const order = {
        Pendente: 1,
        "Em andamento": 2,
        Concluído: 3,
        Suspenso: 4,
      };

      this.listaprotocoloFiltrada.sort((a, b) => {
        const orderA = order[a.status] || 0;
        const orderB = order[b.status] || 0;
        if (this.isAscending) {
          if (orderA < orderB) {
            return -1;
          }
          if (orderA > orderB) {
            return 1;
          }
          return 0;
        } else {
          if (orderA > orderB) {
            return -1;
          }
          if (orderA < orderB) {
            return 1;
          }
          return 0;
        }
      });
    },
  },
};
</script>

<style scoped>
th {
  cursor: pointer;
}
</style>