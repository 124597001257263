<template>
    <aside>
        <div>
            <div class="titulo margem efeito">
                <div class="m-icone"><a href="#" class="icone-menu" title="Menu"
                        onclick="document.getElementById('main').classList.toggle('esconde');return false"></a></div>
            </div>
            <nav class="nav-maior separador">
                <div class="separador">
                    <router-link to="/" active-class="ativo">
                        <div style="display: flex;" class="alinha-v"><span style="white-space: nowrap;">Minhas
                                tarefas</span><span style="margin-left: 5px;" class="hPoints bg-alerta">{{ tarefas[0]
                                }}</span><span class="hPoints bg-ok">{{ tarefas[1] }}</span></div>
                    </router-link>
                    <router-link to="/gestao" active-class="ativo"
                        v-if="this.dadosUsuario ? this.dadosUsuario.grupo.includes('Diretoria') : false">
                        Gestão de Projetos</router-link>
                    <router-link v-if="this.dadosUsuario ? this.dadosUsuario.funcionalidade_id.includes(56) : false"
                        to="/PCM/Controle" active-class="ativo">Aprovação</router-link>
                    <router-link to="/PCM/Controle/Demandados"
                        v-if="this.dadosUsuario ? !this.dadosUsuario.grupo.includes('Diretoria') : false"
                        active-class="ativo">Demandados</router-link>
                </div>
                <div class="separador">
                    <router-link to="/Programas/controle"
                        v-if="this.dadosUsuario ? this.dadosUsuario.grupo.includes('Diretoria') : false"
                        active-class="ativo">Programas - PRO</router-link>
                    <router-link to="/projetos" active-class="ativo">Projeto - PRJ</router-link>
                    <router-link to="/PLA" active-class="ativo">Plano de Ação (novo) - PLA</router-link>
                    <router-link to="/PA/Controle" active-class="ativo">Plano de Ação (descontinuado) -
                        PLA</router-link>
                    <router-link to="/PAP" active-class="ativo">Plano de Alteração de Produto - PAP</router-link>
                </div>
                <div class="separador">
                    <router-link to="/OS" active-class="ativo">Ordem de Serviço - OS</router-link>
                    <router-link to="/TNC" active-class="ativo">Tratativa de Não Conformidade - TNC</router-link>
                    <router-link to="/RAP" active-class="ativo">Resposta de Avaliação de Produto - RAP</router-link>
                </div>
            </nav>
        </div>
    </aside>
</template>

<script>
import { api } from "roboflex-thalamus-request-handler";
import { sso } from "roboflex-thalamus-sso-lib"

export default {
    name: 'NovoMenuLateral',

    data() {
        return {
            sgp: '',
            devURL: api.defaults.devURL,
            tipo: null,
            PCMs: null,
            dadosUsuario: null,
            idUsuario: null,

            tipoProtocolo: sessionStorage.getItem('tipoProtocolo'),
            tarefas: ['-', '-'],
        }
    },

    created() {
        const usuarioLogado = sso.getUsuarioLogado()
        this.idUsuario = usuarioLogado.id
        api.post(`gestao/tarefasUsuario`, {
            dtFim: '',
            idUser: [{
                valor: this.idUsuario
            }],
            statusProjetoPa: [{ valor: 'Aprovado' }, { valor: 'Em andamento' }, { valor: 'Concluído' }],
            statusTarefa: [{ valor: 'Pendente' }, { valor: 'Em andamento' }]
        })
            .then((response) => {
                this.tarefas = response.data

                let statusPendente = 0;
                let statusEmAndamento = 0;

                this.tarefas.forEach(tarefa => {
                    if (tarefa.status === "Pendente") {
                        statusPendente++;
                    } else if (tarefa.status.startsWith("Em andamento")) {
                        statusEmAndamento++;
                    }
                });

                this.tarefas = [statusPendente, statusEmAndamento];
            })
            .catch((error) => {
                console.error(error);
            });
    },

    methods: {

        verPCMCriada(tipo, nivel) {
            localStorage.setItem('Tipo', tipo);
            localStorage.setItem('Nivel', nivel)
            if (this.$route.path === '/PCM/Criar') {
                window.location.reload();
            } else {
                this.$router.push({ name: "CriarPCM" });
            }
        },
        verProtocolos() {
            if (this.$route.path === '/protocolos') {
                sessionStorage.setItem('tipoProtocolo', this.tipoProtocolo)
                window.location.reload();
            } else {
                sessionStorage.setItem('tipoProtocolo', this.tipoProtocolo)
                this.$router.push({ name: "controleProtocolos" });
            }

        },
        criarProtocolo(tipo) {
            if (this.$route.path === '/protocolo/criar') {
                sessionStorage.setItem('tipoProtocolo', tipo)
                window.location.reload();
            } else {
                sessionStorage.setItem('tipoProtocolo', tipo)
                this.$router.push({ name: 'criaçãoProtocolo' })
            }
        },
        async getPCMs() {
            var id = this.idUsuario
            var dadosUsuario = await api.get(`usuario/${id}/funcionalidades`);
            dadosUsuario = dadosUsuario.data
            this.dadosUsuario = dadosUsuario
            api.get(`pcm/listar`, {})
                .then((response) => {
                    this.PCMs = response.data;

                    if (!dadosUsuario.funcionalidade_id.includes(56)) {
                        this.PCMs = []
                    } else {
                        if (!dadosUsuario.grupo.includes('Diretoria')) {
                            this.PCMs = this.PCMs.filter(item => item.setor_id == dadosUsuario.setor)
                            this.PCMs = this.PCMs.filter(item => item.nivel == 'Operacional')
                        } else {
                            this.PCMs = this.PCMs.filter(item => item.nivel == 'Estratégico')
                        }
                    }


                })
                .catch((error) => {
                    console.error(error);
                });
        },
    },

    mounted() {
        this.getPCMs()
    }
};
</script>

<style scoped>
.hPoints {
    border-radius: 6px;
    font-size: 12px;
    width: 1.5rem;
    height: 1.5rem;
}
</style>