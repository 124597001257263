<template>
    <main id="main">
        <section>
            <div class="titulo">
                <div class="margem container">
                    <div class="m-icone esquerda"><a @click="this.$router.back();" style="cursor: pointer;"
                            class="icone-voltar m-d"></a></div>
                    <h2>{{ nome }}</h2>
                </div>
            </div>
            <div class="margem container">
                <v-menu v-model="menu" :close-on-content-click="false" location="end" v-if="perfil == 1">
                    <template v-slot:activator="{ props }">
                        <v-btn
                            style="width: 2.5rem; height: 2.5rem; border: 1px solid var(--cor-separador); color: var(--cor-fonte);"
                            icon="mdi-filter-menu" v-bind="props" class="acao-secundaria"></v-btn>
                    </template>

                    <v-card>
                        <v-list style="background-color: var(--cor-bg); color: var(--cor-fonte);">
                            <v-list-item>
                                <label>
                                    Filtrar responsável:
                                </label>
                                <select v-if="this.filtro == 'Responsável'" v-model="valorFiltro"
                                    style=" width: fit-content; margin-inline: 0.5rem; border: 1px solid black;"
                                    class="form-select" @change="filtrarBacklogs(this.valorFiltro, this.filtro)">
                                    <option value="">Todos</option>
                                    <option
                                        v-for="backlog in filtrarRepetidos(sprints.map((item) => item.backlogs).flat(), 'responsavel')"
                                        :key="backlog">{{ backlog.responsavel
                                        }}</option>
                                </select>

                                <select v-if="this.filtro == 'Sprint'" v-model="valorFiltro"
                                    style=" width: fit-content; margin-inline: 0.5rem; border: 1px solid black;"
                                    class="form-select" @change="filtrarBacklogs(this.valorFiltro, this.filtro)">
                                    <option value="">Todos</option>
                                    <option
                                        v-for="backlog in filtrarRepetidos(sprints.map((item) => item.backlogs).flat(), 'sprint')"
                                        :key="backlog">{{ backlog.nomeSprint
                                        }}</option>
                                </select>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-menu>
            </div>

            <div style="display: flex; flex-flow: row; justify-content: center; width: 100%" class="margem container">
                <div class="colunas">
                    <h2 style="text-align: center;">Pendentes</h2>
                    <draggableVue class="bloco" :list="backlogsPendentes" group="backlogs" itemKey="codigo">
                        <template #item="{ element }">
                            <div class="card">

                                <div style="margin-bottom: 1rem;">
                                    <h3 style="justify-content: space-between;">
                                        {{ element.codigo }}
                                        <div class="m-icone direita">
                                            <i style="color: rgb(255, 145, 0); font-size: 20px;"
                                                class="bi bi-stop-circle-fill"></i>
                                        </div>
                                        <br>
                                    </h3>
                                    <b>
                                        {{ element.nomeSprint }}
                                    </b>
                                </div>
                                <span style="font-size: 15px;"> <b>Fim previsto:</b> {{ element.dtFim ?
                                    `${formatarDataHora(element.dtFim).split('-')[2]}/${formatarDataHora(element.dtFim).split('-')[1]}/${formatarDataHora(element.dtFim).split('-')[0]}`
                                    : '-' }}
                                </span>
                                <br>
                                <span style="font-size: 15px;">
                                    <b>Responsável:</b>
                                    {{ element.responsavel ? nomeEsobrenome(element.responsavel) : '-' }}
                                </span>
                                <br>
                                <span style="font-size: 15px;">
                                    <b>Descrição:</b>
                                    {{ element.descricao }}
                                </span>
                            </div>
                        </template>
                    </draggableVue>
                </div>
                <div class="colunas">
                    <h2 style="text-align: center;">Em andamento</h2>
                    <draggableVue class="bloco" :list="backlogsEmAndamento" group="backlogs" itemKey="codigo">
                        <template #item="{ element }">
                            <div class="card">
                                <div style="margin-bottom: 1rem;">
                                    <h3 style="justify-content: space-between;">
                                        {{ element.codigo }}
                                        <div class="m-icone direita">
                                            <i style="color: rgb(0, 47, 255); font-size: 20px;"
                                                class="bi bi-fast-forward-circle-fill"></i>
                                        </div>
                                        <br>
                                    </h3>

                                    <b>
                                        {{ element.nomeSprint }}
                                    </b>

                                </div>

                                <span style="font-size: 15px;"> <b>Fim previsto:</b> {{ element.dtFim ?
                                    `${formatarDataHora(element.dtFim).split('-')[2]}/${formatarDataHora(element.dtFim).split('-')[1]}/${formatarDataHora(element.dtFim).split('-')[0]}`
                                    : '-' }}
                                </span>
                                <br>
                                <span style="font-size: 15px;">
                                    <b>Responsável:</b>
                                    {{ element.responsavel ? nomeEsobrenome(element.responsavel) : '-' }}
                                </span>
                                <br>
                                <span style="font-size: 15px;">
                                    <b>Descrição:</b>
                                    {{ element.descricao }}
                                </span>
                            </div>
                        </template>
                    </draggableVue>
                </div>

                <div class="colunas">
                    <h2 style="text-align: center;">Concluído</h2>
                    <draggableVue class="bloco" :list="backlogsConcluidos" group="backlogs" itemKey="codigo">

                        <template #item="{ element }">
                            <div class="card">
                                <div style="margin-bottom: 1rem;">
                                    <h3 style="justify-content: space-between;">
                                        {{ element.codigo }}
                                        <div class="m-icone direita">
                                            <i style="color: rgb(0, 255, 0); font-size: 20px"
                                                class="bi bi-check-circle-fill"></i>
                                        </div>
                                        <br>
                                    </h3>
                                    <b>
                                        {{ element.nomeSprint }}
                                    </b>
                                </div>

                                <span style="font-size: 15px;"> <b>Fim previsto:</b> {{ element.dtFim ?
                                    `${formatarDataHora(element.dtFim).split('-')[2]}/${formatarDataHora(element.dtFim).split('-')[1]}/${formatarDataHora(element.dtFim).split('-')[0]}`
                                    : '-' }}
                                </span>
                                <br>

                                <span style="font-size: 15px;">
                                    <b>Responsável:</b>
                                    {{ element.responsavel ? nomeEsobrenome(element.responsavel) : '-' }}
                                </span>
                                <br>
                                <span style="font-size: 15px;">
                                    <b>Descrição:</b>
                                    {{ element.descricao }}
                                </span>
                            </div>
                        </template>
                    </draggableVue>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
import draggableVue from "@/vuedraggableVue";
import { api } from "roboflex-thalamus-request-handler"
import serviceFunções from "@/services/serviceFunções";
import Cookies from 'js-cookie'
import { sso } from "roboflex-thalamus-sso-lib";

export default {
    components: {
        draggableVue,
    },

    setup() {
        const formatarDataHora = (valor) => {
            return serviceFunções.formatarDataHora(valor);
        };
        const nomeEsobrenome = (nome) => {
            return serviceFunções.nomeEsobrenome(nome);
        };
        return {
            formatarDataHora,
            nomeEsobrenome
        };
    },

    created() {

        const usuarioLogado = sso.getUsuarioLogado()
        this.idUsuario = usuarioLogado.id

        const details = Cookies.get('projectDetails')
        if (details) {
            var projectDetails = JSON.parse(details)

            this.idProjeto = projectDetails.idProjeto
            this.nome = projectDetails.nome
            this.perfil = projectDetails.perfil
        } else {
            this.$router.push({ name: 'ControleDeProjetos' })
        }
    },

    data() {
        return {
            menu: false,
            filtro: 'Responsável',
            valorFiltro: '',
            teste: null,
            sprints: null,
            idUsuario: null,

            idProjeto: null,
            nome: null,
            perfil: null,

            backlogsPendentes: [],
            backlogsEmAndamento: [],
            backlogsConcluidos: [],

        };
    },

    mounted() {
        this.getSprints()
    },

    watch: {
        backlogsPendentes: {
            handler: 'atualizarStatus',
            deep: true
        },
        backlogsEmAndamento: {
            handler: 'atualizarStatus',
            deep: true
        },

        backlogsConcluidos: {
            handler: 'atualizarStatus',
            deep: true
        },
    },

    methods: {
        filtrarBacklogs(valor, item) {

            if (this.perfil !== 1) {
                this.backlogsPendentes = this.backlogsPendentes.filter(item => parseInt(item.responsavel_id) == parseInt(this.idUsuario));
                this.backlogsEmAndamento = this.backlogsEmAndamento.filter(item => parseInt(item.responsavel_id) == parseInt(this.idUsuario));
                this.backlogsConcluidos = this.backlogsConcluidos.filter(item => parseInt(item.responsavel_id) == parseInt(this.idUsuario));
            } else {
                this.backlogsPendentes = [];
                this.backlogsEmAndamento = [];
                this.backlogsPendentes = []
                this.getBacklogs()
                if (valor != "") {
                    if (item == 'Responsável') {
                        this.backlogsPendentes = this.backlogsPendentes.filter(item => item.responsavel == valor);
                        this.backlogsEmAndamento = this.backlogsEmAndamento.filter(item => item.responsavel == valor);
                        this.backlogsConcluidos = this.backlogsConcluidos.filter(item => item.responsavel == valor);
                    }
                    if (item == 'Sprint') {
                        this.backlogsPendentes = this.backlogsPendentes.filter(item => item.nomeSprint == valor);
                        this.backlogsEmAndamento = this.backlogsEmAndamento.filter(item => item.nomeSprint == valor);
                        this.backlogsConcluidos = this.backlogsConcluidos.filter(item => item.nomeSprint == valor);
                    }
                }
            }
        },

        filtrarRepetidos(array, chave) {

            const uniqueResponsaveis = [];
            if (chave == 'responsavel') {
                return array.filter(item => {
                    if (!uniqueResponsaveis.includes(item.responsavel)) {
                        uniqueResponsaveis.push(item.responsavel);
                        return true;
                    }
                    return false;
                });
            }
            if (chave == 'sprint') {
                return array.filter(item => {
                    if (!uniqueResponsaveis.includes(item.nomeSprint)) {
                        uniqueResponsaveis.push(item.nomeSprint);
                        return true;
                    }
                    return false;
                });
            }

        },

        getSprints() {
            api.get(`sprint/buscar/${this.idProjeto}`, {
            })
                .then((response) => {
                    this.sprints = response.data
                    this.getBacklogs()
                })
                .catch((error) => {
                    console.error(error);
                });

        },

        getBacklogs() {

            this.backlogs = this.sprints.map((item) => item.backlogs).flat();

            this.backlogs.forEach((item) => {
                switch (item.status) {
                    case 'Pendente':
                        this.backlogsPendentes.push(item);
                        break;
                    case 'Em andamento':
                        this.backlogsEmAndamento.push(item);
                        break;
                    case 'Em andamento 25%':
                        this.backlogsEmAndamento.push(item);
                        break;
                    case 'Em andamento 50%':
                        this.backlogsEmAndamento.push(item);
                        break;
                    case 'Em andamento 75%':
                        this.backlogsEmAndamento.push(item);
                        break;
                    case 'Concluído':
                        this.backlogsConcluidos.push(item);
                        break;
                    default:
                        console.error(`Status desconhecido: ${item.status}`);
                }
            });
            if (this.perfil != 1) {
                this.filtrarBacklogs()
            }
        },

        atualizarStatus() {

            let data = new Date()
            let ano = data.getFullYear();
            let mes = (data.getMonth() + 1);
            if (mes < 10) {
                mes = "0" + mes
            }
            let dia = data.getDate();
            if (dia < 10) {
                dia = "0" + dia
            }
            data = ano + '-' + mes + '-' + dia
            this.teste = data

            var backlog = JSON.parse(sessionStorage.getItem('tarefaMovida'));

            if (backlog !== null) {
                var idBacklog = backlog.id

                if (JSON.stringify(this.backlogsPendentes).includes(JSON.stringify(backlog))) {

                    api.put(`sprintTarefa/atualizar/${idBacklog}`, {
                        usuario_id: 1,
                        status: 'Pendente',
                        dtInicioReal: null,
                        dtFimReal: null
                    })
                } else {
                    if (JSON.stringify(this.backlogsEmAndamento).includes(JSON.stringify(backlog))) {

                        api.put(`sprintTarefa/atualizar/${idBacklog}`, {
                            usuario_id: 1,
                            status: 'Em andamento',
                            dtInicioReal: data
                        })
                    } else {
                        if (JSON.stringify(this.backlogsConcluidos).includes(JSON.stringify(backlog))) {

                            api.put(`sprintTarefa/atualizar/${idBacklog}`, {
                                usuario_id: 1,
                                status: 'Concluído',
                                dtFimReal: data
                            })
                        }
                    }
                }

                sessionStorage.removeItem('tarefaMovida')

            }
        },
    }
};
</script>

<style scoped>
.card {
    border: 1px solid var(--cor-separador);
    margin: 0.3rem;
    border-radius: 5px !important;
    padding: 5px;
}

.bloco {
    border: 2px solid var(--cor-separador);
    border-radius: 10px !important;
    height: 100%;
}

.colunas {
    border-radius: 20px;
    margin-inline: 2px;
    max-width: 33%;
    min-width: 33%;
}
</style>