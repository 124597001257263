<template>
    <main id="main">
        <section>
            <div class="titulo">
                <div class="margem container">
                    <div class="m-icone direita"><button @click="verPCMCriada('Projeto')" class="acao-secundaria">Novo
                            Projeto</button></div>
                    <div class="m-icone direita">
                        <div class="pesquisa">
                            <input type="text" placeholder="Pesquisar Projeto" v-model="projetoSelecionado"
                                @input="filtrarProjetos()" />
                            <a disabled class="icone-pesquisa"></a>
                        </div>
                    </div>
                    <h2>Projetos</h2>
                </div>
            </div>
            <div class="margem container">
                <div class="bloco margem">
                    <table class="tabela">
                        <tbody>
                            <tr>
                                <th scope="col">
                                    Código
                                </th>
                                <th scope="col" style="white-space: nowrap;">
                                    <span @click="ordenarLista('nome')" style="cursor: pointer;">
                                        Nome do Projeto
                                    </span>
                                    <i class="bi bi-caret-down-fill" style="display: none;" id="setaBaixonome"></i>
                                    <i class="bi bi-caret-up-fill" style="display: none;" id="setaCimanome"></i>
                                </th>
                                <th scope="col">
                                    <span @click="ordenarLista('status')" style="cursor: pointer;">
                                        Status
                                    </span>
                                    <i class="bi bi-caret-down-fill" style="display: none;" id="setaBaixostatus"></i>
                                    <i class="bi bi-caret-up-fill" style="display: none;" id="setaCimastatus"></i>
                                </th>
                                <th scope="col" style="width: 10rem;">
                                    <span @click="ordenarLista('dtInicio')" style="cursor: pointer;">
                                        Data de Início
                                    </span>
                                    <i class="bi bi-caret-down-fill" style="display: none;" id="setaBaixodtInicio"></i>
                                    <i class="bi bi-caret-up-fill" style="display: none;" id="setaCimadtInicio"></i>
                                </th>
                                <th scope="col" style="width: 10rem;">
                                    <div style="display: flex; align-items: center;">
                                        <span @click="ordenarLista('dtTermino')" style="cursor: pointer;">
                                            Data de Termino
                                        </span>
                                        <i class="bi bi-caret-down-fill" style="display: none;"
                                            id="setaBaixodtTermino"></i>
                                        <i class="bi bi-caret-up-fill" style="display: none;"
                                            id="setaCimadtTermino"></i>
                                    </div>
                                </th>
                                <th scope="col" class="text-nowrap">
                                    <div style="display: flex; align-items: center;">
                                        <span @click="ordenarLista('gerente')" style="cursor: pointer;">
                                            Gerente Responsável
                                        </span>
                                        <i class="bi bi-caret-down-fill" style="display: none;"
                                            id="setaBaixogerente"></i>
                                        <i class="bi bi-caret-up-fill" style="display: none;" id="setaCimagerente"></i>
                                    </div>
                                </th>
                                <th scope="col">
                                    <div style="display: flex; align-items: center;">
                                        <span @click="ordenarLista('setor')" style="cursor: pointer;">
                                            Setor Beneficiado
                                        </span>
                                        <i class="bi bi-caret-down-fill" style="display: none;" id="setaBaixosetor"></i>
                                        <i class="bi bi-caret-up-fill" style="display: none;" id="setaCimasetor"></i>
                                    </div>
                                </th>
                                <th scope="col"></th>
                            </tr>
                            <tr v-for="item in listaProjetosFiltrada" :key="item.id" class="linhaTabela"
                                @click="verBacklogs(item)" @mouseover="mostrarBotao(item.id, true)"
                                @mouseleave="mostrarBotao(item.id, false)">
                                <td style="vertical-align: middle; text-align: center; cursor: pointer">{{
                                    item.pcm_codigo ?
                                        item.pcm_codigo : '-' }}</td>
                                <td style="vertical-align: middle; cursor: pointer">{{ item.nome }}</td>
                                <td style="vertical-align: middle" class="clicavel">
                                    <select v-model="item.status" :disabled="this.idUsuario !== item.gerente_id"
                                        :style="{ 'color': (item.status == 'Cancelado') ? 'var(--cor-erro)' : (item.status == 'Proposto') ? 'var(--cor-erro)' : (item.status == 'Aprovado') ? '#00B6B3' : (item.status == 'Em andamento') ? 'var(--cor-ok)' : (item.status == 'Concluído') ? 'var(--cor-sucesso)' : '#FFA500', }"
                                        style="background-color: transparent;width: 10rem; outline: none; text-align: center; border: none; cursor: pointer; opacity: 1; "
                                        @click.stop @change="editarProjetoInLine(item.id, 'status', item.status)">
                                        <option style="color: var(--cor-erro);" hidden>Proposto</option>
                                        <option style="color: #00B6B3;">Aprovado</option>
                                        <option style="color: var(--cor-ok);">Em andamento</option>
                                        <option style="color: var(--cor-sucesso);">Concluído</option>
                                        <option style="color: #FFA500">Suspenso</option>
                                        <option style="color: var(--cor-erro)">Cancelado</option>
                                    </select>
                                </td>
                                <td style="text-align: center; vertical-align: middle; width: 9rem;">

                                    {{ item.dtInicio ?
                                        `${item.dtInicio.split('-')[2]}/${item.dtInicio.split('-')[1]}/${item.dtInicio.split('-')[0]}`
                                        : '' }}

                                </td>
                                <td style="text-align: center; vertical-align: middle; width: 9rem;">

                                    {{ item.dtTermino ?
                                        `${item.dtTermino.split('-')[2]}/${item.dtTermino.split('-')[1]}/${item.dtTermino.split('-')[0]}`
                                        : '-' }}

                                </td>
                                <td style="text-align: center; vertical-align: middle;">
                                    {{ nomeEsobrenome(item.gerente) }}
                                </td>
                                <td style="text-align: center; vertical-align: middle;">{{ item.setor }}</td>
                                <td style="vertical-align: middle;">
                                    <!-- {{ item.encerrado }} -->
                                    <div style="width: max-content; visibility: hidden;" :id="'botaoEdicao' + item.id">
                                        <v-menu v-if="Array.isArray(projetos) && projetos.length > 0">
                                            <template v-slot:activator="{ props }">
                                                <v-btn
                                                    style="width: 2rem; height: 2rem; border: 1px solid var(--cor-separador); color: var(--cor-fonte);"
                                                    class="botaoAdicionarSprint acao-secundaria"
                                                    icon="mdi-dots-horizontal" v-bind="props">
                                                </v-btn>
                                            </template>

                                            <v-list style="background-color: var(--cor-bg); color: var(--cor-fonte);">
                                                <v-list-item
                                                    @click="modalCompartilharProjeto = true, this.projetoEditado = item, this.projetoEditado.permissao = this.projetoEditado.permissao.filter((pessoa) => pessoa.automatico == 0)"
                                                    :disabled="idUsuario !== item.gerente_id">
                                                    Compartilhar
                                                    <br />
                                                </v-list-item>
                                                <v-list-item :disabled="idUsuario !== item.gerente_id"
                                                    @click="modalEditarProjeto = true, this.projetoEditado = item">
                                                    Editar
                                                    <br />
                                                </v-list-item>
                                                <v-list-item :disabled="idUsuario !== item.gerente_id || item.encerrado !== null"
                                                    @click="modalFinalizarProjeto = true, this.projetoEditado = item">
                                                    {{ item.encerrado == null ? 'Encerrar projeto' : item.encerrado == 0 ? 'Encerramento pendente' : 'Encerramento aprovado'}}
                                                    <br />
                                                </v-list-item>
                                                <v-list-item
                                                    v-if="dadosUser.includes('Diretoria') || dadosUser.includes('Auditor Projetos')"
                                                    @click="modalExcluirProjeto = true, this.projetoEditado = item"
                                                    style="color: var(--cor-erro);">
                                                    Excluir Projeto
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div v-if="!projetos" class="loading" id="loading">
                        <div></div>
                    </div>
                </div>
            </div>

            <!-- MODAL EXCLUIR PROJETO -->
            <div style="overflow: auto" class="modal-mask" v-if="modalExcluirProjeto" @click="fecharModalFora">
                <div class="jm" style="height: min-content; width: 50rem; padding: 2rem;">
                    <div style="text-align: center; width: 100%;">
                        <div>
                            <h2>Deseja excluir o projeto {{ projetoEditado.nome }}?</h2>
                        </div>
                        <br>
                        <div class="modal-footer">
                            <button type="button" class="button"
                                @click="excluirProjeto(projetoEditado.id)">Confirmar</button>
                            <button type="button" class="button acao-secundaria" @click="modalExcluirProjeto = false"
                                style="margin-left: 0.5rem;">Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- END MODAL -->

            <!-- modal editar projeto -->
            <div style="overflow: auto" class="modal-mask" v-if="modalEditarProjeto" @click="fecharModalFora">
                <div style="height: 70%; width: 50rem; padding: 2rem; margin-bottom: 3rem;" class="jm">
                    <div style="display: flex; justify-content: space-between">
                        <h3>Editar: {{ projetoEditado.nome }} </h3>
                    </div>
                    <hr>
                    <br>
                    <div class="grid-2">
                        <div>
                            <label>Nome do Projeto</label>
                            <input type="text" v-model="projetoEditado.nome"
                                @focusout="editarProjeto('nome', projetoEditado.nome)">
                        </div>
                        <div>
                            <label>Gerente Responsável</label>
                            <select @change="editarProjeto('gerente_id', $event.target.value)"
                                v-model="projetoEditado.gerente_id">
                                <option v-for="pessoa in gerente" :key="pessoa.nome" :value="pessoa.id">
                                    {{ pessoa.nomeCompleto }}
                                </option>
                            </select>
                        </div>
                        <div>
                            <label>Data de Início</label>
                            <input type="date" ref="dtInicio" v-model="projetoEditado.dtInicio"
                                @change="editarProjeto('dtInicio', $event.target.value)">
                        </div>
                        <div>
                            <label>Data de Termino</label>
                            <input type="date" ref="dtTermino" v-model="projetoEditado.dtTermino"
                                @change="editarProjeto('dtTermino', $event.target.value)">
                        </div>
                        <div>
                            <label>Setor Beneficiado</label>
                            <select v-model="projetoEditado.setor_id"
                                @change="editarProjeto('setor_id', $event.target.value)">
                                <option v-for="item in setores" :key="item.id" :value="item.id"
                                    :style="{ 'font-weight': (item.nivel_hierarquico <= 2) ? 'bold' : '0' }">
                                    <span v-if="item.nivel_hierarquico == 3">
                                        &nbsp;&nbsp;
                                    </span>
                                    {{ item.nivel_hierarquico == 3 ? "-" : '' }}
                                    {{ item.nome }}
                                </option>
                            </select>
                        </div>
                        <div>
                            <span>
                                Anexos: <i title="Adicionar anexo"
                                    style="font-size: 15px; cursor: pointer; justify-self: right;"
                                    class="bi bi-plus-circle" @click="openFileInput"></i>
                            </span>
                            <input style="display: none;" ref="fileInput" class="form-control form-control-sm"
                                type="file" @change="handleFileUpload">

                            <ul style="list-style: none; padding-left: 0rem; overflow: auto; max-height: 8.5rem;">
                                <li v-for="item in projetoEditado.anexos" :key="item"
                                    @mouseover="mostrarBotaoExcluirAnexo(item.id, true)"
                                    @mouseleave="mostrarBotaoExcluirAnexo(item.id, false)">
                                    <div
                                        style="margin-left: 0rem; padding-left: 0rem; border: 1px solid var(--cor-separador); border-radius: 10px; padding: 5px; display: flex; width: 20rem; justify-content: space-between;">
                                        <a :href="urlFoto.caminhoFoto + item.path" target="_blank">
                                            {{ item.nome }}
                                        </a>
                                        <i @click="excluirAnexo(item.id)" :id="'botaoExcluir' + item.id"
                                            style="color: red; visibility: hidden;"
                                            class="bi bi-trash-fill botaoAdicionarSprint"></i>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <!-- fim modal -->

            <!-- MODAL Finalizar Projeto-->
            <div class="modal-mask" v-if="modalFinalizarProjeto" @click="fecharModalFora">
                <div class="jm" style="height: min-content; width: 50rem; padding: 2rem;">
                    <div style="display: flex; justify-content: center">
                        <h2>{{ projetoEditado.nome }}</h2>
                    </div>
                    <br>
                    <fieldset class="margem">
                        <div class="grid-2">
                            <div>
                                <label>Data de termino do projeto</label>
                                <input :class="{ shake: disabled }" v-model="projetoEditado.dtConclusao"
                                    id="dtConclusao" type="date">
                            </div>
                            <div class="grid-2">
                                <div>
                                    <label>Custo previsto</label>
                                    <money3 :class="{ shake: disabled }" v-bind="config" id="custoEstimado"
                                        v-model="projetoEditado.custoEstimado"
                                        style="width: 8rem; font-size: 20px; background-color: transparent;">
                                    </money3>
                                </div>
                                <div>
                                    <label>Custo Real</label>
                                    <money3 :class="{ shake: disabled }" v-bind="config" id="custoReal"
                                        v-model="projetoEditado.custoReal"
                                        style="width: 8rem; font-size: 20px; background-color: transparent;">
                                    </money3>
                                </div>
                            </div>
                            <div>
                                <label>
                                    Evidencias de alcance
                                </label>
                                <textarea :class="{ shake: disabled }" id="evidenciaAlcance"
                                    v-model="projetoEditado.evidenciaAlcance"></textarea>
                            </div>
                            <div>
                                <label>Escopo alcançado</label>
                                <textarea :class="{ shake: disabled }" id="escopoAlcancado"
                                    v-model="projetoEditado.escopoAlcancado"></textarea>
                            </div>
                            <div>
                                <label>Resultado Obtido</label>
                                <textarea :class="{ shake: disabled }" id="resultadoObtido"
                                    v-model="projetoEditado.resultadoObtido"></textarea>
                            </div>
                            <div>
                                <label>Conclusão</label>
                                <textarea :class="{ shake: disabled }" v-model="projetoEditado.conclusao"
                                    id="conclusao"></textarea>
                            </div>
                        </div>
                    </fieldset>
                    <div style="align-content: end; margin-left: 1rem; display: flex;">
                        <button @click="finalizarProjeto()">
                            Solicitar encerramento</button>
                            <div :class="{ shake: disabled }">
                                <strong id="aviso" style="display: none; margin-left: 1rem; color: var(--cor-erro);">Preencha todos os campos!</strong>
                            </div>
                    </div>
                </div>
            </div>
            <!--END MODAL-->

            <!-- MODAL compartilhar Projeto-->
            <div class="modal-mask" v-if="modalCompartilharProjeto" @click="fecharModalFora">
                <div style="height: 85%; width: 50rem; padding: 1rem 2rem; overflow: auto;" class="jm">
                    <div>
                        <h2>Compartilhar: {{ projetoEditado.nome }} </h2>
                        <hr>
                        <br>
                    </div>
                    <div style="width: 100%; display: flex; justify-content: center;">
                        <div style="display: flex; flex-flow: column; width: 100%; height: 10rem;">
                            <input type="text" v-model="pessoaSelecionada" class="form-control"
                                @focusin="this.procurar($event.target.value)"
                                @input="this.procurar($event.target.value)" @focusout="fecharLista()"
                                placeholder="Adicionar Participante">

                            <div style="height: fit-content; max-height: 15rem ; overflow: auto; background-color: var(--cor-bg); border: 2px solid var(--cor-separador) ;border-bottom-left-radius: 4px; border-bottom-right-radius: 4px; position: absolute; margin-top: 3rem; width: 30rem;"
                                v-if="listaPessoasFiltrada">
                                <ul style="list-style: none;">
                                    <li @click="atualizarPermissão(item, 'adicionar')" class="pessoaParaAdicionar"
                                        v-for="item in listaPessoasFiltrada" :key="item.id">
                                        <div style="display: flex; align-items: center; padding: 5px; border-radius: 10px; margin-right: 3rem;"
                                            :style="{ 'color': (this.projetoEditado.permissao.map((item) => item.usuario_id)).includes(item.id) ? 'grey' : '', 'cursor': (this.projetoEditado.permissao.map((item) => item.usuario_id)).includes(item.id) ? 'not-allowed' : '' }">
                                            {{ item.nomeCompleto }} {{ (this.projetoEditado.permissao.map((item) =>
                                                item.usuario_id)).includes(item.id) ? '(Já adicionado)' : '' }}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <br>
                            <h3>Pessoas com acesso:</h3>
                            <ul style="list-style: none; padding-left: 0rem !important;">
                                <li v-for="item in reordenarArray(projetoEditado.permissao) " :key="item"
                                    style="display: flex; align-items: center;">
                                    <div
                                        style="display: flex; border: 1px solid var(--cor-separador);  height: 2.5rem ;align-items: center; justify-content: space-between; padding: 5px; border-radius: 10px; width: 90%; margin-top: 0.3rem;">
                                        {{ item.nome }} {{ item.usuario_id == projetoEditado.gerente_id ?
                                            '(Gerente)' :
                                            item.usuario_id == this.idUsuario ? '(Você)' : '' }}
                                    </div>
                                    <i v-if="parseInt(item.usuario_id) !== parseInt(this.idUsuario)"
                                        @click=" atualizarPermissão(item, 'remover')"
                                        style="margin-left: 1rem; font-size: 20px; color: red; cursor: pointer !important;"
                                        :id="'botaoEdicao' + item.id"
                                        class="bi bi-dash-circle botaoAdicionarSprint"></i>
                                </li>
                                <br><br><br>
                            </ul>

                        </div>
                    </div>
                </div>
            </div>
            <!--END MODAL-->
        </section>
    </main>
</template>

<script>
import { urlFoto } from "../../services/api";
import { ref } from 'vue';
import { api } from "roboflex-thalamus-request-handler";
import { consultarSetores } from '@/services/usuario-setor';
import { Money3Component } from "v-money3";
import { sso } from "roboflex-thalamus-sso-lib";
import serviceFunções from "@/services/serviceFunções";
import Cookies from 'js-cookie'

export default {
    name: "ControleDeProjetos",

    components: { money3: Money3Component },

    setup() {
        const fileInput = ref(null);

        const openFileInput = () => {
            fileInput.value.click();
        };

        const nomeEsobrenome = (nome) => {
            return serviceFunções.nomeEsobrenome(nome);
        };

        return { urlFoto, fileInput, openFileInput, nomeEsobrenome };
    },

    data() {
        return {
            inputArquivo: false,
            idUsuario: null,
            pessoaSelecionada: null,
            projetoSelecionado: null,
            listaProjetosFiltrada: null,
            listaPessoasFiltrada: null,
            pessoasComAcessoPorProjeto: [],
            dataTerminoProjeto: null,
            teste: 'teste',
            projetos: null,
            modalCompartilharProjeto: false,
            modalNovoProjeto: false,
            modalEditarProjeto: false,
            modalFinalizarProjeto: false,
            gerente: [],
            pessoaCompartilhada: null,
            disabled: false,
            setores: [],
            projetoEditado: null,
            userName: '',
            dadosUser: null,
            modalExcluirProjeto: false,
            config: {
                masked: false,
                prefix: "R$ ",
                thousands: ".",
                decimal: ",",
                precision: 2,
            },

            anexos: [{ nome: 'Evidencia 1', id: 1 }, { nome: 'Evidencia 2', id: 2 }]

        }
    },

    mounted() {
        this.getProjetos(),
            this.getGerenteseSetor(),
            localStorage.removeItem('ultimaSprintEditada'),
            sessionStorage.removeItem('ultimaSprintEditada')

    },

    created() {
        const usuarioLogado = sso.getUsuarioLogado()
        this.userName = usuarioLogado.nome
        this.idUsuario = usuarioLogado.id
    },

    methods: {
        excluirProjeto(id) {
            const userId = this.idUsuario

            api.put(`projeto/excluir/${id}`, {
                usuario_id: userId
            })
                .then(() => {
                    this.getProjetos();
                    this.modalExcluirProjeto = false;
                })
                .catch((error) => {
                    console.error('Erro ao excluir projeto:', error);
                });
        },
        verPCMCriada(tipo, nivel) {
            localStorage.setItem('Tipo', tipo);
            localStorage.setItem('Nivel', nivel)
            if (this.$route.path === '/PCM/Criar') {
                window.location.reload();
            } else {
                this.$router.push({ name: "CriarPCM" });
            }
        },

        ordenarLista(itemReferencia) {
            for (var item of ['nome', 'status', 'dtInicio', 'dtTermino', 'gerente', 'setor']) {
                if (item !== itemReferencia) {
                    document.getElementById(`setaBaixo${item}`).style.display = 'none'
                    document.getElementById(`setaCima${item}`).style.display = 'none'
                }
            }

            if (itemReferencia == 'dtInicio' || itemReferencia == 'dtTermino') {
                if (document.getElementById(`setaBaixo${itemReferencia}`).style.display === 'none') {
                    this.projetos = this.projetos.sort((a, b) => {
                        const dataInicioA = new Date(a[itemReferencia]);
                        const dataInicioB = new Date(b[itemReferencia]);
                        return dataInicioB - dataInicioA;
                    });
                    document.getElementById(`setaBaixo${itemReferencia}`).style.display = 'inline'
                    document.getElementById(`setaCima${itemReferencia}`).style.display = 'none'
                    return
                } else if (document.getElementById(`setaBaixo${itemReferencia}`).style.display === 'inline') {
                    this.projetos = this.projetos.sort((a, b) => {
                        const dataInicioA = new Date(a[itemReferencia]);
                        const dataInicioB = new Date(b[itemReferencia]);
                        return dataInicioA - dataInicioB;
                    });
                    document.getElementById(`setaBaixo${itemReferencia}`).style.display = 'none'
                    document.getElementById(`setaCima${itemReferencia}`).style.display = 'inline'
                    return
                }
                // --------------------------------------------------------------------------------------------------------------------------
            } else if (itemReferencia == 'status') {
                if (document.getElementById(`setaBaixo${itemReferencia}`).style.display === 'none') {

                    this.projetos = this.projetos.sort((a, b) => {
                        const order = {
                            'Em andamento': 1,
                            'Aprovado': 2,
                            'Proposto': 3,
                            'Suspenso': 4,
                            'Concluído': 5,
                            'Cancelado': 6
                        };
                        const orderA = order[a.status] || 0;
                        const orderB = order[b.status] || 0;
                        if (orderA < orderB) {
                            return -1;
                        }
                        if (orderA > orderB) {
                            return 1;
                        }
                        return 0;
                    });


                    document.getElementById(`setaBaixo${itemReferencia}`).style.display = 'inline'
                    document.getElementById(`setaCima${itemReferencia}`).style.display = 'none'
                    return
                } else if (document.getElementById(`setaBaixo${itemReferencia}`).style.display === 'inline') {


                    this.projetos = this.projetos.sort((a, b) => {
                        const order = {
                            'Em andamento': 1,
                            'Aprovado': 2,
                            'Proposto': 3,
                            'Concluído': 4
                        };
                        const orderA = order[a.status] || 0;
                        const orderB = order[b.status] || 0;
                        if (orderA < orderB) {
                            return -1;
                        }
                        if (orderA > orderB) {
                            return 1;
                        }
                        return 0;
                    });


                    document.getElementById(`setaBaixo${itemReferencia}`).style.display = 'none'
                    document.getElementById(`setaCima${itemReferencia}`).style.display = 'inline'
                    return
                }
            }
            // --------------------------------------------------------------------------------------------------------------------------
            else {
                if (document.getElementById(`setaBaixo${itemReferencia}`).style.display === 'none') {
                    this.projetos = this.projetos.sort((a, b) => {
                        const statusA = a[itemReferencia] ? a[itemReferencia].toLowerCase() : '';
                        const statusB = b[itemReferencia] ? b[itemReferencia].toLowerCase() : '';

                        if (statusA < statusB) {
                            return -1;
                        }
                        if (statusA > statusB) {
                            return 1;
                        }
                        return 0;
                    });
                    document.getElementById(`setaBaixo${itemReferencia}`).style.display = 'inline'
                    document.getElementById(`setaCima${itemReferencia}`).style.display = 'none'
                    return
                } else if (document.getElementById(`setaBaixo${itemReferencia}`).style.display === 'inline') {
                    this.projetos = this.projetos.sort((a, b) => {
                        const statusA = a[itemReferencia] ? a[itemReferencia].toLowerCase() : '';
                        const statusB = b[itemReferencia] ? b[itemReferencia].toLowerCase() : '';

                        if (statusA > statusB) {
                            return -1;
                        }
                        if (statusA < statusB) {
                            return 1;
                        }
                        return 0;
                    });
                    document.getElementById(`setaBaixo${itemReferencia}`).style.display = 'none'
                    document.getElementById(`setaCima${itemReferencia}`).style.display = 'inline'
                    return
                }
            }
        },

        filtrarProjetos() {
            if (!this.projetoSelecionado) {
                this.listaProjetosFiltrada = this.projetos;
            } else {
                const textoLowerCase = this.projetoSelecionado.toLowerCase();
                this.listaProjetosFiltrada = this.projetos.filter(projeto => {
                    return Object.values(projeto).some(value => {
                        if (typeof value === 'string') {
                            return value.toLowerCase().includes(textoLowerCase);
                        }
                        return false;
                    });
                });
            }
        },

        handleFileUpload() {
            const fileInput = this.$refs.fileInput;

            if (fileInput && fileInput.files.length > 0) {
                const formData = new FormData();
                formData.append('projeto_id', this.projetoEditado.id);

                for (let i = 0; i < fileInput.files.length; i++) {
                    formData.append('anexos[]', fileInput.files[i]);
                }
                api.post(`projeto/anexo/adicionar`, formData)
                    .then(response => {
                        this.projetoEditado.anexos.push({
                            path: response.data.anexos_salvos[0].path,
                            nome: response.data.anexos_salvos[0].nome,
                            id: response.data.anexos_salvos[0].id
                        });
                    })
                    .catch(error => {
                        console.error(error);
                    });
            }
        },

        excluirAnexo(id) {
            api.delete(`projeto/anexo/remover/${id}`);
            this.projetoEditado.anexos = this.projetoEditado.anexos.filter(anexo => anexo.id !== id)
        },

        reordenarArray(array) {
            var id = this.projetoEditado.gerente_id

            const primeiroElemento = array.find(item => parseInt(item.usuario_id) === id);
            const arraySemPrimeiroElemento = [array.filter(item => item.usuario_id !== parseInt(id))];
            return [].concat(primeiroElemento, ...arraySemPrimeiroElemento)
        },

        atualizarPermissão(item, ação) {
            if ((this.projetoEditado.permissao.map((item) => item.usuario_id)).includes(item.id)) {
                return
            } else {

                if (ação == 'adicionar') {

                    var novaPermissão = {
                        usuario_id: item.id,
                        nivel: 1,
                        nome: item.nomeCompleto,
                    }
                    this.projetoEditado.permissao.push(novaPermissão);

                    api.post(`permissao/projeto/${this.projetoEditado.id}`, {
                        usuarios: this.projetoEditado.permissao
                    })
                        .then(() => {
                            this.listaPessoasFiltrada = null;
                            this.pessoaSelecionada = null
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }
                if (ação == 'remover') {
                    this.projetoEditado.permissao = this.projetoEditado.permissao.filter(pessoa => pessoa.usuario_id !== parseInt(item.usuario_id));
                    api.post(`permissao/projeto/${this.projetoEditado.id}`, {
                        usuarios: this.projetoEditado.permissao
                    })
                        .then(() => {
                            this.listaPessoasFiltrada = null;
                            this.pessoaSelecionada = null
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }
                if (ação == 'atualizar') {

                    api.post(`permissao/projeto/${this.projetoEditado.id}`, {
                        usuarios: this.projetoEditado.permissao
                    })
                        .then(() => {
                            this.listaPessoasFiltrada = null;
                            this.pessoaSelecionada = null
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }
            }

        },

        fecharLista() {
            setTimeout(() => {
                this.listaPessoasFiltrada = null;
                this.pessoaSelecionada = null;
            }, 200);
        },

        procurar(texto) {
            if (!texto) {
                this.listaPessoasFiltrada = this.gerente
            } else {
                if (this.listaPessoasFiltrada !== null) {
                    this.listaPessoasFiltrada = this.gerente
                    this.listaPessoasFiltrada = this.listaPessoasFiltrada.filter(nome => nome.nomeCompleto.toLowerCase().includes(texto.toLowerCase()));
                }
            }
        },

        finalizarProjeto() {
            var camposVazios = []
            if (!this.projetoEditado.dtConclusao) camposVazios.push('dtConclusao');
            if (!this.projetoEditado.custoEstimado) camposVazios.push('custoEstimado');
            if (!this.projetoEditado.custoReal) camposVazios.push('custoReal');
            if (!this.projetoEditado.evidenciaAlcance) camposVazios.push('evidenciaAlcance');
            if (!this.projetoEditado.escopoAlcancado) camposVazios.push('escopoAlcancado');
            if (!this.projetoEditado.conclusao) camposVazios.push('conclusao');
            if (!this.projetoEditado.resultadoObtido) camposVazios.push('resultadoObtido');

            if (camposVazios.length > 0) {
                camposVazios.forEach(item => {
                    document.getElementById(item).style.border = 'solid 1px var(--cor-erro)';
                    document.getElementById('aviso').style.display = '';
                    this.disabled = true
                    setTimeout(() => {
                        this.disabled = false
                    }, 1500)
                    return
                });
            } else {
                api.post(`projeto/encerrar`, {
                    projeto_id: this.projetoEditado.id,
                    dtConclusao: this.projetoEditado.dtConclusao,
                    evidenciaAlcance: this.projetoEditado.escopoAlcancado,
                    custoEstimado: this.projetoEditado.custoEstimado,
                    custoReal: this.projetoEditado.custoReal,
                    escopoAlcancado: this.projetoEditado.evidenciaAlcance,
                    conclusao: this.projetoEditado.conclusao,
                    resultadoObtido: this.projetoEditado.resultadoObtido,
                    status: 'Aguardando aprovação'
                })

                this.modalFinalizarProjeto = false;
                setTimeout(() => {
                    this.getProjetos();
                }, 500)
            }
        },

        mostrarBotao(id, mostrar) {
            if (mostrar == true) {
                document.getElementById('botaoEdicao' + id).style.visibility = ''
            }
            if (mostrar == false) {
                document.getElementById('botaoEdicao' + id).style.visibility = 'hidden'
            }
        },

        mostrarBotaoExcluirAnexo(id, mostrar) {
            if (mostrar == true) {
                document.getElementById('botaoExcluir' + id).style.visibility = ''
            }
            if (mostrar == false) {
                document.getElementById('botaoExcluir' + id).style.visibility = 'hidden'
            }
        },

        editarProjeto(itemAlterado, novoValor) {

            api.put(`projeto/atualizar/${this.projetoEditado.id}`, {
                [itemAlterado]: novoValor,
            })
        },

        editarProjetoInLine(idProjeto, itemAlterado, novoValor) {

            if (novoValor !== "Concluído") {

                api.put(`projeto/atualizar/${idProjeto}`, {
                    [itemAlterado]: novoValor,
                    dtTermino: null
                })
                    .then(() => {
                        this.getProjetos()
                    })
            }
            if (novoValor == "Concluído") {
                var dataAtual = new Date().toISOString().split('T')[0];
                api.put(`projeto/atualizar/${idProjeto}`, {

                    [itemAlterado]: novoValor,
                    dtTermino: dataAtual
                })
                    .then(() => {
                        this.getProjetos()
                    })
            }
        },

        verBacklogs(project) {
            if (this.dadosUser.includes('Auditor Projetos') || this.dadosUser.includes('Diretoria')) {
                Cookies.set('projectDetails', JSON.stringify({
                    idProjeto: project.id,
                    nome: project.nome,
                    pcm_codigo: project.pcm_codigo,
                    pcm_id: project.pcm_id,
                    perfil: 1
                }), { expires: 1 })
                this.$router.push({ name: 'sprints' })
            } else {
                if ((this.projetos.find(projeto => projeto.id == project.id).permissao).find(pessoa => pessoa.usuario_id == this.idUsuario).nivel == 2) {
                    Cookies.set('projectDetails', JSON.stringify({
                        idProjeto: project.id,
                        nome: project.nome,
                        pcm_codigo: project.pcm_codigo,
                        pcm_id: project.pcm_id,
                        perfil: 1
                    }), { expires: 1 })
                    this.$router.push({ name: 'sprints' })
                }
                if ((this.projetos.find(projeto => projeto.id == project.id).permissao).find(pessoa => pessoa.usuario_id == this.idUsuario).nivel == 1) {
                    Cookies.set('projectDetails', JSON.stringify({
                        idProjeto: project.id,
                        nome: project.nome,
                        pcm_codigo: project.pcm_codigo,
                        pcm_id: project.pcm_id,
                        perfil: 2
                    }), { expires: 1 })
                    this.$router.push({ name: 'sprints' })
                }
            }

        },

        fecharModalFora(event) {
            if (event.target.classList.contains('modal-mask')) {
                this.modalEditarProjeto = false
                this.modalFinalizarProjeto = false
                this.modalCompartilharProjeto = false
                this.modalExcluirProjeto = false
                return this.getProjetos()
            }
        },

        async getGerenteseSetor() {
            try {
                const { usuarios, setores } = await consultarSetores();
                this.gerente = usuarios;
                this.setores = setores;
            } catch (error) {
                console.error(error);
            }
        },

        async getProjetos() {
            var id = this.idUsuario

            var gruposUsuario = await api.get(`usuario/${id}/funcionalidades`)
            gruposUsuario = gruposUsuario.data.grupo
            this.dadosUser = gruposUsuario

            if (gruposUsuario.includes('Diretoria') || gruposUsuario.includes('Auditor Projetos')) {
                api.get(`projeto/listar`, {

                })
                    .then((response) => {
                        this.projetos = response.data;

                        this.filtrarProjetos()

                        document.getElementById(`setaBaixostatus`).style.display = 'none'
                        document.getElementById(`setaCimastatus`).style.display = 'none'
                        this.ordenarLista('status')

                    })
                    .catch((error) => {
                        console.error(error);
                    });
            } else {

                api.get(`projeto/usuario/${id}`, {

                })
                    .then((response) => {
                        this.projetos = response.data;
                        this.filtrarProjetos()

                        document.getElementById(`setaBaixodtInicio`).style.display = 'none'
                        document.getElementById(`setaCimadtInicio`).style.display = 'none'
                        this.ordenarLista('status')

                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        }
    }

}
</script>

<style scoped>
.pessoaParaAdicionar:hover {
    cursor: pointer;
}
</style>