<template>
    <main id="main">
        <section>
            <div class="titulo">
                <div class="margem container">
                    <h2>Minhas Entregas</h2>
                </div>
            </div>
            <div class="margem container">
                <div class="bloco margem">
                    <table class="tabela">
                        <tbody>
                            <tr>
                                <th v-for="(i, index) in labels" :key="index">
                                    {{ i.label }}
                                </th>
                                <th>Tarefas</th>
                            </tr>
                            <template v-if="entregas.length > 0">
                                <tr style="text-align: center" v-for="item, index in entregas" :key="index"
                                    @click="verProjeto(item.projeto_id)">
                                    <td v-for="(i, index) in labels" :key="index">
                                        {{ item[i.chave] }}
                                    </td>
                                    <td>
                                        <div style="display: flex; justify-content: center;">
                                            <span style="display: flex">
                                                <span class="hPoints bg-alerta">{{ item.tarefas_pendentes }}</span>
                                                <span class="hPoints bg-ok">{{ item.tarefas_emAndamento }}</span>
                                                <span class="hPoints bg-sucesso">{{ item.tarefas_concluidas }}</span>
                                                <span class="hPoints bg-erro">{{ item.tarefas_atrasadas }}</span>
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                            </template>
                            <h4 v-else style="color: var(--cor-erro);">
                                Nenhuma entrega encontrada!
                            </h4>
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
import { sso } from "roboflex-thalamus-sso-lib";
import { api } from "roboflex-thalamus-request-handler";

export default {
    name: "gestaoDeEntregas",
    data() {
        return {
            idUsuario: null,
            entregas: [],
            labels: [
                { label: 'Nome da Entrega', chave: 'nomeEntrega' },
                { label: 'Projeto', chave: 'nomeProjeto' },
                { label: 'Status do Projeto', chave: 'statusProjeto' },
                { label: 'Data de inicio', chave: 'dtInicio' },
            ]
        };
    },
    created() {
        const usuarioLogado = sso.getUsuarioLogado();
        this.idUsuario = usuarioLogado.id;
        this.getEntregas()
    },
    methods: {
        verProjeto(id) {
            this.$emit('verProjeto', id)
        },
        async getEntregas() {
            var response = await api.post('coordenador/entrega/listar', {
                //usuarioDelegado_id : 5,
                // coordenador_id: this.idUsuario
                coordenador_id: 5
            })
            this.entregas = response.data
            if (this.entregas.length > 0) {
                this.entregas = this.entregas.map(item => ({
                    ...item,
                    dtInicio: item.dtInicio ? `${item.dtInicio.split('-')[2]}/${item.dtInicio.split('-')[1]}/${item.dtInicio.split('-')[0]}` : '-',
                })
                )
            }
        }
    }

};
</script>