<template>
    <main id="main">
        <section>
            <div class="titulo">
                <div class="margem container">
                    <h2>{{ 'Adicionar' }}</h2>
                </div>
            </div>
            <div class="margem container">
                <fieldset class="grid-4 bloco2 margem">
                    <div><label>Tipo</label><select class="form-select" v-model="protocolo.tipo_id">
                            <option v-for="tipo in tipos" :key="tipo.id" :value="tipo.id">{{ tipo.nome }}
                            </option>
                        </select></div>
                    <div><label>Nome</label><input :class="{ shake: disabled }" id="nome" type="text"
                            class="form-control" v-model="protocolo.nome"></div>
                    <div><label>Setor</label><select :class="{ shake: disabled }" id="setor_id" class="form-select"
                            v-model="protocolo.setor_id">
                            <option v-for="item in setores" :key="item.id" :value="item.id"
                                :style="{ 'font-weight': (item.nivel_hierarquico <= 2) ? 'bold' : '0' }">
                                <span v-if="item.nivel_hierarquico == 3" style="color: red;">
                                    &nbsp;&nbsp;
                                </span>
                                {{ item.nivel_hierarquico == 3 ? "-" : '' }}
                                {{ item.nome }}
                            </option>
                        </select></div>
                    <div><label>Responsável</label><select :class="{ shake: disabled }" id="responsavel_id"
                            class="form-select" v-model="protocolo.responsavel_id">
                            <option v-for=" item in gerente " :key="item.id" :value="item.id">
                                {{ nomeEsobrenome(item.nomeCompleto) }}
                            </option>
                        </select></div>
                    <div><label>Data</label><input :class="{ shake: disabled }" id="dtInicio" type="date"
                            class="form-control" v-model="protocolo.dtInicio"></div>
                    <div><label>Hora</label><input :class="{ shake: disabled }" id="horaInicio" type="time"
                            class="form-control" v-model="protocolo.horaInicio"></div>
                </fieldset>
                <div class="submit m-b">
                    <button @click="criarProtocolo()">Criar</button>
                    <button class="acao-secundaria" @click="this.$router.push('/protocolos')">Cancelar</button>
                    <b :class="{ shake: disabled }"
                        style="margin-top: 0.5rem; margin-right: 1rem; display: none; color: var(--cor-erro);" id="aviso">Preencha
                        os
                        campos destacados!</b>
                </div>

                <div class="bloco margem">
                    <div class="row">
                        <div style="width: 20%;">
                            <div style="width: 10rem;">
                                <h5>
                                    <span style="display: flex;">
                                        <span class="hPoints bg-alerta">{{ somarHP(protocolo)[0] }}</span>
                                        <span class="hPoints bg-ok">{{ somarHP(protocolo)[1] }}</span>
                                        <span class="hPoints bg-sucesso">{{ somarHP(protocolo)[2] }}</span>
                                    </span>
                                </h5>
                            </div>
                        </div>
                    </div>
                    <table :id="protocolo.nome" class="tabela">
                        <tr>
                            <th>
                                <strong>
                                    Código
                                </strong>
                            </th>
                            <th>
                                <strong>
                                    Descrição
                                </strong>
                            </th>
                            <th>
                                <strong>
                                    H.P.
                                </strong>
                            </th>
                            <th>
                                <strong>
                                    Responsável
                                </strong>
                            </th>
                            <th>
                                <strong>
                                    Inicio Previsto
                                </strong>
                            </th>
                            <th>
                                <strong>
                                    Fim Previsto
                                </strong>
                            </th>
                            <th style="padding: 0rem;">
                                <strong>
                                    Status
                                </strong>
                            </th>
                            <th>
                            </th>
                        </tr>
                    </table>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
import { api } from "roboflex-thalamus-request-handler";
import { consultarSetores } from '@/services/usuario-setor';
import { sso } from "roboflex-thalamus-sso-lib";
import serviceFunções from "@/services/serviceFunções";

export default {

    name: "VisualizaçãoPlanodeAção",

    setup() {
        const somarHP = (dados) => {
            return serviceFunções.somarHP(dados);
        };
        const nomeEsobrenome = (nome) => {
            return serviceFunções.nomeEsobrenome(nome);
        };

        return { somarHP, nomeEsobrenome };
    },

    data() {
        return {
            protocolo: {
                tipo_id: sessionStorage.getItem('tipoProtocolo'),
                nome: null,
                responsavel_id: null,
                dtInicio: null,
                dtFim: null,
                horaInicio: null,
                setor_id: null
            },

            setores: null,
            tipos: null,

            idProtocolo: sessionStorage.getItem('idProjeto'),
            gerente: [],
            idUsuario: null,

            sprints: [],

            disabled: false,

        }
    },
    
    created() {
        const usuarioLogado = sso.getUsuarioLogado()
        // this.userName = usuarioLogado.nome // nome
        this.idUsuario = usuarioLogado.id // id
    },

    mounted() {
        this.getGerenteseSetor()
    },

    methods: {
        validarCampos() {
            var camposVazios = []
            if (!this.protocolo.nome) camposVazios.push('nome');
            if (!this.protocolo.setor_id) camposVazios.push('setor_id');
            if (!this.protocolo.responsavel_id) camposVazios.push('responsavel_id');
            if (!this.protocolo.dtInicio) camposVazios.push('dtInicio');
            if (!this.protocolo.horaInicio) camposVazios.push('horaInicio');

            return camposVazios
        },

        criarProtocolo() {
            var campoVazio = this.validarCampos()
            if (campoVazio.length !== 0) {
                campoVazio.forEach(item => {
                    document.getElementById(item).style.border = 'solid 1px red';
                    document.getElementById('aviso').style.display = ''
                    this.disabled = true
                    setTimeout(() => {
                        this.disabled = false
                    }, 1500)
                    return
                });
            } else {
                api.post(`protocolo/gravar`, {
                    tipo_id: this.protocolo.tipo_id,
                    nome: this.protocolo.nome,
                    responsavel_id: this.protocolo.responsavel_id,
                    dtInicio: this.protocolo.dtInicio,
                    horaInicio: this.protocolo.horaInicio,
                    setor_id: this.protocolo.setor_id
                })
                    .then((response) => {
                        var id = (response.data).id
                        sessionStorage.setItem('idProjeto', id)
                        this.$router.push({ name: "visualizaçãoProtocolo" })
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        },

        async getGerenteseSetor() {
            try {
                const { usuarios, setores } = await consultarSetores();
                this.gerente = usuarios;
                this.setores = setores
            } catch (error) {
                console.error(error);
            }

            this.tipos = (await api.get('protocolo/tipo/listar')).data
        },

        somenteBacklogs() {
            if (this.sprints !== null) {
                var teste = this.sprints.map((item) => item.tarefas).flat()
                teste.sort((a, b) => b.id - a.id);
                return teste
            }
            return 'nada'
        },

        mostrarBotao(id, mostrar) {
            if (mostrar == true) {
                document.getElementById('botaoEdicao' + id).style.visibility = ''
            }
            if (mostrar == false) {
                document.getElementById('botaoEdicao' + id).style.visibility = 'hidden'
            }
        },

        definirInicioFimReal(idBacklog, status) {

            let data = new Date()
            let ano = data.getFullYear();
            let mes = (data.getMonth() + 1);
            if (mes < 10) {
                mes = "0" + mes
            }
            let dia = data.getDate();
            if (dia < 10) {
                dia = "0" + dia
            }
            data = ano + '-' + mes + '-' + dia

            if (status == 'Em andamento') {

                api.put(`planoAcaoTarefa/atualizar/${idBacklog}`, {
                    usuario_id: this.idUsuario,
                    dtInicioReal: data
                })
                    .then(() => {

                        return this.getBacklogs()
                    })

            } if (status == 'Concluído') {

                api.put(`planoAcaoTarefa/atualizar/${idBacklog}`, {
                    usuario_id: this.idUsuario,
                    dtFimReal: data

                })
                    .then(() => {
                        return this.getBacklogs()
                    })

            } if (status == 'Pendente') {

                api.put(`planoAcaoTarefa/atualizar/${idBacklog}`, {
                    usuario_id: this.idUsuario,
                    dtInicioReal: null,
                    dtFimReal: null
                })
                    .then(() => {
                        return this.getBacklogs()
                    })

            }


        },

        abrirModalEditarBacklog(idBacklog, idSprint) {
            this.showEditarBacklog = true;

            let sprint = this.sprints[0]
            idSprint

            this.backlogeditado = sprint.tarefas.find(tarefa => tarefa.id === idBacklog);
        },

        editarBacklog(itemAlterado, idBacklog, novoValor) {

            api.put(`planoAcaoTarefa/atualizar/${idBacklog}`, {
                usuario_id: this.idUsuario,
                [itemAlterado]: novoValor
            })
                .then(() => {
                    if (itemAlterado == 'status') {
                        return this.definirInicioFimReal(idBacklog, novoValor)
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        fecharModalFora(event) {
            if (event.target.classList.contains('modal-mask')) {
                this.showEditarBacklog = false;
            }

        },

        criarBacklog(id, descricao) {

            if (this.somenteBacklogs().length !== 0) {

                api.post(`planoAcaoTarefa/cadastrar`, {

                    planoAcao_id: id,
                    codigo: 'Tarefa - ' + (parseInt((this.somenteBacklogs()[0].codigo).match(/\d+$/)[0]) + 1),
                    descricao: descricao
                })
                    .then((response) => {
                        localStorage.setItem('ultimaSprintEditada', id)
                        var novoItem = {
                            "id": response.data.id,
                            "sprint_id": id,
                            "codigo": 'Tarefa - ' + (parseInt((this.somenteBacklogs()[0].codigo).match(/\d+$/)[0]) + 1),
                            "descricao": descricao,
                            "HP": 0,
                            "responsavel_id": 0,
                            "status": "Pendente",
                            "dtInicio": null,
                            "dtFim": null,
                            "dtInicioReal": null,
                            "dtFimReal": null,
                            "responsavel": null,
                            "anexos": []
                        };

                        const sprint = this.sprints.find(item => item.id === id)
                        sprint.tarefas.push(novoItem)
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            } else {
                api.post(`planoAcaoTarefa/cadastrar`, {

                    planoAcao_id: id,
                    codigo: 'Tarefa - 1',
                    descricao: descricao
                })
                    .then(() => {
                        sessionStorage.setItem('ultimaSprintEditada', id)
                        this.getBacklogs();
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }

        },

        apagarBacklog(idBacklog) {

            api.put(`planoAcaoTarefa/excluir/${idBacklog}`, {
                usuario_id: this.idUsuario
            })
                .then(() => {
                    this.getBacklogs()
                })
                .catch((error) => {
                    console.error(error);
                });
        },

    }
}
</script>

<style scoped></style>